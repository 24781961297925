// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';

import { Button } from '@livingsecurity/cyberblocks';
import { GlobalContext } from '../../GameplayProvider';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ModalContainer from 'components/Modal/ModalContainer';
import TeamsVideoPlayer, { videoContainer } from '../VideoView/TeamsVideoPlayer';
import { useMoveForwardVideo, useVideoContext, useParticipants } from 'hooks';

import { USER_NAME_SEPARATOR } from '_constants';

const useStyles = makeStyles({
  gameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    color: '#F2F2F2',
  },
  videoContainer: {
    ...videoContainer,
    alignItems: 'center',
  },
});

export default function GameOutro({
  debug,
  step,
  gameState,
  isLeader,
  modalData,
  setCurrentGameStep,
  gameCompleted,
}: {
  step: number;
  gameState: string;
  debug?: boolean;
  isLeader: boolean;
  modalData: Array<any>;
  setCurrentGameStep: Function;
  gameCompleted: boolean;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const currentStep = modalData[step];
  const currentVideo = currentStep?.videoFile && currentStep?.videoFile[0];
  const { afterText, calculateAfterText } = useMoveForwardVideo(context);

  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const completedParticipants = [localParticipant, ...participants].map(
    ({ identity }) => identity.split(USER_NAME_SEPARATOR)[1] || 'unknown',
  );

  useEffect(() => {
    context.switchAudio(false);

    if (context.isLeader) {
      datadogLogs.logger.info(`Leader sending game results. Room ID: ${window.location.pathname.split('/')[1]}.`);
      context.gameService.sendGameResults({
        completed: gameCompleted,
        endTime: moment().format('x'),
        completedParticipants,
      });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (currentVideo?.public_id.length) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [step]); // eslint-disable-line

  function progressStep() {
    setCurrentGameStep(gameState, 0, step + 1, undefined, currentVideo?.public_id?.length ? 'video' : null);
    calculateAfterText(gameState, 0, step + 1);
  }

  function endGame() {
    setCurrentGameStep('DONE', 0, 0, undefined, currentVideo?.public_id?.length ? 'video' : null);
    calculateAfterText('DONE', 0, 0);
  }

  if ((!currentVideo && !currentStep) || loading) {
    return null;
  }

  return (
    <div className={classes.gameContainer}>
      <div className={classes.videoContainer}>
        {currentVideo?.public_id?.length ? (
          <TeamsVideoPlayer
            publicId={currentVideo.public_id}
            captions={currentStep?.captionFile}
            language={context.gameDef.language}
            disableSeeking={!debug}
            afterText={afterText}
            percentCallback={(percent: number) => {
              if (percent <= 20 && (context.gameDef?.players || {})[context.identity]?.videoIsOver) {
                context.setWatchedStatus(false);
              }
              if (percent >= 99) {
                context.setWatchedStatus(true);
                if (step < modalData?.length - 1) {
                  progressStep();
                } else {
                  endGame();
                }
              }
            }}
          />
        ) : (
          <ModalContainer>
            <div
              style={{
                width: '450px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>{documentToReactComponents(currentStep)}</div>
              {isLeader && (
                <div id="start-btn" style={{ marginTop: '30px' }}>
                  <Button
                    onClick={() => {
                      if (step < modalData?.length - 1) {
                        progressStep();
                      } else {
                        endGame();
                      }
                    }}
                  >
                    {t('modals:next')}
                  </Button>
                </div>
              )}
            </div>
          </ModalContainer>
        )}
      </div>
    </div>
  );
}
