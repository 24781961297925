import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Divider } from '@material-ui/core';
import { get } from 'lodash';

import { RankTable } from 'components/RankTable';

import { CompanyRank, apiClient } from 'services/api';

import { useStyles } from '../styled';

export const ResultsTable: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [rankDetails, setRankDetails] = useState<CompanyRank>();

  const roomId = location.pathname.split('/')[1];

  useEffect(() => {
    apiClient.getLeaderBoard(roomId).then(setRankDetails).catch(console.error);
  }, [roomId]);

  if (!rankDetails) {
    return (
      <div className={classes.loader}>
        <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
      </div>
    );
  }

  return (
    <div>
      <RankTable
        headColumns={[t('leaderboard:rank'), t('leaderboard:our-team'), t('leaderboard:time')]}
        activeTeam={get(rankDetails, 'thisTeam[0].teamName')}
        rows={rankDetails.thisTeam ? rankDetails.thisTeam : []}
        tableTestId="result-table-this-team"
      />
      <Divider variant="middle" classes={{ middle: classes.divider }} data-testid="result-table-divider" />
      <RankTable
        headColumns={[t('leaderboard:rank'), t('leaderboard:top-10-teams'), t('leaderboard:time')]}
        rows={rankDetails.topTen}
        tableTestId="result-table-other-teams"
      />
    </div>
  );
};
