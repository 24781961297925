import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '70px',
    marginTop: '2.5em',
    padding: '1em 2em',
    fontWeight: 'bold',
    lineHeight: '22px',
    backgroundColor: 'var(--black)',
    borderTop: '2px solid #A2ACB3',
    color: '#F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'default',
    zIndex: 1,
  },
});

export default function (props: any) {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
}
