import React, { FC, useState, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as type from '../../services/api/types';
import { getURLParamsAsObject } from 'utils';

type Props = {
  allTimersHidden?: boolean;
};

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    infoWrapper: {
      width: '100%',
      minWidth: '900px',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '120px',
      flexDirection: ({ allTimersHidden }) => (allTimersHidden ? 'column' : 'row'),
      justifyContent: ({ allTimersHidden }) => (allTimersHidden ? 'space-around' : 'space-between'),
    },
    leftWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: '26px',
      color: '#f2f2f2',
      textAlign: 'center',
      marginRight: '30px',
    },
    subtitle: {
      color: '#f2f2f2',
      textAlign: 'center',
      fontSize: '14px',
    },
    button: {
      margin: '0 -10px 0 25px',
      fontSize: '0.875rem',
      color: '#1fbafb',
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        color: '#a5e9ff',
      },
    },
  }),
);

interface IWaitingRoomBottomContent {
  redirectToGameLobby: () => void;
  linkForReschedule?: string;
  sessionState?: type.SessionStateEnum;
  sessionStart?: string;
  sessionEnd?: string;
  debug?: boolean;
  allTimersHidden?: boolean;
}

const URLParams = getURLParamsAsObject();

const WaitingRoomBottomContent: FC<IWaitingRoomBottomContent> = ({
  linkForReschedule,
  sessionState,
  sessionStart,
  sessionEnd,
  redirectToGameLobby,
  debug,
  allTimersHidden,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ allTimersHidden });

  const tz = moment?.tz?.zone(moment?.tz?.guess())?.abbr(parseInt(moment().format('x')));
  const startTime = moment(sessionStart);

  const isSessionStateCanceled = sessionState === type.SessionStateEnum.CANCELED;
  const validSessionState =
    sessionState === type.SessionStateEnum.SCHEDULED || sessionState === type.SessionStateEnum.INPROGRESS;

  const [timeState, setTime] = useState<any>({
    isOver: false,
    endTime: moment(),
    doorsOpenAt: moment(),
    canJoin: false,
    incomplete: false,
  });

  useEffect(() => {
    function calculateTime() {
      const endTime = sessionEnd ? moment(sessionEnd) : moment(sessionStart).add(1, 'h');
      setTime({
        endTime,
        isOver: endTime.isBefore(moment()) || sessionState === type.SessionStateEnum.ENDED,
        doorsOpenAt: moment(sessionStart).subtract(1, 'h'),
        canJoin: startTime.diff(moment(), 'm', true) <= 60 && validSessionState,
        incomplete: sessionState === type.SessionStateEnum.INCOMPLETE,
      });
    }

    calculateTime();

    const interval = setInterval(() => {
      calculateTime();
    }, 60000);

    return () => clearInterval(interval);
  }, [sessionStart, sessionEnd]); // eslint-disable-line

  useEffect(() => {
    if (timeState.canJoin) {
      redirectToGameLobby();
    }
  }, [timeState.canJoin]);

  const rescheduleLink = useMemo(() => {
    if (!linkForReschedule) return;
    if (!URLParams.token) return linkForReschedule;
    const url = new URL(linkForReschedule as string);
    url.searchParams.set('token', URLParams.token);
    return url.href;
  }, [linkForReschedule]);

  function getTitle() {
    if (timeState.isOver) return t('sign-up:cyberescape-online');
    if (timeState.isSessionStateCanceled) return t('lobby:session-cancelled');
    if (timeState.incomplete) return t('lobby:game-incomplete');
    return t('lobby:session-not-open');
  }

  function getSubtitle() {
    if (timeState.isOver) return t('lobby:game-completed', { endTime: `${getStartTime(timeState.endTime)} ${tz}` });
    if (isSessionStateCanceled) return null;
    return t('lobby:doors-open-at', {
      startTime: `${getStartTime(timeState.doorsOpenAt)} ${tz} (${timeState.doorsOpenAt.fromNow()})`,
    });
  }

  function getStartSubtitle() {
    if (timeState.isOver || isSessionStateCanceled) return null;
    return t('lobby:game-start', {
      startTime: `${getStartTime(startTime)} ${tz} (${startTime.fromNow()})`,
    });
  }

  function getStartTime(time: any) {
    return time.isSame(moment(), 'day') ? time.format('h:mm A zz') : time.format('MMM D, YYYY [at] h:mm A zz');
  }

  function getButtonText() {
    if (!timeState.isOver || debug) {
      return (
        <a
          className={classes.button}
          href={rescheduleLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('lobby:reschedule')}
        </a>
      );
    }
  }

  return (
    <div className={classes.infoWrapper}>
      <div className={classes.leftWrapper}>
        <div className={classes.title}>{getTitle()}</div>
        <div style={{ margin: '20px 0' }}>
          <div className={classes.subtitle}>{getStartSubtitle()}</div>
          <div className={classes.subtitle}>{getSubtitle()}</div>
        </div>
      </div>
      <div>{getButtonText()}</div>
    </div>
  );
};

export default WaitingRoomBottomContent;
