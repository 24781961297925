import React, { useContext, useState, useEffect, useCallback, ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button as LinkButton, Select, MenuItem, Link } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { startFirebaseDiagnostic, TestResultEnum } from '@livingsecurity/firebase-test';
import { datadogLogs } from '@datadog/browser-logs';

import LocalVideoPreview from 'components/LocalVideoPreview/LocalVideoPreview';
import ModalContainer from 'components/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'state';
import { Button } from 'components/Button';
import Switcher from 'components/Switcher';
import { useMediaStreamTrack, useRoomState, useVideoContext } from 'hooks';
import { GlobalContext } from '../GameplayPage/GameplayProvider';
import DatabaseStatusBlock from './StatusBlock/DatabaseStatusBlock/DatabaseStatusBlock';
import AudioStatusBlock from './StatusBlock/AudioStatusBlock/AudioStatusBlock';
import { LocalVideoTrack } from 'twilio-video';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_AUDIO_OUTPUT_KEY,
  REGIONS,
} from '_constants';
import {
  useVideoInputDevices,
  useAudioInputDevices,
  useAudioOutputDevices,
} from 'components/MenuBar/DeviceSelector/deviceHooks/deviceHooks';
import LocalAudioLevelIndicator from 'components/MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import AudioButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/AudioButton/AudioButton';
import VideoButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/VideoButton/VideoButton';
import AudioTroubleBlock from './StatusBlock/AudioTroubleBlock/AudioTroubleBlock';
import getFirebaseConfig from '../../firebase-config';

import OpenNewTabIcon from 'assets/images/open-new-tab.svg';
import arrow from 'assets/images/arrow.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      background: '#002638',
      margin: '0 auto',
      width: 'auto',
      height: 'auto',
      maxHeight: '100%',
      color: '#F2F2F2',
      boxShadow: 'var(--green-box-shadow)',
      borderRadius: '10px',
      cursor: 'default',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
        height: '100%',
        maxHeight: 'initial',
        borderRadius: '0',
        justifyContent: 'initial',
        '& > div': {
          borderRadius: 0,
        },
      },
      '&.invisible': {
        display: 'none',
      },
    },
    modalBody: {
      overflowX: 'hidden',
      fontSize: 'initial !important',
      padding: '0',
    },
    hostedModalBody: {
      padding: '30px 30px 24px 30px',
    },
    rootContainer: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gap: '32px',
    },
    joinModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    joinModalContent: {
      overflowX: 'hidden',
      padding: '20px 24px 10px',
      width: '1016px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    settingsContainer: {
      position: 'relative',
      width: '100%',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '3px',
      alignItems: 'center',
      background: 'rgba(255, 255, 255, .05)',
    },
    form: {
      minWidth: '300px',
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1.1fr',
      rowGap: '2ch',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 'auto',
    },
    joinButtons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '0 24px 20px',
    },
    moderatedBody: {
      maxWidth: '800px',
      margin: '0 auto 20px',
    },
    moderatedLink: {
      margin: '-1px 0 0 3px',
    },
    localPreviewContainer: {
      paddingRight: '2em',
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
    backButton: {
      marginRight: '20px',
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 'auto',
      marginTop: '27px',
      gap: '35px',
    },
    select: {
      flexGrow: 1,
      width: 0,
      background: '#173042',
      border: '1px solid #A2ACB3',
      borderRadius: 4,
      color: '#EAEAEA',
      fontSize: '1rem',
      lineHeight: '25px',
      '&:before, &:after': {
        display: 'none',
      },
      '&:hover': {
        borderColor: '#6ebddb',
      },
      '& > div': {
        position: 'relative',
        zIndex: 2,
        padding: '7.5px 36px 7.5px 16px !important',
      },
    },
    selectError: {
      borderColor: '#FF9380',
    },
    selectArrow: {
      position: 'absolute',
      top: '50%',
      right: '16px',
      transform: 'translateY(-50%)',
    },
    selectTitle: {
      color: '#F2F2F2',
      fontSize: '1rem',
      lineHeight: 2,
    },
    selectContainer: {
      marginBottom: '24px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '16px',
      },
    },
    selectWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    noDeviceDetected: {
      marginTop: '8px',
      color: '#FF9380',
      fontSize: '.75rem',
      lineHeight: '16px',
    },
    audioIndicatorWrapper: {
      marginLeft: '16px',
    },
    previewContainer: {},
    previewTitle: {
      margin: '10px',
      fontSize: '1rem',
      lineHeight: 1,
      fontWeight: 700,
      textAlign: 'center',
    },
    previewBlur: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 16,
    },
    audioVideoBlock: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
    devicesBlock: {
      flexGrow: 1,
      width: 0,
      minWidth: '350px',
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    videoBlock: {
      marginRight: '40px',
      marginLeft: '72px',
      width: '336px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '36px',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    previewControls: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 18,
    },
    testingBlock: {
      margin: '0',
    },
    audioTesting: {
      marginBottom: '30px',
    },
    linkImage: {
      marginLeft: 10,
      verticalAlign: 'baseline',
    },
  }),
);

type JoinModalProps = {
  onClose: () => void;
  handleSubmit: () => void;
  userName: string;
  fromSettings?: boolean;
  isJoinModal: boolean;
  withBackButton: boolean;
};

export enum testStatusesEnum {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PROGRESS = 'PROGRESS',
}

const SelectComponent = ({
  title,
  onChange,
  value,
  options,
  titleWithStar,
  hasDevices,
  indicator,
  errorMessage,
  testId = 'item',
}: {
  title: string;
  onChange: any;
  value: string | null | undefined;
  options: any;
  titleWithStar?: boolean;
  hasDevices?: boolean;
  indicator?: ReactElement;
  errorMessage?: string;
  testId?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.selectContainer}>
      <div className={classes.selectTitle} data-testid={`${testId}-select-title`}>
        {title}
        {titleWithStar && <span style={{ color: hasDevices ? 'inherit' : '#FF9380' }}>*</span>}
      </div>
      <div className={classes.selectWrapper}>
        <Select
          onChange={onChange}
          value={value}
          className={`${classes.select} ${errorMessage && !hasDevices ? classes.selectError : ''}`}
          IconComponent={() => <img className={classes.selectArrow} src={arrow} alt="select arrow" />}
          data-testid={`${testId}-select`}
        >
          {options}
        </Select>
        {indicator}
      </div>
      {!hasDevices && errorMessage && <div className={classes.noDeviceDetected}>{errorMessage}</div>}
    </div>
  );
};

export const JoinModalContent: React.FC<JoinModalProps> = ({
  onClose,
  handleSubmit,
  userName,
  fromSettings,
  isJoinModal,
  withBackButton,
}) => {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const { debugSession, runFirebaseTestOnJoin } = useFlags();
  const { isConnecting, localTracks, isProcessorAvailable, isProcessorOn, addBlur, removeBlur } = useVideoContext();
  const { isFetching, activeSinkId, setActiveSinkId } = useAppState();
  const [testStatus, setTestStatus] = useState<testStatusesEnum>(testStatusesEnum.PROGRESS);
  const [isLoading, setIsLoading] = useState(false);

  const handleBlurChange = (checked: boolean) => {
    checked ? addBlur() : removeBlur();
  };

  const roomDetails = context.gameService?.getRoomDetails();
  const { isSessionHosted } = context;

  const runFirebaseTest = runFirebaseTestOnJoin && !isSessionHosted;
  const hasContentfulData = !!context.contentfulData;

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleSubmit();
    } finally {
      setIsLoading(false);
    }
  }, [handleSubmit]);

  useEffect(() => {
    (async () => {
      if (!isJoinModal || fromSettings || !runFirebaseTest || testStatus !== testStatusesEnum.PROGRESS) return;
      const region = roomDetails?.session?.region || REGIONS.US;

      datadogLogs.logger.info(`${userName} running Firebase test in the ${region} region`);

      setTestStatus(testStatusesEnum.PROGRESS);

      const tests = startFirebaseDiagnostic(getFirebaseConfig(region), process.env.REACT_APP_FIREBASE_LS_KEY);

      for (let index = 0; index < tests.length; index++) {
        const test = await tests[index];
        const isError = test.status === TestResultEnum.ERROR;
        if (isError) {
          setTestStatus(testStatusesEnum.FAIL);
          console.error('Firebase connection test failed: ', {
            companyId: roomDetails?.companyId,
            userName,
            url: window.location.href,
            results: JSON.stringify(test),
          });
          break;
        }
        if (index + 1 === tests.length || !tests.length) setTestStatus(testStatusesEnum.SUCCESS);
      }
    })();
  }, [isJoinModal]);

  /* ------------------------------------- */
  /* ---- VIDEO ---- */
  const videoInputDevices = useVideoInputDevices();
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoTrack = localTracks.find((track) => track.kind === 'video') as LocalVideoTrack;
  const mediaVideoStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = mediaVideoStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  if (!videoInputDevices.length && localVideoTrack) {
    videoInputDevices.push({
      deviceId: localVideoInputDeviceId!,
      groupId: localVideoTrack.id,
      kind: 'videoinput',
      label: localVideoTrack!.mediaStreamTrack!.label,
      toJSON: () => {},
    });
  }

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  useEffect(() => {
    // it's needed for a first-time visit to the page to show video device in select when user
    // disable the camera and it's not saved to local storage yet
    if (!storedLocalVideoDeviceId && localVideoTrack) {
      setStoredLocalVideoDeviceId(localVideoTrack.mediaStreamTrack.getSettings().deviceId || null);
    }
  }, [localVideoTrack, storedLocalVideoDeviceId]);

  /* ---- VIDEO ---- */

  /* ---- AUDIO INPUT ---- */
  const audioInputDevices = useAudioInputDevices();
  const localAudioTrack = localTracks && localTracks.find((track) => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceAudioTrack(newDeviceId: string) {
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
  }

  const changeMicrophone = (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    replaceAudioTrack(e.target.value as string);
  };
  /* ---- AUDIO INPUT ---- */

  /* ---- AUDIO OUTPUT ---- */
  const audioOutputDevices = useAudioOutputDevices();

  const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
  const hasSelectedVideoDevice = audioOutputDevices.some(
    (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
  );
  const localAudioOutputDeviceId = hasSelectedVideoDevice ? selectedAudioDeviceId : activeSinkId;
  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.label;

  const changeSpeaker = (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    const id = e.target.value as string;
    setActiveSinkId(id);
    window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, id);
  };
  /* ---- AUDIO OUTPUT ---- */
  /* ------------------------------------- */

  const isReadyToJoin =
    localAudioInputDeviceId &&
    localAudioOutputDeviceId &&
    hasContentfulData &&
    (runFirebaseTest ? testStatus === testStatusesEnum.SUCCESS : true);
  const isJoinDisabled = !isReadyToJoin && (!context.debug || !debugSession);

  return (
    <div className={classes.joinModalContainer}>
      <div className={classes.joinModalContent}>
        <div className={classes.audioVideoBlock}>
          <div className={classes.devicesBlock}>
            <SelectComponent
              title={t('join-status:camera')}
              onChange={(e: any) => replaceTrack(e.target.value as string)}
              value={videoInputDevices.length ? localVideoInputDeviceId : t('modals:settings:no-local-video')}
              options={
                videoInputDevices.length ? (
                  videoInputDevices.map((device) => (
                    <MenuItem value={device.deviceId} key={device.deviceId}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'inherit' }}>
                        {device.label}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={t('modals:settings:no-local-video')}>{t('join-status:no-camera')}</MenuItem>
                )
              }
              testId="camera"
            />
            <SelectComponent
              title={t('join-status:microphone')}
              onChange={changeMicrophone}
              value={localAudioTrack?.mediaStreamTrack.label ? localAudioInputDeviceId : t('join-status:no-microphone')}
              options={
                audioInputDevices.length && localAudioTrack?.mediaStreamTrack.label ? (
                  audioInputDevices.map((device) => (
                    <MenuItem value={device.deviceId} key={device.deviceId}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'inherit' }}>
                        {device.label}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={t('join-status:no-microphone')}>{t('join-status:no-microphone')}</MenuItem>
                )
              }
              titleWithStar
              hasDevices={Boolean(audioInputDevices.length && localAudioTrack?.mediaStreamTrack.label)}
              indicator={
                <div className={classes.audioIndicatorWrapper}>
                  <LocalAudioLevelIndicator />
                </div>
              }
              errorMessage={t('join-status:no-microphone-error')}
              testId="microphone"
            />
            <SelectComponent
              title={t('join-status:speaker')}
              onChange={changeSpeaker}
              value={activeOutputLabel ? localAudioOutputDeviceId : t('join-status:no-speaker')}
              options={
                audioOutputDevices.length && activeOutputLabel ? (
                  audioOutputDevices.map((device) => (
                    <MenuItem value={device.deviceId} key={device.deviceId}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'inherit' }}>
                        {device.label}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={t('join-status:no-speaker')}>{t('join-status:no-speaker')}</MenuItem>
                )
              }
              titleWithStar
              hasDevices={Boolean(audioOutputDevices.length && activeOutputLabel)}
              errorMessage={t('join-status:no-speaker-error')}
              testId="speaker"
            />
          </div>
          <div className={classes.videoBlock}>
            <div className={classes.previewTitle} data-testid="preview-title">
              {t('join-status:video-preview')}
            </div>
            <LocalVideoPreview identity={userName} withIndicator={false} />
            {isProcessorAvailable && (
              <div className={classes.previewBlur} data-testid="preview-blur">
                <Switcher
                  text={t('join-status:blur-video-background')}
                  checked={isProcessorOn}
                  onChange={handleBlurChange}
                  disabled={!localVideoTrack}
                />
              </div>
            )}
            <div className={classes.previewControls}>
              <AudioButton fromSettings />
              <VideoButton />
            </div>
          </div>
        </div>
        <div className={classes.testingBlock}>
          {fromSettings ? (
            <AudioTroubleBlock />
          ) : (
            <>
              <div className={classes.audioTesting}>
                <AudioStatusBlock success={Boolean(localAudioInputDeviceId) && Boolean(localAudioOutputDeviceId)} />
              </div>
              {runFirebaseTest && <DatabaseStatusBlock status={testStatus} />}
            </>
          )}
        </div>
      </div>
      <div className={classes.joinButtons}>
        {fromSettings ? (
          <>
            <Link
              href="https://www.livingsecurity.com/data-protection"
              target="_blank"
              rel="noreferrer noopener nofollow"
              style={{
                color: '#89BBFF',
                marginRight: 'auto',
              }}
            >
              {t('modals:settings:data-protection-policy')}
              <img src={OpenNewTabIcon} className={classes.linkImage} />
            </Link>
            <Button type="submit" onClick={onClose} data-testid="done-button">
              {t('lobby:done')}
            </Button>
          </>
        ) : (
          <>
            {withBackButton && (
              <LinkButton onClick={onClose} className={classes.backButton} data-testid="back-button">
                {t('modals:start-confirm:back')}
              </LinkButton>
            )}
            <Button
              type="submit"
              onClick={onSubmit}
              loading={isLoading || isConnecting || isFetching}
              disabled={isJoinDisabled}
              data-testid="join-button"
            >
              {t('lobby:join')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const JoinModal: React.FC<JoinModalProps> = ({ onClose, handleSubmit, userName, isJoinModal, withBackButton }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isSessionHosted } = useContext(GlobalContext);
  const { isConnecting } = useVideoContext();
  const { isFetching } = useAppState();
  const [isLoading, setIsLoading] = useState(false);
  const roomState = useRoomState();

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleSubmit();
    } finally {
      setIsLoading(false);
    }
  }, [handleSubmit]);

  if (isSessionHosted) {
    return (
      <ModalContainer
        title={t('lobby:moderated-title')}
        classes={{
          modalContainer: `${classes.modalContainer} ${isJoinModal ? '' : 'invisible'}`,
          body: `${classes.modalBody} ${classes.hostedModalBody}`,
        }}
      >
        <div className={classes.moderatedBody}>
          {t('hosted-version:join-modal-1')}
          {', '}
          <a
            href="https://www.livingsecurity.com/support/how-to-host-a-teams-room"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t('hosted-version:join-modal-2')}
          </a>
          . {t('hosted-version:join-modal-3')}
        </div>
        <div className={classes.joinButtons} style={{ justifyContent: 'center' }}>
          <LinkButton onClick={onClose} className={classes.backButton}>
            {t('modals:start-confirm:back')}
          </LinkButton>
          <Button type="submit" onClick={onSubmit} loading={isLoading || isConnecting || isFetching}>
            {t('lobby:join')}
          </Button>
        </div>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      title={t('sign-up:your-settings')}
      titleTestId="join-modal-title"
      classes={{
        modalContainer: `${classes.modalContainer} ${isJoinModal ? '' : 'invisible'}`,
        body: classes.modalBody,
      }}
    >
      {roomState === 'disconnected' && (
        <JoinModalContent
          onClose={onClose}
          handleSubmit={handleSubmit}
          userName={userName}
          isJoinModal={isJoinModal}
          withBackButton={withBackButton}
        />
      )}
    </ModalContainer>
  );
};

export default JoinModal;
