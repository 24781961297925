import React, { useCallback, useRef, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';

import NavbarButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/NavbarButton';
import ThreeDotsVideoMenu from 'components/ThreeDotsVideoMenu';
import IconWrapper from '../IconWrapper';

// Move icons back if they will be changed
// import VideoOn from 'assets/images/navbar/video-on.svg';
// import VideoOff from 'assets/images/navbar/video-off.svg';
import Video from 'assets/images/navbar/video.svg';

import { useDevices, useLocalVideoToggle, useVideoContext } from 'hooks';

import { setVideoPreference } from 'utils';

interface Props {
  disabled?: boolean;
  withBlurMenu?: boolean;
}

export default function VideoButton({ disabled = false, withBlurMenu = false }: Props) {
  const { t } = useTranslation();
  const { localTracks, isProcessorAvailable } = useVideoContext();
  const context = useContext(GlobalContext);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices, hasAllowedVideoInputDevices, permissionsStatuses } = useDevices(localTracks);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500 && !permissionsStatuses.video.wasDenied) {
      if (hasAllowedVideoInputDevices) {
        setVideoPreference(context.roomSessionId, String(!isVideoEnabled));
      }

      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled, hasAllowedVideoInputDevices, permissionsStatuses.video.wasDenied]);

  const hasPermission = useMemo(() => {
    return !permissionsStatuses.video.wasDenied && permissionsStatuses.video.state;
  }, [permissionsStatuses.video.wasDenied, permissionsStatuses.video.state]);

  return (
    <>
      <NavbarButton
        // icon={isVideoEnabled && hasPermission ? VideoOn : VideoOff}
        iconComponent={<IconWrapper isEnabled={Boolean(isVideoEnabled && hasPermission)} icon={Video} />}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || disabled}
        alt="Toggle video button"
        testId="toggle-video-button"
      >
        {hasPermission && hasVideoInputDevices && hasAllowedVideoInputDevices
          ? `${t('bottom-bar:video')} ${isVideoEnabled ? t('bottom-bar:on') : t('bottom-bar:off')}`
          : t('bottom-bar:no-video')}
      </NavbarButton>
      {withBlurMenu && isProcessorAvailable && (
        <ThreeDotsVideoMenu isDisabled={Boolean(!isVideoEnabled || !hasPermission)} />
      )}
    </>
  );
}
