import { QuestionStatistics } from 'containers/GameplayPage/services/types';

export enum SessionStateEnum {
  SCHEDULED = 'SCHEDULED',
  INPROGRESS = 'INPROGRESS',
  ENDED = 'ENDED',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum GameCompletionStatusEnum {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export type GenericResponse = Record<string, any>;
export type ErrorResponse = any;

export interface TeamRank {
  rank: number;
  teamName: string;
  time: string;
  totalTimeInSeconds: number;
  createdDate: Date | string;
  participants: Array<string>;
  prize: Array<string>;
}

export interface CompanyRank {
  topTen: Array<TeamRank>;
  thisTeam?: Array<TeamRank>;
}

export type RankBodyRequest = Record<'timeInSeconds', number>;

export type TeamName = Record<'teamName', string>;

export type RankItem = Record<'rank', number>;

export type Username = Record<'userName', string>;

export type TeamAvailable = Record<'available', boolean>;

export interface RoomDetailsRoom {
  roomFull?: boolean;
  maxParticipantsCount?: number;
  connectedParticipantsCount?: number;
}

export interface RoomDetails {
  id: string;
  sessionState?: SessionStateEnum;
  companyId: string;
  session?: any;
  room?: RoomDetailsRoom;
  sessionStart: string;
  sessionEnd: string;
}

export interface GameResults {
  teamName: string;
  startDateTime: string;
  endDateTime: string;
  storylineId: string;
  campaignId: number;
  joinedParticipants: Array<string>;
  completedParticipants: Array<string>;
  finalTimeInSeconds: number;
  penaltyTimeInSeconds: number;
  numberOfPenalties: number;
  numberOfQuestions: number;
  numberOfQuestionsAnsweredCorrectFirstTime: number;
  gameCompletionStatus: GameCompletionStatusEnum;
  questionsStatistics: Array<QuestionStatistics>;
}
