import { useCallback, useState, useEffect } from 'react';
import fscreen from 'fscreen';

export default function useFullScreenToggle() {
  const [isFullScreen, setIsFullScreen] = useState<Boolean>(!!fscreen.fullscreenElement);

  useEffect(() => {
    const onFullScreenChange = () => setIsFullScreen(!!fscreen.fullscreenElement);
    fscreen.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  const toggleFullScreen = useCallback(
    (forceValue?: boolean | any) => {
      if (forceValue !== undefined && typeof forceValue === 'boolean') {
        try {
          if (forceValue) {
            fscreen.requestFullscreen(document.documentElement);
          } else if (fscreen && fscreen.fullscreenElement) {
            fscreen.exitFullscreen();
          }
        } catch (e) {}
        return;
      }
      try {
        isFullScreen ? fscreen.exitFullscreen() : fscreen.requestFullscreen(document.documentElement);
      } catch (e) {}
    },
    [isFullScreen],
  );

  return [isFullScreen, toggleFullScreen, fscreen.fullscreenEnabled] as const;
}
