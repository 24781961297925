import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavbarButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/NavbarButton';

import ResourcesIcon from 'assets/images/navbar/resource-icon.svg';
import ResourcesDialog from 'components/ResourcesDialog/ResourcesDialog';

export default function ResourceButton() {
  const [isResourceDialogOpen, toggleResourceModal] = useState(true);
  const { t } = useTranslation();

  const openResourceModal = () => {
    toggleResourceModal(true);
  };
  const closeResourceModal = () => {
    toggleResourceModal(false);
  };

  return (
    <>
      <NavbarButton icon={ResourcesIcon} onClick={openResourceModal} alt={t('resources:alt')}>
        {t('bottom-bar:resources')}
      </NavbarButton>
      <ResourcesDialog open={isResourceDialogOpen} onClose={closeResourceModal} />
    </>
  );
}
