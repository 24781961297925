import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { IGameResults } from '../../services/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      fontSize: '24px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        '& hr': {
          margin: '5px 0',
        },
      },
    },
    row: {
      minWidth: '400px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '4px 0',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    name: {
      width: '60%',
      textAlign: 'left',
    },
    count: {
      width: '5%',
      textAlign: 'center',
    },
    time: {
      fontFamily: 'AtomicAge',
      width: '35%',
      textAlign: 'right',
    },
  }),
);

export default function ({ gameResults }: { gameResults: IGameResults }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container} data-testid="game-result-table">
      <div className={classes.row} data-testid="game-result-game-play">
        <div className={classes.name}>{t('game-results:game-play')}</div>
        <div className={classes.count} />
        <div className={classes.time}>{gameResults.gameTime}</div>
      </div>
      <div className={classes.row} data-testid="game-result-table-incorrect-answers">
        <div className={classes.name}>{t('game-results:incorrect-answers')}</div>
        <div className={classes.count}>{gameResults.incorrectCount}</div>
        <div className={classes.time}>{gameResults.incorrectPenalty}</div>
      </div>
      <div className={classes.row} data-testid="game-result-table-skipped-exrcises">
        <div className={classes.name}>{t('game-results:skipped-challenges')}</div>
        <div className={classes.count}>{gameResults.skipCount}</div>
        <div className={classes.time}>{gameResults.skipPenalty}</div>
      </div>
      <hr style={{ width: '100%' }} />
      <div className={classes.row} data-testid="game-result-table-total-time">
        <div className={classes.name}>{t('game-results:total-time')}</div>
        <div className={classes.count} />
        <div className={classes.time}>{gameResults.totalTime}</div>
      </div>
    </div>
  );
}
