import React, { Children } from 'react';

import { useStyles } from './styled';

export const ScrollableLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  const [header, body] = Children.toArray(children);
  return (
    <div className={classes.container}>
      <div className={classes.top}>{header}</div>
      <div className={classes.body}>{body}</div>
    </div>
  );
};
