import React, { createContext, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { size, keyBy, cloneDeep, findKey } from 'lodash';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Userpilot } from 'userpilot';
import { LocalParticipant } from 'twilio-video';
import { useSnackbar } from 'notistack';
import { datadogLogs } from '@datadog/browser-logs';
// @ts-ignore
import { useWindowResize, ResponsiveContext } from '@livingsecurity/cyberblocks';

import { mergeToFirebase, updateFirebaseField } from 'services/firestore';
import {
  ConnectionProblem,
  GameDefinition,
  GameplayProviderProps,
  GAME_STATES,
  IGlobalContext,
  Player,
  Players,
} from './services/types';
import GameService from './services/GameService';

import { encryptAES, getAudioPreference, isModeratorRole, setAudioPreference } from 'utils';

import { useAppState } from 'state';

import { useParticipants, useRoomState, useVideoContext } from 'hooks';

import { USER_NAME_SEPARATOR, PARTICIPANT_STATUSES, REGIONS } from '_constants';

import { getSnackbarClone } from './components/SnackbarClone';
import { I18N_LOCAL_STORAGE_KEY } from '../../utils/i18n';

const DEBUG = process.env.REACT_APP_DEBUG === 'true';
const DEFAULT_STATE = {
  debug: DEBUG,
  roomId: '',
  identity: '',
  gameDef: {} as GameDefinition,
  gameService: undefined as unknown as GameService,
  isLeader: false,
  setCurrentIdentity: () => {},
  setUsersNames: () => {},
  updateParticipantList: () => {},
  onDisconnect: () => {},
  setCurrentGameStep: () => {},
  switchAudio: () => {},
  isLeaderChosen: false,
  setWatchedStatus: () => {},
  roomSessionId: null,
  currentLeader: null,
  contentfulData: {},
  setConnectionStatus: () => {},
  connectionProblems: [],
  setConnectionProblems: () => {},
  startTimers: () => {},
  isSessionHosted: false,
};

export const GlobalContext = createContext<IGlobalContext>(DEFAULT_STATE);

export default function GameplayProvider({
  children,
  roomId,
  firebaseSettings,
  gameService,
  contentfulData,
}: GameplayProviderProps) {
  const { debugSession, disableUserPilot } = useFlags();
  const { t } = useTranslation();
  const responsiveContext = useContext<object>(ResponsiveContext);
  const { userEmail } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const { localTracks, room } = useVideoContext();
  const roomState = useRoomState();
  const videoParticipants = useParticipants();
  const [stepArgs, saveStepArgs] = useState<null | [string?, number?, number?, object?, string?]>(null);
  const [identity, setCurrentIdentity] = useState<string>('');
  const [isUpdatingStep, setUpdatingStep] = useState<boolean>(false);
  const [currentLeaderName, setCurrentLeaderName] = useState<string>();
  const [manuallyMuted, setManuallyMuted] = useState<boolean>(false);
  const roomDetails = gameService.getRoomDetails();
  const isSessionHosted = roomDetails.session.is_hosted;
  const gameDefinition = gameService.getGameDefinition();
  const { pixelRatio } = useWindowResize();
  const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
  const [isUserSetUsername, setIsUserSetUsername] = useState(false);
  const [connectionProblems, setConnectionProblems] = useState<ConnectionProblem[]>([]);

  const isLeaderChosen: boolean = useMemo(() => {
    return !!gameDefinition?.players && !!gameDefinition?.leader;
  }, [gameDefinition]);

  const currentLeader = useMemo(() => {
    return gameDefinition?.leader || null;
  }, [gameDefinition]);

  useEffect(() => {
    datadogLogs.onReady(() => {
      const locale = localStorage.getItem(I18N_LOCAL_STORAGE_KEY);
      datadogLogs.addLoggerGlobalContext('roomId', roomId);
      datadogLogs.addLoggerGlobalContext('companyId', roomDetails?.companyId);
      datadogLogs.addLoggerGlobalContext('email', userEmail);
      datadogLogs.addLoggerGlobalContext('usr.email', userEmail);
      datadogLogs.addLoggerGlobalContext('region', roomDetails?.session?.region || REGIONS.US);
      datadogLogs.addLoggerGlobalContext('hostedSession', roomDetails?.session?.is_hosted);
      datadogLogs.addLoggerGlobalContext('locale', locale || gameDefinition?.language);
      // eslint-disable-next-line camelcase
      datadogLogs.addLoggerGlobalContext('contentfulId', roomDetails?.session.contentful_src_id);
    });
    // In case of debug purposes, let's log the user's email address and session details
    if (userEmail && roomDetails) {
      datadogLogs.logger.debug(
        `Starting debug session for: ${userEmail} from company ID ${roomDetails.companyId} in room ID ${roomDetails.id}`,
      );
    }
  }, [roomDetails, userEmail, identity]);

  useEffect(() => {
    if (
      !disableUserPilot &&
      roomDetails &&
      userEmail &&
      (!window.location.href.includes('localhost') || process.env.REACT_APP_USERPILOT === 'true')
    ) {
      Userpilot.identify(`${userEmail}`, {
        name: identity,
        email: userEmail,
        product: 'TEAMS_GAMEPLAY',
        created_at: moment(roomDetails.sessionStart).format('X'),
        updated_at: moment().format(),
        tenantId: roomDetails.companyId,
        timezone: roomDetails.session.timezone,
        sessionId: roomDetails.id,
        contentfulId: roomDetails.session.contentful_src_id,
        company: {
          id: roomDetails.companyId,
        },
      });
    }
  }, [roomDetails, userEmail, identity, disableUserPilot]);

  useEffect(() => {
    if (!size(gameDefinition.players)) return;
    // do nothing if  twilio participants and players are not the same
    if (room && [...room?.participants.values(), room.localParticipant].length !== size(gameDefinition.players)) return;

    const newLeaderName = findKey(gameDefinition.players, (p: Player) => gameDefinition.leader === p.email);

    if (currentLeaderName !== newLeaderName && roomState === 'connected' && newLeaderName && newLeaderName.length) {
      const leaderString = !currentLeaderName ? 'gameplay:current-leader' : 'gameplay:new-leader';
      const snackbarText = t(leaderString, { newLeaderName: newLeaderName?.split(USER_NAME_SEPARATOR)[0] });

      setCurrentLeaderName(newLeaderName);
      enqueueSnackbar(snackbarText, {
        ...(connectionProblems.length ? { content: getSnackbarClone(snackbarText) } : {}),
      });
      datadogLogs.logger.debug(`${newLeaderName} became leader for session ${gameDefinition.__id}`);
    }

    if (
      size(gameDefinition.players) &&
      stepArgs &&
      Object.keys(gameDefinition.players).every((key: string) => gameDefinition.players[key].videoIsOver)
    ) {
      setCurrentGameStep(...stepArgs);
      saveStepArgs(null);
    }
  }, [gameDefinition?.players]); // eslint-disable-line

  useEffect(() => {
    if (gameDefinition?.players?.[identity]?.isMutedByModerator) {
      switchAudio(false, true);
      setAudioPreference(roomDetails.session.id, 'false');

      const snackbarText = t('gameplay:you-have-been-muted');

      enqueueSnackbar(snackbarText, {
        ...(connectionProblems.length ? { content: getSnackbarClone(snackbarText) } : {}),
      });
    }
  }, [gameDefinition?.players?.[identity]?.isMutedByModerator]);

  const setUsersNames = useCallback(
    async (playerName: string, teamName: string, email: string, groups: string[]) => {
      const cipherEmail = encryptAES(email, roomDetails!.session!.id);
      // Use last gameDefinition version cause we can call 'setUsersNames' function everywhere in project
      const gameDefinition = gameService.getGameDefinition();

      if (!gameDefinition) return;

      // If the user was previously in the game so we don't add them again as another "player"
      const previousUser =
        gameDefinition.players &&
        Object.keys(gameDefinition.players).find((key) => gameDefinition.players[key].email === cipherEmail);
      const previousJoinedPlayer: any =
        gameDefinition.joinedPlayers &&
        Object.values(gameDefinition.joinedPlayers).find((joinedPlayer: any) => joinedPlayer.playerName === playerName);
      const hasLeader = !!currentLeader;
      const isMuted = getAudioPreference(roomDetails.session.id) === 'false';

      // If their email was already used, and they're NOT using the same playerName (ie. joining again),
      // we need to remove that old player
      const newPlayers: Players = {};

      const currentJoinedPlayer = {
        [cipherEmail]: {
          playerName,
          teamSuggestion: teamName,
          clientDetails: {
            pixelRatio,
            supportsWebSockets,
            firebaseSettings: firebaseSettings || null,
            // speedTest: { duration, speedInMbps },
            responsiveContext,
          },
          status: previousJoinedPlayer?.status || PARTICIPANT_STATUSES.HAS_NOT_ARRIVED,
          groups,
          isMuted,
          isMutedByModerator: false,
        },
      };

      if (previousUser) {
        delete Object.assign(newPlayers, gameDefinition.players, {
          [playerName]: gameDefinition.players[previousUser],
        })[previousUser];

        await updateFirebaseField(roomId, {
          players: {
            ...newPlayers,
            [playerName]: gameDefinition.players[previousUser],
          },
          joinedPlayers: {
            ...gameDefinition.joinedPlayers,
            ...currentJoinedPlayer,
          },
        });
      } else {
        await mergeToFirebase(
          roomId,
          {
            players: {
              [playerName]: {
                videoIsOver: false,
                teamName,
                email: cipherEmail,
                status: previousJoinedPlayer?.status || PARTICIPANT_STATUSES.HAS_NOT_ARRIVED,
                groups,
                isMuted,
                isMutedByModerator: false,
              },
            },
            joinedPlayers: {
              ...currentJoinedPlayer,
            },
          },
          () => {},
          true,
        );
      }

      const leaderIsInGame = Object.values((size(newPlayers) ? newPlayers : gameDefinition.players) || {}).some(
        (player) => player.email === currentLeader,
      );

      if (!leaderIsInGame || size(gameDefinition.players) === 0 || !hasLeader) {
        await gameService.setLeader(cipherEmail);
      }

      setIsUserSetUsername(true);
    },
    [
      gameDefinition,
      firebaseSettings,
      pixelRatio,
      responsiveContext,
      roomDetails,
      roomId,
      supportsWebSockets,
      currentLeader,
    ],
  );

  const updateParticipantList = (localParticipant: LocalParticipant, gameDefinitionPlayers?: any) => {
    const twilioIdentities = Array.from(room?.participants.values() || [])
      .map((participant) => participant.identity)
      .concat(localParticipant.identity);
    const oldFirebasePlayers: Players = (gameDefinitionPlayers && cloneDeep(gameDefinitionPlayers)) || {};

    if (!size(oldFirebasePlayers)) return;

    let filteredPlayersStillInGame: Players | undefined;
    // this logic is to delete users that left the game
    if (size(oldFirebasePlayers) >= twilioIdentities.length) {
      // send intermediate game result when user is left
      gameService.sendGameResults({
        completed: false,
        endTime: moment().format('x'),
        intermediate: true,
      });

      filteredPlayersStillInGame = keyBy(
        twilioIdentities.sort().map((identity) => {
          const newPlayer = oldFirebasePlayers[identity] || {};
          newPlayer.name = identity;
          return newPlayer;
        }),
        'name',
      );
    }

    const getNewLeaderIfNeeded = (findIn: Players, previousLeader: string | null): string | void => {
      const isLeaderPresent = Object.values(findIn).some((player) => player.email === previousLeader);
      if (!isLeaderPresent || !previousLeader) {
        return Object.values(findIn)[0].email;
      }
    };

    const newLeader = getNewLeaderIfNeeded(filteredPlayersStillInGame || oldFirebasePlayers, currentLeader);

    const localParticipantEmail = oldFirebasePlayers[localParticipant.identity]?.email;
    const leaderIsCurrentUser =
      localParticipantEmail && (localParticipantEmail === currentLeader || localParticipantEmail === newLeader);

    if (!filteredPlayersStillInGame && leaderIsCurrentUser && newLeader) {
      gameService.setLeader(newLeader);
    }
    if (filteredPlayersStillInGame && leaderIsCurrentUser) {
      updateFirebaseField(roomId, {
        players: filteredPlayersStillInGame,
        ...(newLeader && { leader: newLeader }),
      })
        .then(() => DEBUG && console.log(filteredPlayersStillInGame))
        .catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    if (room?.localParticipant && isUserSetUsername) {
      updateParticipantList(room.localParticipant, gameDefinition?.players);
    }
  }, [videoParticipants, room?.localParticipant, isUserSetUsername]);

  useEffect(() => {
    if (
      gameDefinition.activeStep.gameState === GAME_STATES.PAUSED &&
      gameDefinition.players &&
      !Object.values(gameDefinition.players).some((player) => isModeratorRole(player.groups))
    ) {
      gameService.resumeGame();
    }
  }, [gameDefinition.players]);

  useEffect(() => {
    if (isUserSetUsername) {
      gameService.sendGameResults({
        completed: false,
        endTime: moment().format('x'),
        intermediate: true,
      });
    }
  }, [isUserSetUsername]);

  const isLeader: boolean = useMemo(() => {
    const currentPlayers = gameDefinition?.players;

    return (!!size(currentPlayers) && currentPlayers[identity]?.email === gameDefinition?.leader) || isSessionHosted;
  }, [gameDefinition, identity]);

  function setWatchedStatus(status: boolean) {
    const newStatus = status ? moment().unix() : false;
    if (gameDefinition.players && gameDefinition.players[identity]) {
      // We need to merge to Firebase to ensure we're only updating the current player
      mergeToFirebase(roomId, {
        players: { [identity]: { ...gameDefinition.players[identity], videoIsOver: newStatus } },
      })
        .then(() => DEBUG && status && console.log(`${identity} finished watching video`))
        .catch((e) => console.error(e));
    }
  }

  function setConnectionStatus(status: string) {
    if (gameDefinition.players && gameDefinition.players[identity]) {
      // We need to merge to Firebase to ensure we're only updating the current player
      mergeToFirebase(roomId, {
        players: { [identity]: { ...gameDefinition.players[identity], connectionStatus: status } },
      })
        .then(() => {
          datadogLogs.logger.info(`${identity} connection status is ${status}`);
        })
        .catch((e) => console.error(e));
    }
  }

  function switchAudio(enable: boolean, manually?: boolean) {
    if (manually) {
      setManuallyMuted(!enable);
    }

    if (manually || !manuallyMuted) {
      localTracks.forEach((track) => {
        if (track.kind === 'audio') {
          enable ? track.enable(true) : track.disable();
        }
      });
    }
  }

  function onDisconnect(...args: any[]): void {
    const playerName: string = args[0].localParticipant.identity;
    delete gameDefinition.players[playerName];
    updateFirebaseField(roomId, { players: gameDefinition.players });
  }

  const startTimers = useCallback(() => {
    updateFirebaseField(roomId, {
      'gameData.startTime': moment().format('x'),
    });
    datadogLogs.logger.info(`${identity} is setting game start time to ${moment().toISOString()}`);
  }, [roomId]);

  const setCurrentGameStep = useCallback(
    (
      gameState?: string,
      loop?: number,
      step?: number,
      stepData?: object,
      stepType?: string,
      successDatabaseCallback?: Function,
    ): void => {
      const oldState = gameDefinition;
      const gameData = oldState.gameData;
      const { players } = gameDefinition;

      if (stepType === 'video') {
        // Check and confirm that all players have completed the current video
        saveStepArgs([gameState, loop, step, stepData, stepType]);
        if (Object.keys(players).some((key) => !players[key].videoIsOver && key !== identity)) {
          datadogLogs.logger.info(`${identity} is waiting on others to finish video`);
          return;
        }
      }

      if (!isUpdatingStep && (isLeader || stepType === 'video')) {
        setUpdatingStep(true);

        // Ensure that the game only advances forward, unless it's going to the new loop, but even that must be forward
        if (
          isSessionHosted ||
          stepType === 'answer' ||
          (loop === 0 && step === 0) ||
          (step === 0 && !!loop && loop > oldState.activeStep.loop) ||
          (step !== 0 && step! > oldState.activeStep.step)
        ) {
          datadogLogs.logger.info(
            `${identity} is advancing the step and updating Firebase: ${JSON.stringify({
              gameState,
              loop,
              step,
              stepType,
              stepData: stepData || {},
            })}`,
          );

          // Merge updates to Firebase. Typically this is only done by the Leader, but when watching videos it is done by
          // the last player to finish their video
          mergeToFirebase(roomId, {
            activeStep: { gameState, loop, step, stepData: stepData || {} },
            gameData: {
              startTime: gameData.startTime,
            },
            players: Object.keys(gameDefinition?.players).reduce(
              (acc, key: string) => ({
                ...acc,
                [key]: {
                  ...gameDefinition?.players[key],
                  videoIsOver: false,
                },
              }),
              {},
            ),
          })
            .then(() => {
              datadogLogs.logger.info(`${identity} successfully updated the game step in Firebase`);
              setUpdatingStep(false);
              if (successDatabaseCallback) successDatabaseCallback();
            })
            .catch((e) => {
              console.error(`${identity} failed to update the game step in Firebase!`);
              console.error(e);
              setUpdatingStep(false);
            });
        } else if (loop! < oldState.activeStep.loop || step! < oldState.activeStep.step) {
          datadogLogs.logger.info(
            `${identity} from ${gameDefinition.__id} attempted to advance the game to a previous step or previous loop: `,
            {
              newLoop: loop,
              oldLoop: oldState.activeStep.loop,
              newStep: step,
              oldStep: oldState.activeStep.step,
              stepType,
              gameState,
            },
          );
          setUpdatingStep(false);
        } else {
          datadogLogs.logger.warn(
            `${identity} attempted to advance the step to an unexpected state: ${JSON.stringify({
              gameState,
              loop,
              step,
              stepType,
              stepData: stepData || {},
            })}`,
          );
          setUpdatingStep(false);
        }
      }
    },
    [isUpdatingStep, isLeader, roomId, gameDefinition], // eslint-disable-line
  );

  return (
    <GlobalContext.Provider
      value={{
        debug: DEBUG || debugSession || window.location.href.indexOf('debug') > -1,
        roomId,
        identity,
        isLeader,
        gameDef: gameDefinition,
        gameService,
        switchAudio,
        setUsersNames,
        updateParticipantList,
        setCurrentGameStep,
        setCurrentIdentity,
        setWatchedStatus,
        roomSessionId: roomDetails?.session.id || null,
        contentfulData,
        isLeaderChosen,
        currentLeader,
        onDisconnect,
        setConnectionStatus,
        connectionProblems,
        setConnectionProblems,
        startTimers,
        isSessionHosted,
      }}
    >
      {!!gameDefinition && !!gameDefinition.gameData && children}
    </GlobalContext.Provider>
  );
}
