import React from 'react';
import { createMuiTheme, createStyles, Theme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      display: 'flex',
      flex: '1 1 0px',
      flexDirection: 'row',
      flexBasis: '45%',
      justifyContent: 'center',
      borderRadius: '5px',
      border: 'solid 1px #c4c4c4',
      minWidth: '200px',
      minHeight: '50px',
      margin: '0 8px 10px',
      cursor: 'pointer',
      '&:active': {
        '-moz-box-shadow': 'inset 0 0 5px #c4c4c4',
        '-webkit-box-shadow': 'inset 0 0 5px #c4c4c4',
        boxShadow: 'inset 0 0 5px #c4c4c4',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '30px',
      },
    },
    optionDisabled: {
      cursor: 'default',
    },
    leftContainer: {
      width: '53px',
      backgroundColor: '#139ed8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: '25px',
      },
    },
    disabled: {
      backgroundColor: '#828282',
      cursor: 'default',
    },
    emptyCircle: {
      width: '20px',
      height: '20px',
      borderRadius: '25px',
      border: 'solid 2px #F2F2F2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '15px',
        height: '15px',
      },
    },
    selectedCircle: {
      width: '12px',
      height: '12px',
      borderRadius: '5px',
      background: '#F2F2F2',
      [theme.breakpoints.down('sm')]: {
        width: '9px',
        height: '9px',
      },
    },
    rightContainer: {
      width: 'calc(100% - 35px)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: '15px',
      fontSize: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        width: 'calc(100% - 20px)',
      },
    },
    notLeader: {
      cursor: 'default !important',
    },
  }),
);

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export default function (props: any) {
  const classes = useStyles();

  function handleClick() {
    if (props.callback && !props.disabled) {
      props.callback();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        className={clsx(classes.option, {
          [classes.optionDisabled]: props.disabled,
          [classes.notLeader]: !props.isLeader,
        })}
        style={{ ...props.styles }}
        onClick={handleClick}
        data-testid={props.testId || 'quiz-option'}
      >
        <div
          className={clsx(classes.leftContainer, {
            [classes.disabled]: props.disabled,
            [classes.notLeader]: !props.isLeader,
          })}
        >
          <div className={classes.emptyCircle}>
            {!!props.selected && !props.disabled && <div className={classes.selectedCircle} />}
          </div>
        </div>
        <div className={classes.rightContainer}>{props.children}</div>
      </div>
    </ThemeProvider>
  );
}
