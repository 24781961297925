import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// @ts-ignore
import { Button } from '@livingsecurity/cyberblocks';
import { makeStyles } from '@material-ui/core/styles';

import TeamsVideoPlayer, { videoContainer } from '../VideoView/TeamsVideoPlayer';
import WaitingList from 'components/WaitingList/WaitingList';
import { CircularProgress } from '@material-ui/core';

import { GlobalContext } from '../../GameplayProvider';
import ModalContainer from 'components/Modal/ModalContainer';
import { decryptAES } from 'utils';

import 'cloudinary-video-player/dist/cld-video-player.css';
import { useMoveForwardVideo } from 'hooks';

const useStyles = makeStyles((theme) => ({
  gameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    padding: '0',
    margin: '0 auto',
    color: '#F2F2F2',
  },
  videoContainer,
  modalContainer: {
    width: '450px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  showBackdrop: {
    opacity: 0,
  },
  loader: {
    color: theme.palette.common.white,
  },
}));

export default function GameIntro({
  step,
  isLeader,
  debug,
  modalData,
  setCurrentGameStep,
}: {
  step: number;
  isLeader: boolean;
  debug: boolean;
  modalData: Array<any>;
  setCurrentGameStep: Function;
}) {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isLeaderChosen = context.isLeaderChosen;
  const roomSessionId: number = context.gameService.getRoomDetails().session.id;
  const roomCapacity = context.gameService.getRoomDetails().session.max_participants_count;
  const signedUpParticipants = useMemo(() => {
    return (context.gameService.getRoomDetails().session.signedup_participants || []).map((email: string) => ({
      email,
    }));
  }, []);
  const players = useMemo(() => {
    return (Object.values(context.gameDef.players || {}) || []).map(({ email, status }) => ({
      email: decryptAES(email, roomSessionId),
      status,
    }));
  }, [context.gameDef.players]);
  const sessionLink: string = context.gameService.getRoomDetails().session.session_link;
  const storylineName = context.contentfulData.displayName;
  const { isSessionHosted } = context;

  const { afterText, calculateAfterText } = useMoveForwardVideo(context);

  useEffect(() => {
    const currentStep = modalData[step - 1];
    const currentVideo = currentStep?.videoFile && currentStep?.videoFile[0];

    // eslint-disable-next-line camelcase
    if (currentVideo?.public_id.length) {
      context.switchAudio(false);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [step]); // eslint-disable-line

  const progressStep = useCallback(() => {
    const currentStep = modalData[step - 1];

    // If the storyline contains no intro modal content at all, advance to the start of the game
    if (isLeader && !modalData.length) {
      startGame(true);
    } else {
      const currentVideo = currentStep?.videoFile && currentStep?.videoFile[0];
      setCurrentGameStep('INTRO', 0, step + 1, undefined, currentVideo ? 'video' : undefined);
      calculateAfterText('INTRO', 0, step + 1);
    }
  }, [isLeader, modalData, setCurrentGameStep]);

  const startGame = useCallback(
    (forceStart) => {
      if (isLeader) {
        // TODO: Can we assume that every game begins with a video?
        setCurrentGameStep('PLAYING', 0, 0, undefined, forceStart ? 'intro' : 'video');
      }
      calculateAfterText('PLAYING', 0, 0);
    },
    [isLeader, setCurrentGameStep],
  );

  const percentCallback = (percent: number) => {
    if (percent <= 20 && (context.gameDef?.players || {})[context.identity]?.videoIsOver) {
      context.setWatchedStatus(false);
    }
    if (percent === 100) {
      context.setWatchedStatus(true);
      context.switchAudio(true);
      if (step < modalData.length) {
        progressStep();
      } else {
        startGame(false);
      }
    }
  };

  function renderSteps() {
    const currentStep = modalData[step - 1];
    const currentVideo = currentStep?.videoFile && currentStep?.videoFile[0];

    if (step === 0) return renderZeroStep();

    if (step < 1) return;

    // eslint-disable-next-line camelcase
    if (currentVideo?.public_id.length) {
      return (
        <div className={classes.videoContainer}>
          {!loading && (
            <TeamsVideoPlayer
              publicId={currentVideo.public_id}
              captions={currentStep?.captionFile}
              language={context.gameDef.language}
              disableSeeking={!debug}
              afterText={afterText}
              percentCallback={percentCallback}
            />
          )}
        </div>
      );
    }

    if (step < modalData.length + 1) {
      return (
        <ModalContainer>
          <div className={classes.modalContainer}>
            <div>{documentToReactComponents(currentStep)}</div>
            {isLeader && (
              <div id="start-btn" style={{ marginTop: '30px' }}>
                <Button onClick={progressStep}>{t('modals:next')}</Button>
              </div>
            )}
          </div>
        </ModalContainer>
      );
    }
  }

  const renderZeroStep = () => (
    <>
      {!isSessionHosted && (
        <WaitingList
          link={sessionLink}
          signedUpParticipants={signedUpParticipants}
          players={players}
          storylineName={storylineName}
          sessionCapacity={roomCapacity}
        />
      )}
      <div id="start-btn">
        <Button onClick={progressStep} disabled={!isLeader}>
          {t('introduction:start')}
        </Button>
      </div>
    </>
  );

  return (
    <div className={classes.gameContainer}>
      {isLeaderChosen ? renderSteps() : <CircularProgress className={classes.loader} />}
    </div>
  );
}
