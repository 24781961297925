export enum ConnectionNotificationTypeEnum {
  WARNING = 'WARNING',
  FAIL = 'FAIL',
  INFO = 'INFO',
}

export enum ConnectionProblemTypeEnum {
  NETWORK = 'NETWORK',
  TWILIO = 'TWILIO',
}
