// @ts-ignore
import { SlideOutPanel } from '@livingsecurity/cyberblocks';
import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isObject } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Instruction, InstructionSection } from '../../services/types';
import { useTranslation } from 'react-i18next';

const { Wrapper, Slides, Title, CollapseBlock, Navigation, GoBack, DetailsWrapper, SubTitle, DetailsText } =
  SlideOutPanel;

const useStyles = makeStyles({
  instructionContainer: {
    marginLeft: '10px',
  },
});

export default function PuzzleInstructions({
  puzzleInstructions,
  generalInstructions,
}: {
  puzzleInstructions: Array<Instruction>;
  generalInstructions: Array<Instruction>;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<Instruction | null>(null);
  const sections: Array<InstructionSection> = [
    {
      title: t('gameplay:puzzle:current-exercise'),
      type: 'PUZZLE',
      items: puzzleInstructions || [],
    },
    {
      title: t('gameplay:puzzle:general'),
      type: 'GENERAL',
      items: generalInstructions || [],
    },
  ];

  const renderInstructions = (section: InstructionSection, next: Function) => {
    const instructions = section?.items;

    if (!instructions || !instructions.length) {
      return null;
    }
    return (
      <CollapseBlock title={section.title}>
        {instructions.map((item: Instruction, itemIdx: number) => {
          return (
            <Navigation
              title={item?.title}
              hasSubItem={!!item.body}
              onClick={() => (item.body ? updateActiveItem(item, next) : () => {})}
              key={itemIdx}
            />
          );
        })}
      </CollapseBlock>
    );
  };

  const updateActiveItem = (item: any, next: Function) => {
    setActiveItem(item);
    next();
  };

  return (
    <div className={clsx(classes.instructionContainer)}>
      <Wrapper iconColor="#FFFFFF">
        {sections.length && (
          <Slides.Wrapper>
            <Slides.Slide>
              {/* eslint-disable-next-line react/no-unused-prop-types */}
              {({ next }: { next: Function }) => (
                <>
                  <Title>{t('gameplay:puzzle:instructions')}</Title>
                  {sections?.map((section: any, index: number) => {
                    return renderInstructions(section, next);
                  })}
                </>
              )}
            </Slides.Slide>
            <Slides.Slide>
              {/* eslint-disable-next-line react/no-unused-prop-types */}
              {({ prev }: { prev: Function }) => (
                <>
                  <GoBack
                    title={t('gameplay:puzzle:back-to-instructions')}
                    onClick={() => updateActiveItem(null, prev)}
                  />
                  <DetailsWrapper>
                    <SubTitle>{activeItem?.title}</SubTitle>
                    <DetailsText>
                      {activeItem?.body && isObject(activeItem.body)
                        ? documentToReactComponents(activeItem.body)
                        : activeItem?.body}
                    </DetailsText>
                  </DetailsWrapper>
                </>
              )}
            </Slides.Slide>
          </Slides.Wrapper>
        )}
      </Wrapper>
    </div>
  );
}
