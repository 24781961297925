import React, { useContext, useState, useMemo } from 'react';
import clsx from 'clsx';
import { size } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LocalVideoTrack, Participant, RemoteVideoTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { useTranslation } from 'react-i18next';

import { useAudioLevel, usePublications, useIsTrackSwitchedOff, useTrack } from 'hooks';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { Players } from 'containers/GameplayPage/services/types';

import ThreeDotsParticipantMenu from 'components/ThreeDotsParticipantMenu';

import { mergeToFirebase } from 'services/firestore';
import { useAppState } from 'state';
import { isLSAdmin, isModerator } from 'utils';
import { USER_NAME_SEPARATOR } from '_constants';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';

import LeaderCircle from 'assets/images/LeaderCircle.svg';
import TakeLeadCircle from 'assets/images/TakeLeadCircle.svg';
import { ReactComponent as MicIcon } from 'assets/images/Micro.svg';
import { ReactComponent as MutedMicIcon } from 'assets/images/navbar/muted-mic.svg';

export const participantInfoStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: 1,
      flexBasis: 128,
      flexGrow: 0,
      flexShrink: 0,
    },
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 96,
      color: '#F2F2F2',
      backgroundColor: 'var(--black-two)',
      // backgroundColor: 'white',
      borderRadius: 3,
      '& video': {
        filter: 'none',
      },
      '@media (max-height: 750px)': {
        height: 75,
      },
      '@media (max-height: 650px)': {
        height: 60,
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      boxShadow: '0px 0px 0px 0px #85E5FF',
      transition: 'box-shadow 0.2s linear',
      borderRadius: 3,
    },
    isTalking: {
      margin: '1px',
      boxShadow: '0px 0px 0px 3px #85E5FF',
    },
    infoRow: {
      width: 110,
      height: 26,
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
    default: {
      cursor: 'default',
    },
    leaderCircleContainer: {
      borderRadius: '0 0 8px 0',
      position: 'relative',
      width: '25px',
      height: '22px',
      backgroundColor: 'var(--black-two)',
      '& img': {
        position: 'absolute',
        left: '2px',
        top: 0,
        width: '19px',
      },
      '@media (max-height: 650px)': {
        width: '20px',
        height: '19px',
        '& img': {
          height: '15px',
          left: 0,
        },
      },
    },
    nonLeader: {
      backgroundColor: '#85E5FF',
    },
    leadingLabel: {
      position: 'absolute',
      top: 0,
      left: 25,
      padding: '0 6px 1px 4px',
      fontSize: 10,
      borderRadius: '0 4px 4px 0',
      backgroundColor: 'var(--black-two)',
      color: 'var(--white)',
      '@media (max-height: 650px)': {
        left: '15px',
        fontSize: '10px',
      },
    },
    takeLeadLabel: {
      backgroundColor: '#85E5FF',
      color: 'var(--black-two)',
    },
    identityRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 'auto',
      width: '100%',
      color: 'var(--white)',
      fontSize: '0.875rem',
      background: 'linear-gradient(360deg, #121212 -27.27%, rgba(18, 18, 18, 0) 100%)',
      '&:hover': {
        background: 'linear-gradient(360deg, #121212 6.82%, rgba(18, 18, 18, 0) 161.36%)',
      },
    },
    nameRow: {
      padding: '2px 4px 3px',
      color: 'var(--white)',
      borderRadius: '0 4px 4px 0',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textShadow: '0px 0px 1.125px #000000',
      whiteSpace: 'nowrap',
    },
    iconRow: {
      height: '22px',
      marginRight: '5px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      zIndex: 0,
    },
    micIcon: {
      width: '12px',
      height: '12px',
    },
    childrenWrapper: {
      width: '100%',
      height: '100%',
      borderRadius: 3,
      overflow: 'hidden',
    },
    group: {
      padding: '2px 4px 0',
      color: 'var(--white)',
      fontSize: 12,
      textShadow: '0px 0px 1.125px #000000',
    },
  }),
);

export interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isLeader?: boolean;
}

// Could be usefull:
// ParticipantState.REDACTED - https://cdn.cyberescape.livingsecurity.com/image/redacted.png
// ParticipantState.WAITING - https://cdn.cyberescape.livingsecurity.com/image/waiting.png
// We used only ParticipantState.ACTIVE

// const DEBUG = process.env.REACT_APP_DEBUG === 'true';

const getUserGroup = (players: Players, identity: string): string | null => {
  if (!players || !identity) return null;

  const currentPlayerGroups = players[identity]?.groups;

  if (!currentPlayerGroups) return null;

  if (isLSAdmin(currentPlayerGroups)) return 'Living Security';
  if (isModerator(currentPlayerGroups)) return 'Host';

  return null;
};

export default function ParticipantInfo({ participant, children, isLeader }: ParticipantInfoProps) {
  const context = useContext(GlobalContext);
  const { t } = useTranslation();
  const publications = usePublications(participant);
  const { isCurrentUserModerator } = useAppState();
  const [loading, setLoading] = useState(false);

  const userGroup = useMemo(
    () => getUserGroup(context.gameDef.players, participant.identity),
    [context.gameDef.players, participant.identity],
  );

  const userEncryptedEmail = participant.identity.split(USER_NAME_SEPARATOR)[1];
  const isUserLeader = context.currentLeader === userEncryptedEmail;
  const players = context.gameDef.players || {};
  const isUserMuted = players[participant.identity]?.isMuted ?? false;

  // We reverse publications because when user gives permission after joining game it creates additional publication and we need to get last
  const audioPublication =
    publications &&
    publications
      .slice()
      .reverse()
      .find((p) => p.kind === 'audio');
  const videoPublication = publications && publications.find((p) => p.trackName.includes('camera'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  const [isTrackEnabled, audioLevel] = useAudioLevel(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const classes = participantInfoStyles();

  function isLocalUser(participant: Participant): boolean {
    // @ts-ignore
    return !!participant.signalingRegion;
  }

  async function updateLeader() {
    if (loading) return;

    setLoading(true);

    if (!players[participant.identity]) {
      // TODO: add interface for joinedPlayer
      const sameJoinedPlayer: any = Object.values(context.gameDef.joinedPlayers).find(
        (player: any) => player.playerName === participant.identity,
      );
      const sameJoinedPlayerGroups = sameJoinedPlayer?.groups || [];

      players[participant.identity] = {
        ...sameJoinedPlayer,
        index: size(players),
        name: participant.identity,
        isReady: true,
        teamName: '',
        email: userEncryptedEmail || '',
        groups: sameJoinedPlayerGroups,
      };

      await mergeToFirebase(context.roomId, { players });
    }

    await context.gameService.setLeader(userEncryptedEmail);

    setLoading(false);
  }

  async function muteParticipant() {
    if (loading) return;

    setLoading(true);

    try {
      await mergeToFirebase(context.roomId, {
        players: {
          ...players,
          [participant.identity]: {
            ...players[participant.identity],
            isMuted: true,
            isMutedByModerator: true,
          },
        },
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.container, {
          [classes.isVideoSwitchedOff]: isVideoSwitchedOff,
        })}
        data-cy-participant={participant.identity}
      >
        <div
          className={clsx(classes.infoContainer, {
            [classes.isTalking]: isTrackEnabled && audioLevel > 0.6,
          })}
        >
          {(isLeader || isLocalUser(participant)) && (
            <div
              id={isLeader ? 'leader-tag' : ''}
              className={clsx('toggle-lead-btn', classes.infoRow, { [classes.default]: isLeader })}
              onClick={isLeader ? () => {} : updateLeader}
            >
              <div className={clsx(classes.leaderCircleContainer, { [classes.nonLeader]: !isLeader })}>
                <img
                  src={isLeader ? LeaderCircle : TakeLeadCircle}
                  alt={isLeader ? 'Leader Label' : 'Take Lead Button'}
                />
              </div>
              <div className={clsx(classes.leadingLabel, { [classes.takeLeadLabel]: !isLeader })}>
                {isLeader ? t('gameplay:leader') : t('gameplay:take-lead')}
              </div>
            </div>
          )}
          {isCurrentUserModerator && !isLocalUser(participant) && (
            <ThreeDotsParticipantMenu
              setLeaderHandler={updateLeader}
              muteAudioHandler={muteParticipant}
              isSetLeaderDisabled={isUserLeader}
              isMuteAudioDisabled={isUserMuted}
            />
          )}
          <div className={classes.identityRow}>
            <div className={classes.nameRow} data-testid="participant-identity">
              {`${participant.identity.split(USER_NAME_SEPARATOR)[0]}`}
            </div>
            <div className={classes.iconRow}>
              {isTrackEnabled ? (
                <MicIcon className={classes.micIcon} data-testid="mic-icon" />
              ) : (
                <MutedMicIcon className={classes.micIcon} data-testid="muted-mic-icon" />
              )}
            </div>
          </div>
        </div>
        {isVideoSwitchedOff && <BandwidthWarning />}
        <div className={classes.childrenWrapper}>{children}</div>
      </div>
      {userGroup && <div className={classes.group}>{userGroup}</div>}
    </div>
  );
}
