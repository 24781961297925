import axios, { AxiosRequestConfig } from 'axios';
import * as t from './types';
import { Region } from 'types';

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/v1`,
});

export class CyberEscapeAPI {
  private getResource<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<T> {
    return instance.get<T>(url, config).then((r) => r.data);
  }

  private putResource<B, R>(url: string, data: B, config?: AxiosRequestConfig | undefined): Promise<R> {
    return instance.put<R>(url, data as B, config).then((r) => r.data);
  }

  private postResource<B, R>(url: string, data: B, config?: AxiosRequestConfig | undefined): Promise<R> {
    return instance.post<R>(url, data as B, config).then((r) => r.data);
  }

  public setAuthorizationHeader(token: string) {
    // @ts-ignore
    instance.defaults.headers.common.Authorization = token ? `JWT ${token}` : null;
  }

  /**
   * getLeaderBoard
   */
  public getLeaderBoard(roomId: string) {
    return this.getResource<t.CompanyRank>(`/room/${roomId}/leaderboard`);
  }

  /**
   * calculateRank
   */
  public calculateRank(roomId: string, timeInSeconds: t.RankBodyRequest['timeInSeconds']) {
    return this.postResource<t.RankBodyRequest, t.RankItem>(`/room/${roomId}/calculate-rank`, {
      timeInSeconds,
    });
  }

  /**
   * validateTeamName
   */
  public validateTeamName(roomId: string, teamName: t.TeamName['teamName']) {
    return this.postResource<t.TeamName, t.TeamAvailable | t.GenericResponse | t.ErrorResponse>(
      `/room/${roomId}/team-name/validate`,
      {
        teamName,
      },
    );
  }

  /**
   * setTeamName
   */
  public setTeamName(roomId: string, teamName: t.TeamName['teamName']) {
    return this.putResource<t.TeamName, t.TeamAvailable | t.GenericResponse | t.ErrorResponse>(
      `/room/${roomId}/team-name`,
      {
        teamName,
      },
    );
  }

  /**
   * getFirebaseToken
   */
  public getFirebaseToken(region: Region) {
    return this.getResource<any>(`/firebase/token?region=${region}`);
  }

  /**
   * joinRoom
   */
  public joinRoom(roomId: string, userName: t.Username['userName'], isJoinAfterRefresh: boolean) {
    return this.postResource<t.Username, t.GenericResponse>(`/room/${roomId}/join`, {
      userName,
      ...(isJoinAfterRefresh && { isJoinAfterRefresh: true }),
    });
  }

  /**
   * getRoomDetails
   */
  public getRoomDetails(roomId: string) {
    return this.getResource<t.RoomDetails>(`/room/${roomId}/details`);
  }

  /**
   * sendGameResults
   */
  public sendGameResults(roomId: string, gameResults: t.GameResults) {
    return this.postResource<t.GameResults, t.GenericResponse>(`/room/${roomId}/results`, gameResults);
  }

  /**
   * sendIntermediateResults
   */
  public sendIntermediateResults(roomId: string, gameResults: t.GameResults) {
    return this.postResource<t.GameResults, t.GenericResponse>(`/room/${roomId}/intermediate-results`, gameResults);
  }

  public getEmailByToken(token: string): Promise<string> {
    return this.postResource<{ token: string }, string>(
      '/game-sessions/get-email-by-token/',
      {
        token,
      },
      {
        baseURL: process.env.REACT_APP_MANAGEMENT_API,
      },
    );
  }
}

export const apiClient = new CyberEscapeAPI();
