import { useState, useEffect } from 'react';
import { ContentfulClient, ContentfulClientInterface } from 'react-contentful';

export default function useContentfulClient() {
  const [client, setClient] = useState<ContentfulClientInterface>();

  useEffect(() => {
    setClient(
      ContentfulClient({
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
        space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
        environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || '',
      }),
    );
  }, []);

  return client;
}
