import React from 'react';
import { Participant as IParticipant } from 'twilio-video';

import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  isLeader?: boolean;
}

function Participant({ participant, disableAudio, isLeader }: ParticipantProps) {
  return (
    <ParticipantInfo participant={participant} isLeader={isLeader}>
      <ParticipantTracks participant={participant} disableAudio={disableAudio} />
    </ParticipantInfo>
  );
}

export default React.memo(Participant);
