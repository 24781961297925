import React from 'react';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '600px',
    padding: '1em',
    cursor: 'pointer',
  },
});

export default function LandingPage() {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" className={classes.container}>
      <a href="https://livingsecurity.com">
        <img className={classes.logo} src="https://cdn.cyberescape.livingsecurity.com/image/logo.png" alt="Logo" />
      </a>
    </Grid>
  );
}
