import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ConnectionNotificationTypeEnum } from 'hooks/useConnectionStatus/types';

import CrossIcon from 'assets/images/cross-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    notification: ({ color }: { color: string }) => ({
      backgroundColor: 'var(--black)',
      border: `2px solid ${color}`,
      borderRadius: '8px',
      width: '448px',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '0.875rem',
      justifyContent: 'start',
      height: 'max-content',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 'none',
      },
    }),
    textContainer: {
      borderLeft: '1px solid #A2ACB3',
      padding: '0 16px',
      margin: '16px',
    },
    title: {
      padding: 0,
      margin: 0,
      fontWeight: 700,
    },
    subtitle: {
      padding: 0,
      margin: 0,
    },
    icon: {
      marginLeft: '33px',
    },
    close: {
      margin: '0 37px 0 21px',
      padding: 0,
    },
  }),
);

const ConnectionStatusToast = ({
  type,
  onToggle,
  text,
  color,
  icon,
}: {
  type: ConnectionNotificationTypeEnum;
  onToggle: () => void;
  text: { title: string; subtitle: string | JSX.Element };
  color: string;
  icon: any;
}) => {
  const classes = useStyles({ color });
  const notFailType = type !== ConnectionNotificationTypeEnum.FAIL;

  return (
    <div className={classes.notification}>
      <img src={icon} alt="connection icon" className={classes.icon} />
      <div className={classes.textContainer}>
        <p className={classes.title}>{text.title}</p>
        <p className={classes.subtitle}>{text.subtitle}</p>
      </div>
      {notFailType && (
        <IconButton className={classes.close} color="inherit" onClick={onToggle}>
          <img src={CrossIcon} alt="close icon" />
        </IconButton>
      )}
    </div>
  );
};
export default ConnectionStatusToast;
