/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import SessionBottomInfoBar from 'components/SessionBottomInfoBar/SessionBottomInfoBar';
import WaitingRoomContent from 'components/SessionBottomInfoBar/WaitingRoomBottomContent';

import { checkRoomAvailability, fetchContentfulData, fetchRoomDetails } from '../GameplayPage/utils';

import { RankTable } from 'components/RankTable';
import { apiClient } from 'services/api';
import * as type from 'services/api/types';
import { ResultsTable } from 'containers/TopTeamStandingsPage/components/ResultsTable';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { useAppState } from 'state';
import { getURLParamsAsObject } from '../../utils';
import { ErrorCodesEnum } from '../../components/ErrorDialog/enhanceMessage';
import { useContentfulClient } from '../../hooks';

const URLParams = getURLParamsAsObject();

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflowX: 'hidden',
    cursor: 'default',
  },
  bodyContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    minWidth: '700px',
    height: 'calc(100vh - 100px)',
  },
  headerContainer: {
    padding: '.5em 1em',
    fontSize: '36px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#f2f2f2',
  },
  leaderboardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    minWidth: '450px',
    height: 'calc(100% - 150px)',
    minHeight: '380px',
    background: 'none',
    color: 'black',
  },
  untimedContainer: {
    display: 'flex',
    height: '150px',
    fontSize: '1.5rem',
    marginTop: '5rem',
  },
  header: {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#f2f2f2',
    margin: '36px 0',
  },
  sessions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '1em',
    fontSize: '24px',
    width: '100%',
  },
  leaderboardColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#f2f2f2',
  },
  leaderboardHeader: {
    fontSize: '24px',
  },
  playerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    minWidth: '400px',
    height: 'auto',
    padding: '1em',
    margin: '2em',
    background: '#01050f',
    color: 'white',
    borderRadius: '3px',
    boxShadow: 'var(--green-box-shadow)',
  },
  toBeContinued: {
    margin: '0 auto .5em',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  videoContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  tableContainer: {
    padding: '0 2em',
  },
  resultsTable: {
    marginTop: '2em',
  },
  loaderWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressColor: {
    color: theme.palette.common.white,
    marginTop: '20px',
  },
}));

export default function WaitingRoomPage() {
  const { authChecked, isAuthenticated, setError } = useAppState();
  const { t } = useTranslation();
  const classes = useStyles();
  const [roomDetails, setRoomDetails] = useState<type.RoomDetails | null>(null);
  const [rankDetails, setRankDetails] = useState<type.CompanyRank>();
  const [contentfulData, setContentfulData] = useState<any>(null);
  const location = useLocation<{ from: Location }>();
  const contentfulClient = useContentfulClient();
  const roomId = location.pathname.split('/')[1];
  const debug = location.search.indexOf('debug') > -1;
  const allTimersHidden =
    contentfulData?.gameSettings?.hideCountdownTimer && contentfulData?.gameSettings?.hideAccruedTime;

  useEffect(() => {
    (async () => {
      try {
        const newRoomDetails = await fetchRoomDetails(roomId);
        setRoomDetails(newRoomDetails);
      } catch {
        setError({ message: t('warnings:something-wrong'), code: ErrorCodesEnum.FAILED_TO_FETCH_ROOM_DETAILS });
      }
    })();
  }, [roomId]); // eslint-disable-line

  useEffect(() => {
    if (roomDetails && !contentfulData) {
      fetchContentfulData(
        contentfulClient,
        roomDetails.session.contentful_src_id,
        roomDetails.session.language,
        (data) => {
          setContentfulData(data);
        },
      );
    }
  }, [roomDetails]);

  useEffect(() => {
    apiClient.getLeaderBoard(roomId).then(setRankDetails).catch(console.error);
  }, [roomId]);

  const isSessionEnded = roomDetails?.sessionState === type.SessionStateEnum.ENDED;

  const renderTitle = () => {
    if (!isSessionEnded) {
      return (
        <div className={classes.title} data-testid="waiting-room-title">
          {t('leaderboard:waiting-area')}
        </div>
      );
    }
    return (
      <>
        <div className={classes.title} data-testid="waiting-room-title-ended">
          {t('leaderboard:top-team-standings')}
        </div>
        <CopyToClipboard
          text={window.location.href}
          buttonText={t('game-results:copy-link')}
          testId="waiting-room-copy-to-clipboard"
        />
      </>
    );
  };

  const canJoin = useMemo(() => {
    const isPlayableSessionState =
      type.SessionStateEnum.INPROGRESS === roomDetails?.sessionState ||
      (type.SessionStateEnum.SCHEDULED === roomDetails?.sessionState &&
        moment().diff(moment(roomDetails.sessionStart), 'm', true) < 60);
    return (
      roomDetails?.sessionStart &&
      moment(roomDetails.sessionStart).diff(moment(), 'm', true) < 15 &&
      isPlayableSessionState
    );
  }, [roomDetails]);

  const gameLobbyLink = useMemo(() => {
    const { contentful_src_id: contentfulSrcId, language } = roomDetails?.session || {};
    const { campaign_token, tenant_id } = URLParams;
    return `/${roomDetails?.id}/play?content_id=${contentfulSrcId}&language=${language}&campaign_token=${campaign_token}&tenant_id=${tenant_id}`;
  }, [roomDetails]);

  const redirectToGameLobby = useCallback(async () => {
    const newRoomDetails = await fetchRoomDetails(roomId);
    setRoomDetails(newRoomDetails);
    const roomIsAvailable = checkRoomAvailability(newRoomDetails!);
    if (roomIsAvailable) {
      window.location.href = gameLobbyLink;
    }
  }, [gameLobbyLink]);

  useEffect(() => {
    if (isAuthenticated && canJoin) {
      window.location.href = gameLobbyLink;
    }
  }, [isAuthenticated, canJoin, gameLobbyLink]);

  return authChecked && !(isAuthenticated && canJoin) && roomDetails ? (
    <>
      <div className={classes.headerContainer}>{renderTitle()}</div>
      <Grid className={classes.container}>
        <div className={classes.bodyContainer}>
          {!allTimersHidden ? (
            <div className={classes.leaderboardContainer}>
              {!isSessionEnded && <div className={classes.header}>{t('leaderboard:teams-to-beat')}</div>}
              <div className={classes.tableContainer}>
                {isSessionEnded ? (
                  <div className={classes.resultsTable} data-testid="result-table-wrapper">
                    <ResultsTable />
                  </div>
                ) : (
                  <RankTable
                    headColumns={[t('leaderboard:rank'), t('leaderboard:team'), t('leaderboard:time')]}
                    rows={rankDetails?.topTen}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={classes.untimedContainer}>
              <WaitingRoomContent
                linkForReschedule={roomDetails?.session?.link_for_reschedule}
                sessionState={roomDetails.sessionState}
                sessionStart={roomDetails.sessionStart}
                sessionEnd={roomDetails.sessionEnd}
                redirectToGameLobby={redirectToGameLobby}
                debug={debug}
                allTimersHidden={allTimersHidden}
              />
            </div>
          )}
        </div>
      </Grid>
      {authChecked && !allTimersHidden && (
        <SessionBottomInfoBar>
          {roomDetails && roomDetails.session && (
            <WaitingRoomContent
              linkForReschedule={roomDetails?.session?.link_for_reschedule}
              sessionState={roomDetails.sessionState}
              sessionStart={roomDetails.sessionStart}
              sessionEnd={roomDetails.sessionEnd}
              redirectToGameLobby={redirectToGameLobby}
              debug={debug}
            />
          )}
        </SessionBottomInfoBar>
      )}
    </>
  ) : (
    <div className={classes.loaderWrapper}>
      <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
    </div>
  );
}
