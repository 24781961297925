import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttonWrapper: {
    position: 'relative',
  },
  button: {
    color: '#fff',
    padding: '6px 28px',
    borderRadius: '20px',
    '&$disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
  },
  // need for increasing specificity
  disabled: {},
  loadIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
});
