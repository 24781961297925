import React, { useState, useEffect, useRef, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import TeamsVideoPlayer from './TeamsVideoPlayer';

import { CEOVideo } from '../../services/types';
import { useMoveForwardVideo } from 'hooks';
import { GlobalContext } from '../../GameplayProvider';

const mutedVideo = 'https://cdn.cyberescape.livingsecurity.com/video/mutedVideo.mp4';
// const mutedVideoShort = 'https://cdn.cyberescape.livingsecurity.com/video/mutedVideoShort.mp4';

const useStyles = makeStyles({
  videoContainer: {
    background: '#121212',
    border: '2px solid #A2ACB3',
    borderRadius: 10,
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 32px)',
    margin: 'auto',
    position: 'relative',
    padding: '20px 35px',
  },
  video: ({ isVideoLoaded }: { isVideoLoaded: boolean }) => ({
    position: 'absolute',
    left: 10,
    top: 10,
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    display: isVideoLoaded ? 'initial' : 'none',
    '&::-webkit-media-controls': {
      display: 'none',
    },
    boxShadow: 'var(--green-box-shadow)',
    backgroundColor: '#000000',
  }),
  progressColor: {
    color: '#ffffff',
  },
});

export default function VideoView({
  currentStep,
  calculateNextStep,
  language,
  isLoop,
}: {
  currentStep: CEOVideo;
  calculateNextStep: Function;
  language?: string;
  isLoop?: boolean;
}) {
  const [isPreviewTurnOn, setPreviewTurnOn] = useState(isLoop);
  const [isVideoLoaded, setVideoLoaded] = useState(false);

  const classes = useStyles({ isVideoLoaded });

  const context = useContext(GlobalContext);
  const { afterText, calculateAfterText } = useMoveForwardVideo(context);
  const contextRef = useRef(context);
  const { hideMutingParticipantVideo } = useFlags();
  const { isSessionHosted: hostedVersion } = context;
  contextRef.current = context;
  // const { loop, step } = context.gameDef.activeStep;

  // const isFirstVideo = loop === 0 && step === 0;
  const disablePreview = () => {
    setPreviewTurnOn(false);
  };
  const videoOnLoad = () => {
    setVideoLoaded(true);
  };

  const handlePercentChange = async (percent: number) => {
    if (percent <= 25 && (context.gameDef?.players || {})[context.identity]?.videoIsOver) {
      context.setWatchedStatus(false);
    }
    if (percent === 100) {
      context.setWatchedStatus(true);
      const nextSteps = await calculateNextStep();
      context.setCurrentGameStep(nextSteps.gameState, nextSteps.loop, nextSteps.step, undefined, 'video');
      calculateAfterText(nextSteps.gameState, nextSteps.loop, nextSteps.step);
    }
  };

  useEffect(() => {
    // This disables each player's microphone
    context.switchAudio(false);
    context.setWatchedStatus(false);
    return () => {
      // updated context func for unmount case
      contextRef.current.switchAudio(true);
    };
  }, []); // eslint-disable-line

  return isPreviewTurnOn && !hostedVersion && !hideMutingParticipantVideo ? (
    <div className={classes.videoContainer}>
      <video
        className={classes.video}
        src={mutedVideo} // {isFirstVideo ? mutedVideo : mutedVideoShort} // Disabling w/ TEAMS-1831
        autoPlay
        onEnded={disablePreview}
        onLoadedData={videoOnLoad}
        muted
      />
    </div>
  ) : (
    <TeamsVideoPlayer
      publicId={currentStep.videoFile[0].public_id}
      captions={currentStep.captionFile}
      language={language}
      disableSeeking={!context.debug}
      afterText={afterText}
      percentCallback={handlePercentChange}
    />
  );
}
