import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from '@material-ui/core';
import StatusBlock from '../StatusBlock';

const AudioTroubleBlock = () => {
  const { t } = useTranslation();
  const title = t('join-status:audio-trouble-title');
  const description = (
    <Trans
      i18nKey="join-status:audio-looks-bad"
      components={[
        <Link
          href="https://support.livingsecurity.com/hc/en-us/articles/360055741614-How-do-I-enable-my-microphone-and-webcam-for-the-experience-"
          target="_blank"
          rel="noreferrer noopener nofollow"
          key={0}
          style={{
            color: '#89BBFF',
          }}
        />,
        <Link
          key={2}
          style={{
            color: '#89BBFF',
          }}
          onClick={() => document.location.reload()}
        />,
      ]}
    />
  );

  return <StatusBlock title={title} description={description} withStatus={false} testId="audio-trouble" />;
};

export default AudioTroubleBlock;
