import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ScrollableLayout } from 'components/layouts';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { ResultsTable } from './components/ResultsTable';
import TeamsVideoPlayer from '../GameplayPage/components/VideoView/TeamsVideoPlayer';

import { useStyles } from './styled';

export const TopTeamStandingsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ScrollableLayout>
      <header className={classes.container}>
        <h1 className={classes.title}>{t('leaderboard:top-team-standings')}</h1>
        <CopyToClipboard text={window.location.href} buttonText="COPY PAGE LINK" />
      </header>
      <main className={clsx(classes.container, classes.main)}>
        <div className={classes.banner}>
          <TeamsVideoPlayer publicId="CEO-IntroVideo_kbgmjx" playerOptions={{ muted: true }} disableSeeking />
        </div>
        <ResultsTable />
      </main>
    </ScrollableLayout>
  );
};

export default TopTeamStandingsPage;
