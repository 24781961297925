import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { getJWT, parseJWT, removeJWT, AUTH_NAMESPACE } from 'services/token';
import { useAppState } from 'state';

export default function useCheckAuth0TokenStatus() {
  const history = useHistory();
  const { setIsAuthenticated } = useAppState();

  const handleTokenError = (campaignToken?: string) => {
    // TODO: do we really need campaignToken? what is the case to use it?
    removeJWT();
    setIsAuthenticated(false);
  };

  const checkAuth0TokenStatus = async (): Promise<string> => {
    const token = getJWT();

    if (!token) {
      history.replace('/');
      return Promise.reject(new Error('Missing JWT token from Auth0'));
    }

    const tokenValue = parseJWT(token);
    const expirationMoment = moment(tokenValue.exp, 'X');

    if (expirationMoment.isSameOrBefore(moment())) {
      const claims = tokenValue[AUTH_NAMESPACE];
      const campaignToken = claims.campaign_token;
      handleTokenError(campaignToken);
      return Promise.reject(new Error(`Expired JWT token from Auth0: ${expirationMoment.format()}`));
    }

    return token;
  };

  return checkAuth0TokenStatus;
}
