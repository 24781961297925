import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SettingsIcon from 'assets/images/navbar/settings.svg';

import NavbarButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/NavbarButton';
import { JoinModalContent } from 'containers/SessionSignupPage/JoinModal';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { Dialog } from 'components/Dialog';

import { USER_NAME_SEPARATOR } from '_constants';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      maxWidth: 'initial',
      '@media (max-width: 1024px)': {
        borderRadius: 0,
      },
    },
    root: {
      padding: 0,
      '@media (max-width: 1024px)': {
        paddingBottom: '50px',
      },
    },
  }),
);

export default function SettingsButton() {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const classes = useStyles();

  const changeSettingsStatus = useCallback(
    (value) => () => {
      setSettingsOpen(value);
    },
    [],
  );

  useEffect(() => {
    const body = document.body;

    if (body.clientWidth <= 1024) setFullScreen(true);

    const resizeObserver = new (window as any).ResizeObserver((entries: any) => {
      for (const entry of entries) {
        setFullScreen(entry.contentRect.width <= 1024);
      }
    });

    resizeObserver.observe(body);

    return () => resizeObserver.unobserve(body);
  }, []);

  return (
    <>
      <NavbarButton icon={SettingsIcon} onClick={changeSettingsStatus(true)} id="header-settings-icon">
        {t('bottom-bar:settings')}
      </NavbarButton>
      <Dialog
        className="Dialog"
        title={t('sign-up:your-settings')}
        okText={t('modals:done')}
        open={settingsOpen}
        onClose={changeSettingsStatus(false)}
        fullWidth={false}
        classes={{
          paper: classes.paper,
          root: classes.root,
        }}
        withActions={false}
        fullScreen={fullScreen}
      >
        <JoinModalContent
          handleSubmit={() => {}}
          userName={`${context.identity.split(USER_NAME_SEPARATOR)[0]}`}
          onClose={changeSettingsStatus(false)}
          fromSettings
        />
      </Dialog>
    </>
  );
}
