import { useCallback, useEffect, useState, useMemo } from 'react';
import Video, { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions } from 'twilio-video';
import { GaussianBlurBackgroundProcessor } from '@twilio/video-processors';

import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY, VideoBlurValuesEnum } from '_constants';
import { getDeviceInfo, getVideoBlur, setVideoBlur } from 'utils';

export default function useLocalTracks(isSessionHosted: boolean) {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const [isProcessorAvailable, setIsProcessorAvailable] = useState(false);
  const [isProcessorOn, setIsProcessorOn] = useState(false);
  const [processor, setPropcessor] = useState<GaussianBlurBackgroundProcessor>();

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(
    async (newOptions?: CreateLocalTrackOptions) => {
      // In the DeviceSelector and FlipCameraButton components, a new video track is created,
      // then the old track is unpublished and the new track is published. Unpublishing the old
      // track and publishing the new track at the same time sometimes causes a conflict when the
      // track name is 'camera', so here we append a timestamp to the track name to avoid the
      // conflict.
      const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
      const { videoInputDevices } = await getDeviceInfo();

      const hasSelectedVideoDevice = videoInputDevices.some(
        (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
      );

      const options: CreateLocalTrackOptions = {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
        ...newOptions,
      };

      return Video.createLocalVideoTrack(options).then((newTrack) => {
        setVideoTrack(newTrack);

        if (isProcessorAvailable && isProcessorOn && processor) {
          newTrack.addProcessor(processor);
        }

        return newTrack;
      });
    },
    [isProcessorAvailable, isProcessorOn, processor],
  );

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const addBlur = () => {
    if (!processor || !isProcessorAvailable || !videoTrack) return;

    videoTrack.addProcessor(processor);
    setIsProcessorOn(true);
    setVideoBlur(VideoBlurValuesEnum.true);
  };

  const removeBlur = () => {
    if (!processor || !isProcessorAvailable || !videoTrack) return;

    videoTrack.removeProcessor(processor);
    setIsProcessorOn(false);
    setVideoBlur(VideoBlurValuesEnum.false);
  };

  useEffect(() => {
    if (isSessionHosted) return;
    try {
      setIsAcquiringLocalTracks(true);

      const blurBackground = new GaussianBlurBackgroundProcessor({
        assetsPath: '/public',
      });

      let isBlurLoaded = false;
      const isPreviousBlur = getVideoBlur() === VideoBlurValuesEnum.true;

      blurBackground
        .loadModel()
        .then(() => {
          setIsProcessorAvailable(true);
          setPropcessor(blurBackground);
          isBlurLoaded = true;

          if (isPreviousBlur) {
            setIsProcessorOn(true);
          }
        })
        .catch(() => {
          console.error('Blur was not loaded');
        })
        .finally(() => {
          Video.createLocalTracks({
            video: {
              ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
              name: `camera-${Date.now()}`,
            },
          }).then((tracks) => {
            const videoTrack = tracks.find((track) => track.kind === 'video') as LocalVideoTrack;
            if (videoTrack) {
              if (isPreviousBlur && isBlurLoaded) {
                videoTrack.addProcessor(blurBackground);
              }

              setVideoTrack(videoTrack);
              window.localStorage.setItem(
                SELECTED_VIDEO_INPUT_KEY,
                videoTrack.mediaStreamTrack.getSettings().deviceId ?? '',
              );
            }
          });
        });

      Video.createLocalTracks({ audio: true }).then((tracks) => {
        const audioTrack = tracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
        if (audioTrack) {
          setAudioTrack(audioTrack);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsAcquiringLocalTracks(false);
    }
  }, [isSessionHosted]);

  const localTracks = useMemo(() => {
    return [audioTrack, videoTrack].filter((track) => track !== undefined) as (LocalAudioTrack | LocalVideoTrack)[];
  }, [audioTrack, videoTrack]);

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isProcessorAvailable,
    isProcessorOn,
    addBlur,
    removeBlur,
  };
}
