import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import copy from 'clipboard-copy';

import { useStyles } from './styled';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { getSnackbarClone } from 'containers/GameplayPage/components/SnackbarClone';

type ClickFunctionChildren = (args: () => Promise<string | number>) => React.ReactNode;
type CopyProps = {
  text: string;
  buttonText?: string;
  copyText?: string;
  children?: React.ReactNode | ClickFunctionChildren;
  testId?: string;
};

export const CopyToClipboard = ({ text, buttonText, copyText, testId, children }: CopyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { connectionProblems } = useContext(GlobalContext);

  const successText: string = copyText || t('wait-list:link-copied');
  const errorText = t('warnings:something-wrong');

  const handleClick = (text: string) => () =>
    copy(text)
      .then(() =>
        enqueueSnackbar(successText, {
          preventDuplicate: true,
          ...(connectionProblems.length ? { content: getSnackbarClone(successText) } : {}),
        }),
      )
      .catch(() =>
        enqueueSnackbar(errorText, {
          variant: 'error',
          preventDuplicate: true,
          ...(connectionProblems.length ? { content: getSnackbarClone(errorText) } : {}),
        }),
      );

  return typeof children !== 'function' ? (
    <Button
      classes={{ label: classes.buttonText }}
      onClick={handleClick(text)}
      data-testid={testId || 'copy-to-clipboard'}
    >
      {buttonText || t('gameplay:copy-to-clipboard')}
    </Button>
  ) : (
    children(handleClick(text))
  );
};
