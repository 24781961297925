import React from 'react';
import { useContextMenu } from 'hooks';

import pkg from '../../../package.json';

interface VersionControl {
  className?: string;
  children: React.ReactNode;
}

export const VersionControl = ({ children, className }: VersionControl) => {
  const { bindMenu, bindTrigger } = useContextMenu();
  return (
    <div {...bindTrigger} className={className} style={{ width: '100%', height: '100%' }}>
      {children}
      <div {...bindMenu}>Version: {pkg.dependencies['@livingsecurity/cyberpuzzles']}</div>
    </div>
  );
};
