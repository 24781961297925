import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  errorDialog: {
    background: '#002638',
    borderRadius: 10,
    boxShadow: 'var(--green-box-shadow)',
  },
  title: {
    color: '#fff',
    background: '#2d9cdb',
    padding: '.5em 1em',
    fontSize: '26px',
  },
  contentText: {
    color: '#fff',
  },
});
