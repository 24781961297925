import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import { Button } from '@livingsecurity/cyberblocks';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { GAME_STATES, instanceOfCEOVideo } from 'containers/GameplayPage/services/types';

import { useAppState } from 'state';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginRight: 30,
      marginLeft: 10,
      padding: '10px 24px',
      lineHeight: '16px',
      '&.isDisabled': {
        pointerEvents: 'none',
        opacity: '.5',
      },
    },
  }),
);

export default function PauseGameButton() {
  const { t } = useTranslation();
  const { isCurrentUserModerator } = useAppState();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const context = useContext(GlobalContext);

  const { gameDef, contentfulData, gameService } = context;
  const { loops: gameLoop, gameSettings } = contentfulData;
  const { gameState, loop, step } = gameDef.activeStep;

  const currentStep = gameLoop[loop] && gameLoop[loop][step];
  const gameStep = useMemo(() => {
    return typeof currentStep === 'string' ? JSON.parse(currentStep) : currentStep;
  }, [currentStep]);

  const isDisabled = instanceOfCEOVideo(gameStep) || !gameDef.gameData.startTime;

  const isButtonVisible = useMemo(
    () => isCurrentUserModerator && [GAME_STATES.PLAYING, GAME_STATES.PAUSED].includes(gameState),
    [isCurrentUserModerator, gameState],
  );

  const isPausedGameState = useMemo(() => gameState === GAME_STATES.PAUSED, [gameState]);

  if (!isButtonVisible) return null;

  const changeGameState = async () => {
    if (loading || isDisabled) return;

    setLoading(true);

    try {
      isPausedGameState ? await gameService.resumeGame() : await gameService.pauseGame(gameSettings.gameLengthMinutes);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button classes={clsx(classes.button, { isDisabled })} onClick={changeGameState}>
      {isPausedGameState ? t('bottom-bar:resume') : t('bottom-bar:pause')}
    </Button>
  );
}
