// @ts-ignore
import { Loader } from '@livingsecurity/cyberblocks';
import React, { useContext, useMemo, useEffect, useCallback, useState } from 'react';
import TeamsVideoPlayer, { videoContainer } from 'containers/GameplayPage/components/VideoView/TeamsVideoPlayer';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { PARTICIPANT_STATUSES, USER_NAME_SEPARATOR } from '_constants';
import { updateFirebaseField } from 'services/firestore';
import { useMainParticipant } from 'hooks';

const STORAGE_KEY = 'watched-intro';

const useStyles = makeStyles({
  videoContainer,
  loaderWrapper: {
    margin: 'auto',
  },
});

function InstructionVideo({ hideInstructions }: { hideInstructions: () => void }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const mainParticipant = useMainParticipant();
  const [isParticipantStatusSet, setIsParticipantStatusSet] = useState(false);
  const alreadyWatchedIntro = sessionStorage.getItem(STORAGE_KEY) === globalContext.roomId;

  const instructionVideo = useMemo(() => {
    const instruction = globalContext?.contentfulData?.instructionVideo?.fields;
    if (!instruction) return null;

    return {
      publicId: instruction.videoFile[0].public_id,
      captionFile: instruction.captionFile,
    };
  }, [globalContext.contentfulData]);

  const currentUser = useMemo(() => {
    return !!mainParticipant?.identity && globalContext.gameDef.players?.[mainParticipant.identity];
  }, [mainParticipant?.identity, globalContext.gameDef.players]);

  const updateParticipantStatus = (status: string) => {
    if (!currentUser || !mainParticipant?.identity) return;

    const playerEncryptedEmail = mainParticipant.identity.split(USER_NAME_SEPARATOR)[1];
    const joinedPlayer: any = globalContext.gameDef.joinedPlayers[playerEncryptedEmail];

    return updateFirebaseField(globalContext.roomId, {
      players: {
        ...globalContext.gameDef.players,
        [mainParticipant.identity]: {
          ...globalContext.gameDef.players[mainParticipant.identity],
          status,
        },
      },
      joinedPlayers: {
        ...globalContext.gameDef.joinedPlayers,
        [playerEncryptedEmail]: {
          ...joinedPlayer,
          status,
        },
      },
    });
  };

  const handleClose = useCallback(async () => {
    if (!mainParticipant?.identity || !globalContext.gameDef.players?.[mainParticipant.identity]) return;
    globalContext.switchAudio(true);
    sessionStorage.setItem(STORAGE_KEY, globalContext.roomId);
    await updateParticipantStatus(PARTICIPANT_STATUSES.READY);
    hideInstructions();
  }, [globalContext.gameDef.players, mainParticipant?.identity]);

  const handlePercentChange = useCallback(
    (percent: number) => {
      if (percent === 100) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    globalContext.switchAudio(false);
  }, []);

  useEffect(() => {
    // Added so much checks cause some fields are not setted when the component render
    if (
      !alreadyWatchedIntro &&
      !isParticipantStatusSet &&
      mainParticipant?.identity &&
      currentUser &&
      currentUser.status !== PARTICIPANT_STATUSES.READY
    ) {
      updateParticipantStatus(PARTICIPANT_STATUSES.WATCHING_INTRO_VIDEO);
      setIsParticipantStatusSet(true);
    }
  }, [globalContext.gameDef.players, mainParticipant?.identity]);

  useEffect(() => {
    if ((!instructionVideo || alreadyWatchedIntro) && currentUser) {
      handleClose();
    }
  }, [instructionVideo, alreadyWatchedIntro, currentUser]);

  if (!instructionVideo || alreadyWatchedIntro) {
    return (
      <div className={classes.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classes.videoContainer}>
      <TeamsVideoPlayer
        publicId={instructionVideo.publicId}
        captions={instructionVideo.captionFile}
        language={globalContext.gameDef.language}
        showSkip
        skipText={t('video-player:skip-intro')}
        percentCallback={handlePercentChange}
      />
    </div>
  );
}

export default InstructionVideo;
