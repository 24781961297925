import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '36px',
    padding: '.35em 1em',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#f2f2f2',
    margin: '0 0 7px 0',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '6fr 4fr',
    columnGap: '68px',
    padding: '.5em 1em',
  },
  banner: {
    boxShadow: 'var(--green-box-shadow)',
    height: 'min-content',
  },

  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressColor: {
    color: theme.palette.common.white,
  },

  divider: {
    height: '3px',
    backgroundColor: '#f2f2f2',
    marginTop: 20,
    marginBottom: 20,
  },
}));
