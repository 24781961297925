import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';

import { useAppState } from '../../state';
import { getJWT, getRoomId, removeAuthData } from 'services/token';
import { apiClient } from '../../services/api';
import { getURLParamsAsObject, getRoomIdFromPathname } from 'utils';

const URLParams = getURLParamsAsObject();

interface IEmailState {
  isFetched: boolean;
  email: string | null;
}

const DEFAULT_EMAIL_STATE: IEmailState = {
  isFetched: false,
  email: null,
};

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { user, isAuthenticated, setIsAuthenticated } = useAppState();
  const [emailState, setEmailState] = useState<IEmailState>(DEFAULT_EMAIL_STATE);
  const [isSessionChecked, setIsSessionChecked] = useState(false);

  useEffect(() => {
    const savedRoomId = getRoomId();

    if (!savedRoomId) return;

    const currentRoomId = getRoomIdFromPathname();

    if (savedRoomId !== currentRoomId) {
      removeAuthData();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!URLParams.token) return;

      try {
        const email = await apiClient.getEmailByToken(URLParams.token);
        setEmailState({
          isFetched: true,
          email,
        });
      } catch (e) {
        console.error(e);
        setEmailState((prev) => ({
          ...prev,
          isFetched: true,
        }));
      }
    })();
  }, []);

  const finishAuthentication = (token?: string): void => {
    if (token) {
      apiClient.setAuthorizationHeader(token);
      setIsAuthenticated(true);
      setIsSessionChecked(true);
    }

    setIsSessionChecked(true);
  };

  const loginWithRedirect = useCallback(async () => {
    const urlEnd = window.location.href.split('/')?.[3] || '';
    const sessionId = urlEnd.split('?')?.[0] || '';
    const { sso } = getURLParamsAsObject();
    const redirectUrl = `${window.location.origin}/redirect${window.location.search}&sessionId=${sessionId}`;

    const auth0 = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH_DOMAIN || '',
      clientId: sso ? process.env.REACT_APP_AUTH_CLIENT_ID_SSO || '' : process.env.REACT_APP_AUTH_CLIENT_ID || '',
      authorizationParams: {
        response_type: 'token id_token',
        redirect_uri: redirectUrl,
      },
    });

    await auth0.loginWithRedirect({
      authorizationParams: { connection: !sso ? 'email' : undefined },
      appState: { targetUrl: window.location.href },
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated && isSessionChecked) return;

    const storedToken = getJWT();

    if (storedToken) {
      const token: any = jwtDecode(storedToken);

      if (token.exp * 1000 > Date.now()) {
        finishAuthentication(storedToken);
      } else {
        loginWithRedirect();
      }
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, setIsAuthenticated, isSessionChecked]);

  const renderChildren = user || !process.env.REACT_APP_SET_AUTH;

  if ((!renderChildren && !isAuthenticated) || !isSessionChecked) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: '#fff' }}>
        <CircularProgress color="inherit" size={40} />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          renderChildren ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        // eslint-disable-next-line
      }
    />
  );
}
