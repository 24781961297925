import React from 'react';
import Navbar from 'components/Navbar/Navbar';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      color: '#F2F2F2',
      fontSize: '1.125rem',
      lineHeight: '18px',
      fontWeight: 'bold',
    },
  }),
);

function SessionNameNavbar({ displayName }: { displayName: string }) {
  const classes = useStyles();

  return (
    <Navbar>
      <div>
        <div className={classes.text} data-testid="signup-cyber-escape-online">
          CyberEscape Online
        </div>
        <div className={classes.text} data-testid="signup-game-title">
          {displayName}
        </div>
      </div>
    </Navbar>
  );
}

export default SessionNameNavbar;
