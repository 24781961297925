import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useOutsideClick, useVideoContext } from 'hooks';

const styles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      '&.isDisabled': {
        pointerEvents: 'none',
        opacity: '.3',
      },
    },
    threeDots: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      height: 47,
      borderRadius: 2,
      cursor: 'pointer',
      transition: 'background .2s',
      '&:hover': {
        background: '#373636',
        '& > span': {
          background: 'var(--white)',
        },
      },
      '&.isOpened': {
        background: '#373636',
        '& > span': {
          background: 'var(--white)',
        },
      },
    },
    dot: {
      width: 3,
      height: 3,
      background: '#626E76',
      borderRadius: '50%',
      transition: 'background .2s',
      '&:nth-child(2)': {
        margin: '3px 0',
      },
    },
    menu: {
      position: 'absolute',
      top: '-10px',
      left: 'calc(100% + 5px)',
      padding: '7px 0',
      background: 'var(--white)',
      border: '1px solid #0083A3',
      borderRadius: 4,
      boxShadow: '0px 6px 12px rgba(23, 48, 66, 0.08), 0px 8px 24px rgba(23, 48, 66, 0.04)',
    },
    menuItem: {
      padding: '0 16px',
      color: '#373636',
      cursor: 'pointer',
      fontSize: 14,
      lineHeight: '125%',
      whiteSpace: 'nowrap',
      transition: 'background .2s',
      '&:hover': {
        background: 'var(--light-blue-grey-two)',
      },
    },
  }),
);

type Props = {
  isDisabled?: boolean;
};

export default function ThreeDotsVideoMenu({ isDisabled = false }: Props) {
  const { isProcessorOn, addBlur, removeBlur } = useVideoContext();
  const [isOpened, setIsOpened] = useState(false);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const classes = styles();

  const toggleOpened = () => setIsOpened((prev) => !prev);
  const closeMenu = () => setIsOpened(false);

  useOutsideClick(closeMenu, menuRef);

  return (
    <div className={clsx(classes.container, { isDisabled })}>
      <div className={clsx(classes.threeDots, { isOpened })} onClick={toggleOpened} ref={menuRef}>
        <span className={classes.dot} />
        <span className={classes.dot} />
        <span className={classes.dot} />
      </div>
      {isOpened && (
        <div className={classes.menu}>
          {isProcessorOn ? (
            <div className={classes.menuItem} onClick={removeBlur}>
              {t('bottom-bar:remove-blur-background')}
            </div>
          ) : (
            <div className={classes.menuItem} onClick={addBlur}>
              {t('bottom-bar:blur-background')}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
