import React, { useState, useCallback, useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import VideoBoxesContainer from 'components/VideoBoxesContainer/VideoBoxesContainer';
import NetworkSpeedTest from 'components/NetworkSpeedTest/NetworkSpeedTest';
import InstructionVideo from 'components/InstructionVideo/InstructionVideo';

import GameplayNavbar from 'components/Navbar/components/GameplayNavbar/GameplayNavbar';
import SessionNameNavbar from 'components/Navbar/components/SessionNameNavbar/SessionNameNavbar';

import GameplayContent from './GameplayContent';
import { GlobalContext } from './GameplayProvider';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '100%',
    },
    contentWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
  }),
);

export default function GameplayPage() {
  const context = useContext(GlobalContext);
  const { isSessionHosted } = context;
  const { disableNetworkSpeedTest } = useFlags();
  const [showInstructions, setShowInstructions] = useState(!isSessionHosted);
  const classes = useStyles({ showSidebar: !isSessionHosted });
  const displayName = context.contentfulData?.displayName;

  const hideInstructions = useCallback(() => {
    setShowInstructions(false);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        {!disableNetworkSpeedTest && <NetworkSpeedTest />}
        {showInstructions && !isSessionHosted ? (
          <InstructionVideo hideInstructions={hideInstructions} />
        ) : (
          <>
            {!isSessionHosted && <VideoBoxesContainer />}
            <GameplayContent />
          </>
        )}
      </div>
      {showInstructions ? (
        <SessionNameNavbar displayName={displayName} />
      ) : (
        <GameplayNavbar gameStart={!!context.gameDef.gameData.teamName} />
      )}
    </div>
  );
}
