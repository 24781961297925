import React from 'react';
import AvatarIcon from '@material-ui/icons/Person';
import { makeStyles, Theme } from '@material-ui/core';
import LocalAudioLevelIndicator from 'components/MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from 'components/VideoTrack/VideoTrack';
import { useVideoContext } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
    background: 'black',
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identity: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    padding: '7px 10px 9px',
    width: '100%',
    height: '30px',
    color: 'white',
    textShadow: '0px 0px 1.125px #000000',
    background: 'linear-gradient(360deg, #121212 -27.27%, rgba(18, 18, 18, 0) 100%)',
    '&:hover': {
      background: 'linear-gradient(360deg, #121212 6.82%, rgba(18, 18, 18, 0) 161.36%)',
    },
  },
  indicatorWrapper: {
    marginRight: '15px',
  },
  identityText: {
    flexGrow: 1,
    width: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    lineHeight: '22px',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    width: '100%',
  },
}));

export default function LocalVideoPreview({
  identity,
  withIndicator = true,
}: {
  identity: string;
  withIndicator?: boolean;
}) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;

  return (
    <div className={classes.container} data-testid="local-video-preview-container">
      <div className={classes.innerContainer} data-testid="local-video-preview-inner-container">
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer} data-testid="local-video-preview-avatar-container">
            <AvatarIcon />
          </div>
        )}
      </div>

      <div className={classes.identity} data-testid="local-video-preview-identity">
        {withIndicator && (
          <div className={classes.indicatorWrapper} data-testid="local-video-preview-indicator-wrapper">
            <LocalAudioLevelIndicator />
          </div>
        )}
        <span className={classes.identityText} data-testid="local-video-preview-identity-text">
          {identity}
        </span>
      </div>
    </div>
  );
}
