import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { useAppState } from 'state';
import { LeftArrow, RightArrow } from './Arrows';

import { useParticipants, useVideoContext } from 'hooks';

import { PARTICIPANT_STATUSES } from '_constants';

import { isLSAdmin, isModerator } from 'utils';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { GAME_STATES, instanceOfCEOVideo, Players } from 'containers/GameplayPage/services/types';
import Participant from 'components/Participant/Participant';

import ArrowButton from 'assets/images/arrowButton.svg';

import 'react-horizontal-scrolling-menu/dist/styles.css';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      zIndex: 2,
      backgroundColor: 'var(--black)',
      borderBottom: '2px solid #A2ACB3',
      transition: 'transform .5s',
      '&.isParticipantsHidden': {
        transition: 'transform .2s',
      },
      '&.isVideoStepForModerator': {
        transition: 'transform .2s',
      },
      '&.isVideoStep': {
        transform: 'translateY(-20px)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '@media (max-height: 600px)': {
        display: 'none',
      },
    },
    arrowButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '-15px',
      left: 'calc(50% - 12px)',
      zIndex: 2,
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      background: '#A2ACB3',
      cursor: 'pointer',
      transition: 'transform .3s',
      '&.isParticipantsHidden': {
        transform: 'rotateX(180deg)',
      },
      '&.isVideoStepForModerator': {
        transform: 'rotateX(180deg)',
      },
      '& img': {
        width: '12px',
      },
    },
    carouselWrapper: {
      maxHeight: theme.videoBarMaxHeight,
      transition: 'max-height .3s',
      overflow: 'hidden',
      '&.isParticipantsHidden': {
        maxHeight: '1px',
      },
      '&.isVideoStepForModerator': {
        maxHeight: '1px',
      },
      '&.isVideoStep': {
        maxHeight: 0,
      },
      '@media (max-height: 750px)': {
        maxHeight: theme.videoBarMaxHeightMedium,
      },
      '@media (max-height: 650px)': {
        maxHeight: theme.videoBarMaxHeightSmall,
      },
    },
    separator: {
      margin: '0 6px',
      width: 1,
      height: 96,
      background: 'var(--grey)',
      '@media (max-height: 750px)': {
        height: 75,
      },
      '@media (max-height: 650px)': {
        height: 60,
      },
    },
  }),
);

const isUserLSAdmin = (participant: LocalParticipant | RemoteParticipant, players: Players): boolean => {
  return isLSAdmin(players[participant.identity]?.groups || []);
};

const isUserModerator = (participant: LocalParticipant | RemoteParticipant, players: Players): boolean => {
  return isModerator(players[participant.identity]?.groups || []);
};

const sortParticipants = (
  gameState: string,
  localParticipant: LocalParticipant,
  participants: RemoteParticipant[],
  players: Players,
) => {
  const isCurrentUserLSAdmin = isUserLSAdmin(localParticipant, players);
  const isCurrentUserModerator = isUserModerator(localParticipant, players);

  const LSAdmins: (LocalParticipant | RemoteParticipant)[] = isCurrentUserLSAdmin ? [localParticipant] : [];
  const moderators: (LocalParticipant | RemoteParticipant)[] = isCurrentUserModerator ? [localParticipant] : [];
  const otherPlayers: (LocalParticipant | RemoteParticipant)[] =
    !isCurrentUserLSAdmin && !isCurrentUserModerator ? [localParticipant] : [];

  participants.forEach((participant) => {
    if (gameState === GAME_STATES.INTRO && players[participant.identity]?.status !== PARTICIPANT_STATUSES.READY) {
      return;
    }

    if (isUserLSAdmin(participant, players)) {
      return LSAdmins.push(participant);
    }

    if (isUserModerator(participant, players)) {
      return moderators.push(participant);
    }

    otherPlayers.push(participant);
  });

  return [...LSAdmins, ...moderators, ...otherPlayers];
};

function VideoBoxesContainer() {
  const context = useContext(GlobalContext);
  const classes = styles();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const players = context.gameDef.players || {};
  const gameState = context.gameDef.activeStep.gameState;
  const { isCurrentUserModerator } = useAppState();
  const [indexOfSeparator, setIndexOfSeparator] = useState<number>(-1);
  const [isParticipantsHidden, setIsParticipantsHidden] = useState(false);
  const [isVideoStepForModerator, setIsVideoStepForModerator] = useState(false);
  const [hideVideoBoxes, setHideVideoBoxes] = useState(false);
  const allParticipants = sortParticipants(gameState, localParticipant, participants, players);

  const isVideoStep = useMemo(() => {
    const { loops } = context.contentfulData;
    const { gameState, loop: gameLoop, step: gameStep } = context.gameDef.activeStep;
    const currentStep = loops[gameLoop]?.[gameStep];

    const isTeamNamePicker = gameState === GAME_STATES.PLAYING && !context.gameDef.gameData.startTime;
    const isStartButtonStep = gameState === GAME_STATES.INTRO && gameStep === 0;

    const previousStep = loops[gameLoop]?.[gameStep - 1];
    const isIntroVideoStep =
      gameState === GAME_STATES.INTRO &&
      previousStep &&
      instanceOfCEOVideo(typeof previousStep === 'string' ? JSON.parse(previousStep) : previousStep);

    const isFinalResults = gameState === GAME_STATES.DONE;

    return (
      !isFinalResults &&
      !isTeamNamePicker &&
      !isStartButtonStep &&
      (instanceOfCEOVideo(typeof currentStep === 'string' ? JSON.parse(currentStep) : currentStep) || isIntroVideoStep)
    );
  }, [context.contentfulData, context.gameDef.activeStep]);

  useEffect(() => {
    if (isCurrentUserModerator) {
      setIsVideoStepForModerator(isVideoStep);
      return;
    }

    if ((isVideoStep && !hideVideoBoxes) || (!isVideoStep && hideVideoBoxes)) {
      setTimeout(() => {
        setHideVideoBoxes((prev) => !prev); // it's needed to unmount video boxes after container's collapsing animation
      }, 500);
    }
  }, [isVideoStep, hideVideoBoxes]);

  const showVideoBoxes = useMemo(() => {
    return (isVideoStep && !hideVideoBoxes) || !isVideoStep;
  }, [isVideoStep, hideVideoBoxes]);

  const calculateDivider = () => {
    const allParticipants = sortParticipants(gameState, localParticipant, participants, players);
    const indexOfFirstOtherParticipant = allParticipants.findIndex(
      (participant: LocalParticipant | RemoteParticipant) => {
        return !isUserLSAdmin(participant, players) && !isUserModerator(participant, players);
      },
    );
    setIndexOfSeparator(indexOfFirstOtherParticipant);
  };

  const toggleIsParticipantsHidden = () => {
    if (isVideoStep && isCurrentUserModerator && isVideoStepForModerator) {
      setIsVideoStepForModerator(false);
    }

    setIsParticipantsHidden((prev) => !prev);
  };

  useEffect(() => {
    calculateDivider();
  }, [participants, players, gameState]);

  const getIsLeader = useCallback(
    (identity) => {
      return players[identity] && players[identity].email === context.currentLeader;
    },
    [players],
  );

  return (
    <div
      className={clsx(classes.container, {
        isParticipantsHidden,
        isVideoStepForModerator,
        isVideoStep: isVideoStep && !isCurrentUserModerator,
      })}
      data-testid="video-boxes-container"
    >
      <div
        onClick={toggleIsParticipantsHidden}
        className={clsx(classes.arrowButton, { isParticipantsHidden, isVideoStepForModerator })}
        data-testid="video-boxes-container-toggle"
      >
        <img src={ArrowButton} alt="arrow-button" />
      </div>
      <div
        className={clsx(classes.carouselWrapper, {
          isParticipantsHidden,
          isVideoStepForModerator,
          isVideoStep: isVideoStep && !isCurrentUserModerator,
        })}
        data-testid="video-boxes-container-carousel-wrapper"
      >
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {showVideoBoxes ? (
            allParticipants.map((participant, index) => {
              return (
                <div style={{ display: 'flex', width: '160px', alignItems: 'flex-end' }} key={index}>
                  {index !== 0 && index === indexOfSeparator && <div className={classes.separator} />}
                  <div style={{ minWidth: '140px', margin: '0 3px', display: 'inline-block' }}>
                    <Participant
                      key={participant.sid}
                      participant={participant}
                      isLeader={getIsLeader(participant.identity)}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div />
          )}
        </ScrollMenu>
      </div>
    </div>
  );
}

export default React.memo(VideoBoxesContainer);
