import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core';
import { unionBy, differenceBy } from 'lodash';

import { PARTICIPANT_STATUSES } from '_constants';

import { CopyToClipboard } from 'components/CopyToClipboard';

import LinkIcon from 'assets/images/link-icon.svg';

const Container = styled('div')({
  padding: '30px 32px 40px',
  width: '480px',
  background: '#112431',
  borderRadius: '5px 5px 100px 5px',
  border: '1px solid #104563',
  boxShadow: '0px 4px 100px rgb(81 155 215 / 70%)',
});

const StorylineName = styled('div')({
  marginBottom: 13,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#EAEAEA',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: 1.3,
  textTransform: 'uppercase',
});

const SessionCapacity = styled('div')({
  marginBottom: 24,
  paddingBottom: 21,
  borderBottom: '1px solid #4EB3D4',
  color: '#A2ACB3',
  textTransform: 'uppercase',
  fontSize: 12,
  lineHeight: 1.6,
});

const SessionCapacityCount = styled('span')({
  fontFamily: 'AtomicAge',
});

const Info = styled('div')({
  marginBottom: 24,
  color: '#FFF',
  fontSize: 16,
  lineHeight: 1.4,
});

const ParticipantsList = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'start',
  gridColumnGap: 12,
  marginBottom: 24,
  maxHeight: '120px',
  overflowX: 'hidden',
});

const ParticipantsListTitle = styled('div')({
  marginBottom: 8,
  color: '#A2ACB3',
  fontSize: 10,
  lineHeight: 1.6,
  textTransform: 'uppercase',
});

const ParticipantsListInfo = styled('div')({
  padding: '4px 0',
  color: '#FFF',
  fontSize: 12,
  lineHeight: 1.4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const RemindParticipants = styled('div')({
  position: 'relative',
  padding: '16px 24px 12px',
  width: '100%',
  background: '#05131D',
});

const RemindParticipantsTriangle = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 0 20px 20px',
  borderColor: 'transparent transparent #05131D transparent',
});

const RemindParticipantsLinkContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
});

const RemindParticipantsLinkIcon = styled('img')({});

const Link = styled('span')({
  flexGrow: 1,
  marginLeft: 10,
  marginRight: 13,
  width: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  color: '#FFF',
  fontSize: 10,
  fontWeight: 'bold',
  lineHeight: 1.6,
  whiteSpace: 'nowrap',
  letterSpacing: '0.1em',
});

const RemindParticipantsCopy = styled('div')({
  textTransform: 'uppercase',
  color: '#4EB3D4',
  cursor: 'pointer',
  fontSize: 10,
  lineHeight: 1.6,
});

type Participant = {
  email: string;
  status?: string;
};

const getUserEmailText = (email: string) => email.split('@')[0];

export default function WaitingList({
  storylineName,
  link,
  players,
  signedUpParticipants,
  sessionCapacity = 15,
}: {
  storylineName: string;
  link: string;
  players: Array<Participant>;
  signedUpParticipants: Array<Participant>;
  sessionCapacity?: number;
}) {
  const { t } = useTranslation();
  const [storedParticipants, setStoredParticipants] = useState<Array<Participant>>([]);
  const sessionLink = link || window.location.href;

  useEffect(() => {
    const uniqParticipants: Array<Participant> = unionBy(players, signedUpParticipants, 'email');
    const newParticipants: Array<Participant> = differenceBy(uniqParticipants, storedParticipants, 'email');
    const filteredParticipants: Array<Participant> = storedParticipants.reduce(
      (acc: Array<Participant>, storedParticipant: Participant) => {
        const filteredParticipant: Participant | undefined = uniqParticipants.find(
          (uniqParticipant) => uniqParticipant.email === storedParticipant.email,
        );

        return filteredParticipant ? acc.concat(filteredParticipant) : acc;
      },
      [],
    );

    setStoredParticipants(filteredParticipants.concat(newParticipants));
  }, [players, signedUpParticipants]);

  const getUserStatusText = useCallback((status) => {
    switch (status) {
      case PARTICIPANT_STATUSES.WATCHING_INTRO_VIDEO:
        return t('introduction:participant-statuses:watching-intro-video');
      case PARTICIPANT_STATUSES.READY:
        return t('introduction:participant-statuses:ready');
      case PARTICIPANT_STATUSES.HAS_NOT_ARRIVED:
      default:
        return t('introduction:participant-statuses:has-not-arrived');
    }
  }, []);

  return (
    <Container>
      <StorylineName>{storylineName}</StorylineName>
      <SessionCapacity>
        {t('introduction:waiting-list:session-capacity')}:{' '}
        <SessionCapacityCount>{sessionCapacity}</SessionCapacityCount>
      </SessionCapacity>
      <Info>{t('introduction:waiting-list:once-everyones-ready')}</Info>
      <ParticipantsList>
        <ParticipantsListTitle>{t('introduction:waiting-list:participant')}</ParticipantsListTitle>
        <ParticipantsListTitle>{t('introduction:waiting-list:status')}</ParticipantsListTitle>
        {storedParticipants.map(({ email, status }) => (
          <>
            <ParticipantsListInfo key={email}>{getUserEmailText(email)}</ParticipantsListInfo>
            <ParticipantsListInfo key={`${email}_${status}`}>{getUserStatusText(status)}</ParticipantsListInfo>
          </>
        ))}
      </ParticipantsList>
      <RemindParticipants>
        <RemindParticipantsTriangle />
        <RemindParticipantsLinkContainer>
          <RemindParticipantsLinkIcon src={LinkIcon} alt="link-icon" />
          <Link>{sessionLink}</Link>
          <CopyToClipboard text={sessionLink} copyText={t('wait-list:link-copied')}>
            {(onClick) => <RemindParticipantsCopy onClick={onClick}>{t('wait-list:copy-link')}</RemindParticipantsCopy>}
          </CopyToClipboard>
        </RemindParticipantsLinkContainer>
      </RemindParticipants>
    </Container>
  );
}
