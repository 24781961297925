import React, { useContext, createContext, ReactNode, useCallback, useState, useEffect } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
} from 'twilio-video';
import { Callback, ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import { GlobalContext } from '../../containers/GameplayPage/GameplayProvider';
import { isPermissionDenied } from '../../utils';
import GameService from '../../containers/GameplayPage/services/GameService';
import { datadogLogs } from '@datadog/browser-logs';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */
export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  isMicBlocked: boolean;
  isCameraBlocked: boolean;
  removeLocalVideoTrack: () => void;
  isProcessorAvailable: boolean;
  isProcessorOn: boolean;
  addBlur: () => void;
  removeBlur: () => void;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  gameService: GameService;
  children: ReactNode;
}

export function VideoProvider({ options, onError = () => {}, gameService, children }: VideoProviderProps) {
  const [isMicBlocked, setMicBlocked] = useState<boolean>(false);
  const [isCameraBlocked, setCameraBlocked] = useState<boolean>(false);
  const roomDetails = gameService.getRoomDetails();
  const isSessionHosted = roomDetails.session.is_hosted;

  useEffect(() => {
    if (isSessionHosted) {
      isPermissionDenied('microphone').then(setMicBlocked);
      isPermissionDenied('camera').then(setCameraBlocked);
    }
  }, [isSessionHosted]);

  const onErrorCallback: ErrorCallback = useCallback(
    (error: TwilioError) => {
      // https://www.twilio.com/docs/video/reconnection-states-and-events
      if (error.code === 53001) {
        datadogLogs.logger.error(`Reconnecting your signaling connection! ${error.message}`);
      } else if (error.code === 53405) {
        datadogLogs.logger.error(`Reconnecting your media connection! ${error.message}`);
      } else if (error.code === 20104) {
        datadogLogs.logger.error(`Signaling reconnection failed due to expired token ${error.message}`);
        error.message = 'Reconnect failed due to expired token';
        onError(error);
      } else if (error.code === 53000) {
        datadogLogs.logger.error(`Signaling reconnection failed after too many attempts ${error.message}`);
        error.message = 'Reconnection failed after too many attempts';
        onError(error);
      } else if (error.code === 53002) {
        datadogLogs.logger.error(`Signaling reconnection took too long ${error.message}`);
        error.message = 'Reconnection took too long';
        onError(error);
      } else {
        datadogLogs.logger.error(`Unknown Twilio VideoProvider error: ${error.code} - ${error.message}`);
      }
    },
    [onError],
  );

  const globalContext = useContext(GlobalContext);

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isProcessorAvailable,
    isProcessorOn,
    addBlur,
    removeBlur,
  } = useLocalTracks(isSessionHosted);
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    globalContext.onDisconnect,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    false,
    () => {},
  );
  useHandleTrackPublicationFailed(room, onErrorCallback);
  useHandleRoomDisconnectionErrors(room, onErrorCallback);
  useRestartAudioTrackOnDeviceChange(localTracks);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect: globalContext.onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isMicBlocked,
        isCameraBlocked,
        isProcessorAvailable,
        isProcessorOn,
        addBlur,
        removeBlur,
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
