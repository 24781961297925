import moment from 'moment';
import { mergeToFirebase } from '../../services/firestore';
import { apiClient, RoomDetails, SessionStateEnum } from '../../services/api';

export async function createGameDefinition(roomId: string, language: string = 'en-US') {
  mergeToFirebase(
    roomId,
    {
      activeStep: {
        gameState: 'INTRO',
        loop: 0,
        step: 0,
        stepData: {},
      },
      gameData: {
        startTime: null,
        accruedTimeSeconds: 0,
        position: 1,
        penalties: {
          QUESTION: 0,
          PUZZLE: 0,
        },
        questionsStatistics: [],
      },
      language,
    },
    () => {},
    /* This flag says don't call firebase transactions on initial app loading
     ** TODO: remove it after refactor or transactions logic change
     ** TODO: also we don't call `callback` prop in `firestoreRequest`
     ** so we need to remove it in all functions which pass it
     */
    true,
  );
}

export function fetchContentfulData(
  client: any,
  contentfulId: string,
  language: string,
  callback: (data: any) => void,
) {
  client
    .getEntry(contentfulId, {
      include: 10,
      locale: language,
    })
    .then(({ fields: contentFulData }: any) => {
      const introModalSteps =
        contentFulData.introModalSteps?.map((step: any) => {
          return step.fields.content || step.fields;
        }) || [];

      const loops = {} as Record<string, any>;
      contentFulData.loops.forEach((loop: any, index: any) => {
        loops[index] = loop.fields.modules.map((module: any) => {
          if (module.fields?.puzzleType) {
            return JSON.stringify(module.fields);
          }
          return {
            ...module.fields,
            id: module.sys.id,
          };
        });
      });

      const outroModalSteps =
        contentFulData.outroModalSteps?.map((step: any) => {
          return step.fields.content || step.fields;
        }) || [];

      const outroModalStepsWin =
        contentFulData.outroModalStepsWin?.map((step: any) => {
          return step.fields.content || step.fields;
        }) || [];

      const puzzleResource = contentFulData.puzzleResource
        ? {
            ...contentFulData.puzzleResource.fields,
          }
        : {};

      const instructions = contentFulData.instructions?.map((instructions: any) => instructions.fields) || [];

      callback({
        ...contentFulData,
        introModalSteps,
        loops,
        outroModalSteps,
        outroModalStepsWin,
        puzzleResource,
        instructions,
      });
    })
    .catch(console.error);
}

export function isLeader(players: Record<string, any>, identity = '') {
  return !!(players[identity] && players[identity].isLeader);
}

export function fetchRoomDetails(roomId: string): Promise<RoomDetails | null> {
  return apiClient
    .getRoomDetails(roomId)
    .then((response) => {
      if (response?.session) {
        return response;
      } else {
        Promise.reject(new Error('Session does not exist'));
        return null;
      }
    })
    .catch((error) => {
      // Likely a 404 error
      console.error(error);
      const splitPathname = window.location.pathname.split('/');
      const isGameplayRoute = splitPathname[splitPathname.length - 1] === 'play';
      if (!window.navigator.onLine && isGameplayRoute) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return null;
      }
      if (process.env.NODE_ENV !== 'test') {
        window.location.replace('/');
      }
      return null;
    });
}

export function checkRoomAvailability<Boolean>(roomDetails: RoomDetails) {
  return !gameIsOverOrCancelled(roomDetails) && !gameIsFull(roomDetails);
}

// Check if the game state is cancelled or ended, or if the start & end have passed
export function gameIsOverOrCancelled(roomDetails: RoomDetails) {
  const gameStartMoment = moment(roomDetails?.sessionStart);
  const gameEndMoment = moment(roomDetails?.sessionStart).add(2, 'h');

  return (
    roomDetails?.sessionState === SessionStateEnum.CANCELED ||
    roomDetails?.sessionState === SessionStateEnum.ENDED ||
    // Check if the start & end time has already passed
    (roomDetails?.sessionState === SessionStateEnum.SCHEDULED &&
      gameStartMoment.isBefore(moment()) &&
      gameEndMoment.isBefore(moment()))
  );
}

// Check if the game is full either from Twilio (roomFull) or by maxParticipant count
export function gameIsFull(roomDetails: RoomDetails) {
  const room = roomDetails?.room;
  // @ts-ignore
  return !!room && (room?.roomFull || room.maxParticipantsCount <= room.connectedParticipantsCount);
}
