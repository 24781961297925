import { useReducer, Reducer } from 'react';

type ReducerType = Reducer<boolean, any>;
type ReducerReturnType = [boolean, (nextValue?: any) => void];

const toggleReducer = (state: boolean, nextValue?: any) => (typeof nextValue === 'boolean' ? nextValue : !state);

export default function useToggle(initialValue: boolean) {
  return useReducer<ReducerType>(toggleReducer, initialValue) as ReducerReturnType;
}
