export enum ErrorCodesEnum {
  INVALID_TOKEN = 20101,
  BLOCKED_MIC_OR_CAMERA = 0,
  FAILED_FIREBASE_TOKEN = 600613,
  FAILED_FIREBASE_AUTH = 600614,
  FAILED_TO_FETCH_ROOM_DETAILS = 123,
}

// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export function enhanceMessage(message = '', t: Function, code?: number) {
  switch (code) {
    case ErrorCodesEnum.INVALID_TOKEN: // Invalid token error
      return `${message}. ${t('warnings:invalid-token-error')}.`;
    case ErrorCodesEnum.BLOCKED_MIC_OR_CAMERA: // User has microphone or camera blocked in browser
      return `${message}. ${t('warnings:media-access-error')}.`;
    case ErrorCodesEnum.FAILED_FIREBASE_TOKEN: // Failed to fetch Firebase Token (browser error)
      return `${message}. ${t('warnings:firebase-token-error')}.`;
    case ErrorCodesEnum.FAILED_FIREBASE_AUTH: // Failed to authenticate to Firebase (internal error)
      return `${message}`;
    case ErrorCodesEnum.FAILED_TO_FETCH_ROOM_DETAILS: // Failed to fetch room details
      return `${message}`;
    default:
      return message;
  }
}

export function errorLink(code?: number) {
  switch (code) {
    case ErrorCodesEnum.INVALID_TOKEN: // Invalid token error
      return null;
    case ErrorCodesEnum.BLOCKED_MIC_OR_CAMERA: // User has microphone or camera blocked in browser
      return 'https://support.livingsecurity.com/hc/en-us/articles/360055741614-How-do-I-enable-my-microphone-and-webcam-for-the-experience-';
    case ErrorCodesEnum.FAILED_FIREBASE_TOKEN: // Failed to fetch Firebase Token (browser error)
      return 'https://support.livingsecurity.com/hc/en-us/articles/1500004961401-Compatibility-Testing-for-Teams-CyberEscape-Online';
    case ErrorCodesEnum.FAILED_FIREBASE_AUTH: // Failed to authenticate to Firebase (internal error)
      return null;
    default:
      return null;
  }
}
