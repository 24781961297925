import React, { PropsWithChildren, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { enhanceMessage, errorLink } from './enhanceMessage';
import { Dialog } from 'components/Dialog';
import { useTranslation } from 'react-i18next';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { t } = useTranslation();
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, t, code);
  const supportLink = errorLink(code);
  const [text, setText] = useState('');

  return (
    <Dialog
      open={error !== null}
      title={t('warnings:error')}
      onEnter={() => setText(enhancedMessage)}
      onExited={() => setText('')}
      onClose={() => dismissError()}
    >
      <div style={{ margin: '20px 0' }}>{`${text}`}</div>
      {supportLink && (
        <div style={{ margin: '20px 0' }}>
          {`${t('warnings:or-documentation')}`}{' '}
          <a href={supportLink} target="_blank" rel="noopener noreferrer">
            {t('modals:help-documentation')}
          </a>
          .
        </div>
      )}
      <div style={{ margin: '20px 0' }}>{`${t('warnings:help-widget')}.`}</div>
    </Dialog>
  );
}

export default ErrorDialog;
