import React from 'react';

export default function PuzzleErrorComponent(props: any) {
  return (
    <div
      onClick={props.reloadCallback}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Something went wrong. Click to reload.
    </div>
  );
}
