import React, { useMemo, useState, useEffect } from 'react';
import ConnectionStatusToast from './ConnectionStatusToast/ConnectionStatusToast';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core';
import ConnectionYellowIcon from 'assets/images/connection-yellow-icon.svg';
import ConnectionRedIcon from 'assets/images/connection-red-icon.svg';
import InfoIcon from 'assets/images/info-icon.svg';
import { ConnectionProblem } from 'containers/GameplayPage/services/types';
import { useConnectionStatus, usePreloadImages } from 'hooks';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from 'hooks/useConnectionStatus/types';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';

const networkTroubleshootingLink = 'https://www.livingsecurity.com/support/troubleshooting-teams-internet-speed';

const getColor = (type: ConnectionNotificationTypeEnum) => {
  switch (type) {
    case ConnectionNotificationTypeEnum.WARNING:
      return '#F7D77F';
    case ConnectionNotificationTypeEnum.FAIL:
      return '#F16153';
    case ConnectionNotificationTypeEnum.INFO:
      return '#85E5FF';
  }
};

const getIcon = (type: ConnectionNotificationTypeEnum) => {
  switch (type) {
    case ConnectionNotificationTypeEnum.WARNING:
      return ConnectionYellowIcon;
    case ConnectionNotificationTypeEnum.FAIL:
      return ConnectionRedIcon;
    case ConnectionNotificationTypeEnum.INFO:
      return InfoIcon;
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    snackbar: () => ({
      position: 'absolute',
      top: '0.4em',
      right: 0,
    }),
    simple: ({ notificationType }: { notificationType: ConnectionNotificationTypeEnum }) => ({
      backgroundColor: 'var(--black)',
      border: `2px solid ${getColor(notificationType)}`,
      width: '48px',
      height: '48px',
      position: 'absolute',
      top: '4em',
      right: '1em',
      zIndex: 10,
    }),
    simpleIcon: {
      width: '22px',
      height: '18px',
    },
  }),
);

const ConnectionStatusWidget = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation();
  const { connectionProblems } = useConnectionStatus();
  usePreloadImages([ConnectionYellowIcon, ConnectionRedIcon, InfoIcon]);

  const notificationType = useMemo(() => {
    if (!connectionProblems.length) return ConnectionNotificationTypeEnum.WARNING;
    const priority = {
      [ConnectionNotificationTypeEnum.FAIL]: 3,
      [ConnectionNotificationTypeEnum.WARNING]: 2,
      [ConnectionNotificationTypeEnum.INFO]: 1,
    };
    let type = ConnectionNotificationTypeEnum.INFO;
    for (let i = 0; i < connectionProblems.length && type !== ConnectionNotificationTypeEnum.FAIL; i++) {
      const currentType = connectionProblems[i].notificationType;
      if (!type || priority[currentType] > priority[type]) {
        type = currentType;
      }
    }
    return type;
  }, [connectionProblems]);

  const classes = useStyles({ notificationType });

  const getNotificationText = (problem: ConnectionProblem) => {
    switch (problem.problemType) {
      case ConnectionProblemTypeEnum.NETWORK:
        switch (problem.notificationType) {
          case ConnectionNotificationTypeEnum.WARNING:
            return {
              title: t('connection-toast:warning-title'),
              subtitle: (
                <>
                  {`${t('connection-toast:warning-subtitle')} `}
                  <a href={networkTroubleshootingLink} target="_blank" rel="nofollow noopener noreferrer">
                    {t('connection-toast:warning-link')}
                  </a>
                </>
              ),
            };
          case ConnectionNotificationTypeEnum.FAIL:
            return {
              title: t('connection-toast:fail-title'),
              subtitle: t('connection-toast:fail-subtitle'),
            };
          case ConnectionNotificationTypeEnum.INFO:
            return {
              title: t('connection-toast:info-title'),
              subtitle: t('connection-toast:info-subtitle'),
            };
          default:
            return {
              title: t('connection-toast:warning-title'),
              subtitle: (
                <>
                  {`${t('connection-toast:warning-subtitle')} `}
                  <a href={networkTroubleshootingLink} target="_blank" rel="nofollow noopener noreferrer">
                    {t('connection-toast:warning-link')}
                  </a>
                </>
              ),
            };
        }
      case ConnectionProblemTypeEnum.TWILIO:
        switch (problem.notificationType) {
          case ConnectionNotificationTypeEnum.FAIL:
            return {
              title: t('connection-toast:fail-title'),
              subtitle: t('connection-toast:fail-subtitle'),
            };
          case ConnectionNotificationTypeEnum.INFO:
            return {
              title: t('connection-toast:twilio-info-title'),
              subtitle: t('connection-toast:twilio-info-subtitle'),
            };
          default:
            return {
              title: t('connection-toast:fail-title'),
              subtitle: t('connection-toast:fail-subtitle'),
            };
        }
      default:
        return {
          title: t('connection-toast:warning-title'),
          subtitle: (
            <>
              {`${t('connection-toast:warning-subtitle')} `}
              <a href={networkTroubleshootingLink} target="_blank" rel="nofollow noopener noreferrer">
                {t('connection-toast:warning-link')}
              </a>
            </>
          ),
        };
    }
  };

  const toggleOpen = () => {
    setIsOpen((state) => !state);
  };

  const problems = useMemo(() => {
    const failNotification = connectionProblems.find(
      (problem) => problem.notificationType === ConnectionNotificationTypeEnum.FAIL,
    );
    if (failNotification) return [failNotification];
    return connectionProblems;
  }, [connectionProblems]);

  useEffect(() => {
    if (
      !isOpen &&
      connectionProblems.some((problem) => problem.notificationType === ConnectionNotificationTypeEnum.FAIL)
    ) {
      setIsOpen(true);
    }
  }, [isOpen, connectionProblems]);

  if (!isOpen && connectionProblems.length) {
    return (
      <IconButton className={classes.simple} color="inherit" onClick={toggleOpen}>
        <img src={getIcon(notificationType)} alt="connection icon" className={classes.simpleIcon} />
      </IconButton>
    );
  }

  if (!problems.length) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open
      classes={{ root: classes.snackbar }}
    >
      <div>
        {problems.map((problem) => (
          <ConnectionStatusToast
            key={problem.notificationType + problem.problemType}
            type={problem.notificationType}
            onToggle={toggleOpen}
            text={getNotificationText(problem)}
            color={getColor(problem.notificationType)}
            icon={getIcon(problem.notificationType)}
          />
        ))}
      </div>
    </Snackbar>
  );
};
export default ConnectionStatusWidget;
