import { useCallback, useContext } from 'react';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { ConnectionProblem } from 'containers/GameplayPage/services/types';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from './types';

export default function useConnectionStatus() {
  const { connectionProblems, setConnectionProblems } = useContext(GlobalContext);

  const removeConnectionStatusNotification = useCallback(
    (type: ConnectionProblemTypeEnum) => {
      setConnectionProblems((prev: ConnectionProblem[]) => {
        const notificationWithGivenTypeIsSaved = prev.some((problem) => problem.problemType === type);

        return notificationWithGivenTypeIsSaved ? prev.filter((problem) => problem.problemType !== type) : prev;
      });
    },
    [connectionProblems],
  );

  const addConnectionStatusNotification = useCallback(
    (problemType: ConnectionProblemTypeEnum, notificationType: ConnectionNotificationTypeEnum, closeAfter?: number) => {
      setConnectionProblems((prev: ConnectionProblem[]) => {
        const previousProblem = prev.find((problem) => problem.problemType === problemType);
        if (previousProblem) {
          if (previousProblem.notificationType === notificationType) return prev;
          return prev.map((savedProblem) => {
            return savedProblem.problemType === problemType
              ? {
                  problemType,
                  notificationType,
                }
              : savedProblem;
          });
        }
        return prev.concat({
          problemType,
          notificationType,
        });
      });
      if (closeAfter) {
        setTimeout(() => {
          removeConnectionStatusNotification(problemType);
        }, closeAfter);
      }
    },
    [setConnectionProblems, removeConnectionStatusNotification],
  );

  return {
    addConnectionStatusNotification,
    removeConnectionStatusNotification,
    connectionProblems,
  };
}
