import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useOutsideClick } from 'hooks';

const styles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      zIndex: 2,
      top: 2,
      // left: 'calc(100% - 19px)',
      right: '3px',
    },
    threeDots: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      width: 16,
      height: 16,
      background: 'var(--black)',
      borderRadius: '50%',
      cursor: 'pointer',
      '&.isOpened': {
        background: '#85E5FF',
      },
    },
    dot: {
      width: 2,
      height: 2,
      background: 'var(--white)',
      borderRadius: '50%',
      '&:nth-child(2)': {
        margin: '1.5px 0',
      },
    },
    menu: {
      marginTop: 2,
      minWidth: 192,
      background: 'var(--black)',
      boxShadow: '0px 2px 12px rgba(78, 179, 212, 0.4)',
      borderRadius: 4,
      overflow: 'hidden',
    },
    menuItem: {
      padding: '4px 16px 4px',
      color: 'var(--white)',
      cursor: 'pointer',
      fontSize: 13,
      lineHeight: '24px',
      '&:hover': {
        background: 'var(--dark-grey-two)',
      },
      '&:not(:first-child)': {
        borderTop: '1px solid rgba(162, 172, 179, .5)',
      },
      '&.disabled': {
        pointerEvents: 'none',
        opacity: '.3',
      },
    },
  }),
);

type Props = {
  setLeaderHandler: () => void;
  isSetLeaderDisabled: boolean;
  muteAudioHandler: () => void;
  isMuteAudioDisabled: boolean;
};

export default function ThreeDotsParticipantMenu({
  setLeaderHandler,
  muteAudioHandler,
  isSetLeaderDisabled,
  isMuteAudioDisabled,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const classes = styles();

  const toggleOpened = () => setIsOpened((prev) => !prev);
  const closeMenu = () => setIsOpened(false);

  useOutsideClick(closeMenu, menuRef);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.threeDots, { isOpened })} onClick={toggleOpened} ref={menuRef}>
        <span className={classes.dot} />
        <span className={classes.dot} />
        <span className={classes.dot} />
      </div>
      {isOpened && (
        <div className={classes.menu}>
          <div className={clsx(classes.menuItem, { disabled: isSetLeaderDisabled })} onClick={setLeaderHandler}>
            {t('gameplay:video-box:set-a-leader')}
          </div>
          <div className={clsx(classes.menuItem, { disabled: isMuteAudioDisabled })} onClick={muteAudioHandler}>
            {t('gameplay:video-box:mute-audio')}
          </div>
        </div>
      )}
    </div>
  );
}
