import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import NavbarButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/NavbarButton';

import FullscreenIcon from 'assets/images/navbar/fullscreen.svg';
import ExitFullscreenIcon from 'assets/images/navbar/exit-fullscreen.svg';

const useStyles = makeStyles({
  button: {
    width: '111px',
  },
});

export default function FullscreenButton({
  isFullScreen,
  toggleFullScreen,
}: {
  isFullScreen: Boolean;
  toggleFullScreen: () => void;
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <NavbarButton
      icon={isFullScreen ? ExitFullscreenIcon : FullscreenIcon}
      onClick={toggleFullScreen}
      alt="Toggle fullscreen mode"
      className={classes.button}
      testId="toggle-full-screen-button"
    >
      {`${isFullScreen ? `${t('bottom-bar:exit')} ` : ''}${t('bottom-bar:full-screen')}`}
    </NavbarButton>
  );
}
