import { useEffect } from 'react';

export default function usePreloadImages(images: string[]) {
  useEffect(() => {
    images.forEach((image) => {
      const newImage = new Image();
      newImage.onerror = () => {
        console.warn('Error on preload image ', image);
      };
      newImage.src = image;
      // @ts-ignore
      window.ReactPreloadedPictures = (window.ReactPreloadedPictures || []).concat(newImage);
    });
  }, []);
}
