import { useEffect, useState, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// @ts-nocheck
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import { Settings } from '@firebase/firestore-types';
import getFirebaseConfig from '../../firebase-config';
import { REGIONS } from '_constants';
import { Region } from 'types';
import { RoomDetails } from 'services/api';

export const DB_KEY = process.env.REACT_APP_FIREBASE_LS_KEY;
export const GAME_DEF_COLLECTION = 'game-definitions';
export const PUZZLE_DB = 'puzzles/';
export const MOUSE_ROUTE = 'mouse';
export const STATE_ROUTE = 'state';
const INIT_STATE = {
  MISSING: 'MISSING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

type useFirebaseConnectionProps = {
  newUser: any;
  launchDarklyError: boolean;
  callback: Function;
  roomDetails: RoomDetails;
  setFirebaseConfig: Function;
};

export default function useFirebaseConnection({
  newUser,
  launchDarklyError,
  callback,
  roomDetails,
  setFirebaseConfig,
}: useFirebaseConnectionProps) {
  const { firebaseForceLongPolling, firebaseForceExperimentalFallback } = useFlags();
  const [initState, setInitState] = useState<string>(INIT_STATE.MISSING);
  const [firebaseSettings, setFirebaseSettings] = useState<Settings | undefined>();

  const getFirebaseSettings = useCallback(() => {
    return new Promise<Settings>((resolve) => {
      const settings: Settings = {
        ignoreUndefinedProperties: true,
        experimentalForceLongPolling: false,
        experimentalAutoDetectLongPolling: false,
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      };

      if (window) {
        const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;

        if (firebaseForceLongPolling || !supportsWebSockets) {
          settings.experimentalForceLongPolling = firebaseForceLongPolling;
        } else if (firebaseForceExperimentalFallback) {
          settings.experimentalAutoDetectLongPolling = firebaseForceExperimentalFallback;
        }

        console.log(`Setting to Firebase connection to: ${JSON.stringify(settings)}`);

        return resolve(settings);
      }
    });
  }, [firebaseForceLongPolling, firebaseForceExperimentalFallback]);

  const initializeFirebase = useCallback(
    async (ldBlocked: boolean, region: Region) => {
      getFirebaseSettings().then((settings: Settings) => {
        setFirebaseSettings(settings);

        if (!firebase.apps.length) {
          const config = getFirebaseConfig(region);

          firebase.initializeApp(config);
          firebase.firestore().settings(settings);
          firebase.firestore();

          setFirebaseConfig(config);
        }

        if (ldBlocked) {
          console.error('No LaunchDarkly connection, using default connection settings', settings);
        } else {
          if (newUser?.custom.isSessionHosted) {
            // @ts-ignore
            window.offlineMode = true;
            firebase.firestore().disableNetwork();
          }
        }
      });
    },
    [getFirebaseSettings, newUser?.custom.isSessionHosted],
  );

  useEffect(() => {
    if (
      !firebaseSettings &&
      initState === INIT_STATE.MISSING &&
      ((newUser && !newUser.anonymous) || (!newUser && launchDarklyError)) &&
      roomDetails
    ) {
      const region = roomDetails?.session?.region || REGIONS.US;

      setInitState(INIT_STATE.IN_PROGRESS);
      initializeFirebase(launchDarklyError, region).then(() => setInitState(INIT_STATE.COMPLETED));
      if (callback) callback(region);
    }
  }, [newUser, firebaseSettings, initializeFirebase, launchDarklyError, roomDetails]);

  return firebaseSettings;
}
