import isPlainObject from 'is-plain-object';
import CryptoJS from 'crypto-js';

import { parseJWT } from 'services/token';

import { GROUPS, VIDEO_BLUR_KEY } from '_constants';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions(constraints: MediaStreamConstraints = { audio: true, video: true }) {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia(constraints)
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
      }
    });
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

// curry function, that accepts callback and return error callback
export function checkBlockedPermissions(onError?: (e: DOMException) => void) {
  return (e: any) => {
    if (e instanceof DOMException && e.name === 'NotAllowedError' && e.message.includes('denied')) {
      onError && onError(e);
    }
  };
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

// Check if their screen resolution is a retina display
export function isRetina(): boolean {
  return (
    window.matchMedia &&
    (window.matchMedia(
      'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
    ).matches ||
      window.matchMedia(
        'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
      ).matches)
  );
}

export function getURLParamsAsObject() {
  return Object.fromEntries(new URLSearchParams(window.location.search).entries());
}

export function removeURLParamByName(paramName: string) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search.slice(1));
  params.delete(paramName);
  window.history.replaceState({}, '', `${window.location.pathname}?${params}${window.location.hash}`);
}

export function getRoomIdFromPathname(pathname?: string): string {
  // string between "/" and "/" or end of input
  return (pathname || window.location.pathname).match(/(?<=\/)(.*?)(?=\/|$)/)?.[0] || '';
}

export function isJSON(item: any): boolean {
  if (typeof item !== 'string') return false;
  let newItem;
  try {
    newItem = JSON.parse(item);
  } catch (e) {
    return false;
  }
  return typeof newItem === 'object' && !Array.isArray(newItem) && newItem !== null;
}

export function getFieldFromAuthJWT(token: string, field: string) {
  const parsedJWT = parseJWT(token);

  return parsedJWT[field];
}

export function encryptAES(value: string, key: number): string {
  const passphrase = CryptoJS.lib.WordArray.create([parseInt(key + '', 32)]);
  return CryptoJS.AES.encrypt(value, passphrase, { mode: CryptoJS.mode.ECB }).toString();
}

export function decryptAES(value: string, key: number): string {
  try {
    const passphrase = CryptoJS.lib.WordArray.create([parseInt(key + '', 32)]);
    return CryptoJS.AES.decrypt(value, passphrase, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
  } catch {
    return '';
  }
}

enum MediaStorageKeysEnum {
  video = 'Teams-selectedVideoPreference',
  audio = 'Teams-selectedAudioPreference',
}

export function setVideoPreference(roomSessionId: number | null, value: string) {
  return window.sessionStorage.setItem(`${MediaStorageKeysEnum.video}_${roomSessionId}`, value);
}

export function getVideoPreference(roomSessionId: number | null) {
  return window.sessionStorage.getItem(`${MediaStorageKeysEnum.video}_${roomSessionId}`);
}

export function setAudioPreference(roomSessionId: number | null, value: string) {
  return window.sessionStorage.setItem(`${MediaStorageKeysEnum.audio}_${roomSessionId}`, value);
}

export function getAudioPreference(roomSessionId: number | null) {
  return window.sessionStorage.getItem(`${MediaStorageKeysEnum.audio}_${roomSessionId}`);
}

// GROUPS utils
export function isLSAdmin(groups: string[] = []): boolean {
  return groups.includes(GROUPS.LS_ADMIN);
}

export function isModerator(groups: string[] = []): boolean {
  return groups.some((group) => [GROUPS.CUSTOMER_ADMIN, GROUPS.COLLABORATOR].includes(group));
}

export function isModeratorRole(groups: string[] = []): boolean {
  return isLSAdmin(groups) || isModerator(groups);
}

// Video blur utils
export function getVideoBlur() {
  return sessionStorage.getItem(VIDEO_BLUR_KEY);
}

export function setVideoBlur(value: string) {
  return sessionStorage.setItem(VIDEO_BLUR_KEY, value);
}
