import { ContentfulClientInterface } from 'react-contentful/types';
import { LocalParticipant } from 'twilio-video';
import GameService from './GameService';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from 'hooks/useConnectionStatus/types';

export const GAME_STATES = {
  INTRO: 'INTRO', // introModalSteps
  PLAYING: 'PLAYING', // loops
  LOSS: 'LOSS', // outroModalSteps
  WIN: 'WIN', // outroModalStepsWin
  DONE: 'DONE', // gameOver
  PAUSED: 'PAUSED', // gamePaused
};
export enum PenaltyType {
  QUESTION = 'QUESTION',
  PUZZLE = 'PUZZLE',
}
export enum InstructionType {
  GENERAL = 'GENERAL',
  PUZZLE = 'PUZZLE',
}

export enum StepType {
  QUIZ = 'QUIZ',
  PUZZLE = 'PUZZLE',
  VIDEO = 'VIDEO',
}

export interface Players {
  [key: string]: Player;
}

export interface GameDefinition {
  __id: string;
  key: string;
  lastUpdated: number;
  activeStep: ActiveStep;
  gameData: GameData;
  gameResults?: any;
  gameSettings: GameSettings;
  introModalSteps: Array<object>;
  loops: Array<Array<CEOVideo | QuizQuestion | Puzzle | any>>;
  outroModalSteps: Array<CEOVideo>;
  outroModalStepsWin: Array<CEOVideo>;
  puzzleResource?: PuzzleResource;
  instructions?: Array<Instruction>;
  players: Players;
  displayName?: string;
  language?: string;
  joinedPlayers: Record<string, string>;
  leader?: string;
}

export interface Player {
  isReady: boolean;
  teamName: string;
  index: number;
  name: string;
  email: string;
  videoIsOver?: false | number;
  status?: string;
  groups: string[];
  isMuted: boolean;
  isMutedByModerator: boolean;
}
export interface AnswerStatistics {
  id?: string;
  answerPlainText: string;
  correctAnswer?: boolean;
  chosen?: boolean;
}

export interface QuestionStatistics extends QuizQuestionData {
  answeredCorrectFirstTime?: boolean;
  answers: Array<AnswerStatistics>;
}

export interface GameData {
  selectedTeamName: number;
  selectedTeamNameValue?: string;
  teamName: string;
  teamNamePicker: boolean;
  startTime: string;
  endTime?: string;
  accruedTimeSeconds: number;
  position: number;
  results?: any;
  error?: any;
  penalties: {
    QUESTION: number;
    PUZZLE: number;
  };
  questionsStatistics: Array<QuestionStatistics>;
  [key: string]: any;
}
export interface GameSettings {
  displayName?: string;
  hideCountdownTimer?: boolean;
  hideAccruedTime?: boolean;
  hideRank?: boolean;
  gameLengthMinutes?: number;
}

export interface ActiveStep {
  gameState: string;
  loop: number;
  step: number;
  stepData: {
    selected?: number;
    disabled?: Array<number>;
    puzzleExpanded?: boolean;
    resultsSent?: boolean;
    answerPlainText?: string;
  };
}

export interface GameplayProviderProps {
  children: {
    props: {
      client: ContentfulClientInterface;
    };
  };
  roomId: string;
  firebaseSettings: any;
  gameService: GameService;
  contentfulData: any;
}

export interface CEOVideo {
  title: string;
  captionUrls: Array<CEOCaptions>;
  videoFile: Array<CloudinaryVideo>;
  captionFile: any; // TODO: add real type
}
export function instanceOfCEOVideo(object: any): object is CEOVideo {
  return 'videoFile' in object;
}
export interface CEOCaptions {
  title: string;
  url: string;
  abbreviation: string;
}
export interface CloudinaryVideo {
  url: string;
  tags: Array<string>;
  type: string;
  bytes: number;
  width: number;
  format: string;
  height: number;
  version: number;
  duration: number;
  metadata: Array<any>;
  public_id: string;
  created_at: string;
  secure_url: string;
  resource_type: string;
  original_url: string;
  original_secure_url: string;
  raw_transformation: string;
}
export interface QuizQuestionData {
  questionPlainText: string;
  id: string;
}
export interface QuizQuestion extends QuizQuestionData {
  title: string;
  answers: Array<QuizAnswer>;
  questionType?: string;
}
export function instanceOfQuizQuestion(object: any): object is QuizQuestion {
  return 'questionPlainText' in object;
}
export interface QuizAnswer {
  sys: Record<string, any>;
  fields: QuizAnswerContent;
}
export interface QuizAnswerContent {
  title: string;
  answerPlainText: string;
  correctAnswer: boolean;
  nudge: string;
}
export interface Puzzle {
  title: string;
  puzzleType: Array<string>;
  puzzleVariation: Array<string>;
  intro?: {
    intro: Array<object>;
  };
  settings?: object;
  puzzleDefinition?: object;
  conclusion?: {
    conclusion: Array<object>;
  };
  failResult?: {
    fail: Array<object>;
  };
  winResult?: {
    copy: boolean;
    text: string;
    title: string;
  };
  puzzleContent: Record<string, any>;
  placeholderImage: any;
  instructions: Array<{ fields: any; metadata: any; sys: any }>;
}
export interface Instruction {
  internalTitle: string;
  title: string;
  body: string;
  type: InstructionType;
}
export interface InstructionSection {
  title: string;
  type: string;
  items: Array<Instruction>;
}
export interface PuzzleResource {
  name: string;
  displayName: string;
  resourcesType: string;
  theme?: {
    backgroundImage?: string;
  };
  resources?: {
    files?: Array<object>;
    directories?: Array<object>;
  };
  code: string;
}
export function instanceOfPuzzle(object: any): object is Puzzle {
  return 'puzzleType' in object;
}

export interface IGlobalContext {
  gameService: GameService;
  setCurrentIdentity: React.Dispatch<React.SetStateAction<string>>;
  setCurrentGameStep: (
    gameState: string,
    loop: number,
    step: number,
    stepData?: object,
    stepType?: string,
    successDatabaseCallback?: Function,
  ) => void;
  setUsersNames: (playerName: string, teamName: string, email: string, groups: string[]) => void;
  updateParticipantList: (localParticipant: LocalParticipant) => void;
  switchAudio: (enable: boolean, manuallyMuted?: boolean) => void;
  roomId: string;
  identity: string;
  isLeader: boolean;
  gameDef: GameDefinition;
  debug: boolean;
  setWatchedStatus: (status: boolean) => void;
  roomSessionId: number | null;
  contentfulData: any;
  isLeaderChosen: boolean;
  currentLeader: string | null;
  onDisconnect: () => void;
  setConnectionStatus: (status: string) => void;
  connectionProblems: ConnectionProblem[];
  setConnectionProblems: (problem: (prev: ConnectionProblem[]) => ConnectionProblem[]) => void;
  startTimers: () => void;
  isSessionHosted: boolean;
}

export interface IGameResults {
  gameTime: string;
  incorrectCount: number;
  incorrectPenalty: string;
  skipCount: number;
  skipPenalty: string;
  totalTime: string;
  finalTimeInSeconds: number;
  penaltyTimeInSeconds: number;
}

export interface ConnectionProblem {
  problemType: ConnectionProblemTypeEnum;
  notificationType: ConnectionNotificationTypeEnum;
}

export function getStepType(object: any) {
  if (instanceOfCEOVideo(object)) return StepType.VIDEO;
  else if (instanceOfPuzzle(object)) return StepType.PUZZLE;
  else if (instanceOfQuizQuestion(object)) return StepType.QUIZ;
  return null;
}
