import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from '@material-ui/core';
import { testStatusesEnum } from '../../JoinModal';
import StatusBlock from '../StatusBlock';

const AudioStatusBlock = ({ success }: { success?: boolean }) => {
  const { t } = useTranslation();
  const title = success ? t('join-status:audio-success-title') : t('join-status:audio-blocked-title');
  const description = success ? (
    t('join-status:audio-looks-good')
  ) : (
    <Trans
      i18nKey="join-status:audio-looks-bad"
      components={[
        <Link
          href="https://support.livingsecurity.com/hc/en-us/articles/360055741614-How-do-I-enable-my-microphone-and-webcam-for-the-experience-"
          target="_blank"
          rel="noreferrer noopener nofollow"
          key={0}
          style={{
            color: '#89BBFF',
          }}
        />,
        <Link
          key={2}
          style={{
            color: '#89BBFF',
          }}
          onClick={() => document.location.reload()}
        />,
      ]}
    />
  );
  const status = success ? testStatusesEnum.SUCCESS : testStatusesEnum.FAIL;

  return <StatusBlock title={title} description={description} status={status} testId="audio" />;
};

export default AudioStatusBlock;
