import { useContext, useEffect } from 'react';
import { useConnectionStatus, useVideoContext } from 'hooks';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from 'hooks/useConnectionStatus/types';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { GAME_STATES } from 'containers/GameplayPage/services/types';

export default function useTwilioReconnectionNotifications() {
  const context = useContext(GlobalContext);
  const { gameState } = context.gameDef.activeStep;
  const { room } = useVideoContext();

  const { addConnectionStatusNotification, removeConnectionStatusNotification } = useConnectionStatus();

  useEffect(() => {
    if (!room) return;
    const handleReconnecting = () => {
      addConnectionStatusNotification(ConnectionProblemTypeEnum.TWILIO, ConnectionNotificationTypeEnum.FAIL);
    };

    const handleReconnected = () => {
      removeConnectionStatusNotification(ConnectionProblemTypeEnum.TWILIO);
    };

    const handleDisconnected = () => {
      const statesToShowNotification = [GAME_STATES.INTRO, GAME_STATES.PLAYING];
      if (statesToShowNotification.includes(gameState)) {
        window.location.href = `${window.location.href}&notification=info-video`;
      }
    };

    room.on('reconnecting', handleReconnecting);
    room.on('reconnected', handleReconnected);
    room.on('disconnected', handleDisconnected);

    return () => {
      room.off('reconnecting', handleReconnecting);
      room.off('reconnected', handleReconnected);
      room.off('disconnected', handleDisconnected);
    };
  }, [room, gameState]);
}
