import { useCallback, useContext, useEffect, useState } from 'react';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

import { useIsTrackEnabled, useVideoContext } from 'hooks';

import { checkBlockedPermissions, getAudioPreference, setAudioPreference } from 'utils';

import { updateFirebaseField } from 'services/firestore';

import { USER_NAME_SEPARATOR } from '_constants';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';

export default function useLocalAudioToggle(
  localTracks: Array<LocalAudioTrack | LocalVideoTrack>,
  hasAllowedAudioInputDevices: boolean,
  fromSettings?: boolean,
) {
  const context = useContext(GlobalContext);
  const { onError, getLocalAudioTrack } = useVideoContext();
  const [hasPermissionError, setPermissionError] = useState(false);

  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      isEnabled ? audioTrack.disable() : audioTrack.enable();
      context.switchAudio(!isEnabled, true);

      if (!hasAllowedAudioInputDevices) return;

      const newAudioState = String(!isEnabled);

      setAudioPreference(context.roomSessionId, newAudioState);

      const {
        gameDef: { players, joinedPlayers },
        roomId,
        identity,
      } = context;

      if (!players || !joinedPlayers || !roomId || !identity) return;

      const playerEncryptedEmail = identity.split(USER_NAME_SEPARATOR)[1];
      const joinedPlayer: any = joinedPlayers[playerEncryptedEmail];
      const isMuted = newAudioState === 'false';
      const isMutedByModerator = joinedPlayer?.isMutedByModerator && !isEnabled;

      return updateFirebaseField(roomId, {
        players: {
          ...players,
          [identity]: {
            ...players[identity],
            isMuted,
            isMutedByModerator,
          },
        },
        joinedPlayers: {
          ...joinedPlayers,
          [playerEncryptedEmail]: {
            ...joinedPlayer,
            isMuted,
            isMutedByModerator,
          },
        },
      });
    } else if (!hasPermissionError) {
      getLocalAudioTrack().catch(() => {
        setPermissionError(true);
        checkBlockedPermissions(onError);
      });
    }
  }, [audioTrack, onError, context, getLocalAudioTrack, hasPermissionError, isEnabled, hasAllowedAudioInputDevices]);

  useEffect(() => {
    if (fromSettings) return;
    const previousAudioPreference = getAudioPreference(context.roomSessionId);
    if (String(isEnabled) !== previousAudioPreference) toggleAudioEnabled();
  }, []); // eslint-disable-line

  return [isEnabled, toggleAudioEnabled] as const;
}
