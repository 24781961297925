import React, { useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { datadogLogs } from '@datadog/browser-logs';
import { GlobalContext } from '../../GameplayProvider';
import { instanceOfCEOVideo, instanceOfQuizQuestion, instanceOfPuzzle, GAME_STATES } from '../../services/types';
import QuizView from '../QuizView/QuizView';
import PuzzleContainer from '../PuzzleContainer/PuzzleContainer';
import VideoView from '../VideoView/VideoView';
import { videoContainer } from '../VideoView/TeamsVideoPlayer';

const useStyles = makeStyles({
  gameLoopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: '#F2F2F2',
  },
  videoContainer,
});

export default function GameLoop() {
  const context = useContext(GlobalContext);
  const classes = useStyles();

  const { loops: gameLoop } = context.contentfulData;
  const { language } = context.gameDef;
  const { gameState, loop, step } = context.gameDef.activeStep;

  const currentStep = gameLoop[loop] && gameLoop[loop][step];
  const loopLimit = Object.keys(gameLoop).length - 1;
  const stepLimit = gameLoop[loop].length - 1;

  const calculateNextStep = useCallback(async () => {
    if (gameState === GAME_STATES.PLAYING && loop >= loopLimit && step >= stepLimit) {
      context.gameService.markEndTime();
      return {
        gameState: GAME_STATES.WIN,
        loop: 0,
        step: 0,
      };
    } else if (step >= stepLimit) {
      if (context.isLeader) {
        await context.gameService.sendGameResults({
          completed: false,
          endTime: moment().format('x'),
          intermediate: true,
        });
      }
      return {
        gameState: gameState,
        loop: loop + 1,
        step: 0,
      };
    } else {
      return {
        gameState: gameState,
        loop: loop,
        step: step + 1,
      };
    }
  }, [
    context.gameService.sendGameResults,
    context.gameService.markEndTime,
    context.isLeader,
    gameState,
    loop,
    loopLimit,
    step,
    stepLimit,
  ]);

  const quizCompleteCallback = useCallback(
    async (callback) => {
      const nextSteps = await calculateNextStep();
      datadogLogs.logger.info(`Calculating step after quiz: ${JSON.stringify(nextSteps)}`);
      context.setCurrentGameStep(nextSteps.gameState, nextSteps.loop, nextSteps.step, undefined, undefined, callback);
    },
    [calculateNextStep, context.setCurrentGameStep],
  );

  const puzzleCompleteCallback = useCallback(async () => {
    const nextSteps = await calculateNextStep();
    datadogLogs.logger.info(`Calculating step after puzzle: ${JSON.stringify(nextSteps)}`);
    context.setCurrentGameStep(nextSteps.gameState, nextSteps.loop, nextSteps.step);
  }, [calculateNextStep, context.setCurrentGameStep]);

  const gameStep = useMemo(() => {
    return typeof currentStep === 'string' ? JSON.parse(currentStep) : currentStep;
  }, [currentStep]);

  return currentStep ? (
    <div className={classes.gameLoopContainer}>
      <MemoizedLoopStep
        gameStep={gameStep}
        language={language}
        calculateNextStep={calculateNextStep}
        quizCompleteCallback={quizCompleteCallback}
        puzzleCompleteCallback={puzzleCompleteCallback}
        currentActivity={`${loop}-${step}`}
      />
    </div>
  ) : null;
}

const MemoizedLoopStep = React.memo(LoopStep);

function LoopStep(props: any) {
  const {
    gameStep,
    language,
    calculateNextStep,
    quizCompleteCallback,
    puzzleCompleteCallback,
    currentActivity,
  } = props;

  if (instanceOfCEOVideo(gameStep)) {
    return <VideoView currentStep={gameStep} language={language} calculateNextStep={calculateNextStep} isLoop />;
  } else if (instanceOfQuizQuestion(gameStep)) {
    return <QuizView quizQuestion={gameStep} callback={quizCompleteCallback} />;
  } else if (instanceOfPuzzle(gameStep)) {
    return (
      <PuzzleContainer puzzleData={gameStep} callback={puzzleCompleteCallback} currentActivity={currentActivity} />
    );
  }
  return null;
}
