import firebase from 'firebase';
import moment from 'moment';
import { GAME_DEF_COLLECTION, STATE_ROUTE, PUZZLE_DB } from 'hooks/useFirebaseConnection/useFirebaseConnection';
import { datadogLogs } from '@datadog/browser-logs';

// This function authenticates the server's access token with the Google Firebase client
export function authenticateFirebase(accessToken: string, callback?: () => void) {
  return firebase
    .auth()
    .signInWithCustomToken(accessToken)
    .then(() => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log({ message: 'Authenticated Successfully' });
      }
      callback && callback();
    })
    .catch((e) => {
      datadogLogs.logger.error(`Authentication Error in Firestore Service: ${JSON.stringify(e)}`);
      throw e;
    });
}

// This function merges in the `update` object you pass in
export function mergeToFirebase(
  roomId: string,
  update: Partial<firebase.firestore.DocumentData>,
  callback?: () => void,
  isInitialLoad?: boolean,
) {
  // @ts-ignore
  if (window?.offlineMode) {
    firestoreRequest(roomId, update, true, callback, isInitialLoad);
    // Firestore requests don't return promises while offline (TEAMS-1347)
    return Promise.resolve();
  }
  return firestoreRequest(roomId, update, true, callback, isInitialLoad);
}

// This function completely overwrites with the entire `update` object you pass in
export function writeToFirebase(
  roomId: string,
  update: Partial<firebase.firestore.DocumentData>,
  callback?: () => void,
) {
  // @ts-ignore
  if (window?.offlineMode) {
    firestoreRequest(roomId, update, false, callback);
    return Promise.resolve();
  }
  return firestoreRequest(roomId, update, false, callback);
}

export function updateFirebaseField(
  roomId: string,
  update: Partial<firebase.firestore.DocumentData>,
  withTransaction: boolean = true,
) {
  const firestoreTransactions = window.ldClient?.variation('enable-firestore-transactions');
  const hostedVersion = window.ldClient?.isSessionHosted;

  if (withTransaction && firestoreTransactions && !hostedVersion) {
    return firebase.firestore().runTransaction((transaction) => {
      const docRef = firebase.firestore().collection(GAME_DEF_COLLECTION).doc(roomId);
      return transaction.get(docRef).then((doc) => {
        const data = doc.data();
        if (!doc.exists || !data) {
          throw new Error('Document does not exist!');
        }
        transaction.update(docRef, {
          key: process.env.REACT_APP_FIREBASE_LS_KEY,
          lastUpdated: moment().format('x'),
          ...update,
        });
      });
    });
  } else {
    const res = firebase
      .firestore()
      .collection(GAME_DEF_COLLECTION)
      .doc(roomId)
      .update({
        lastUpdated: moment().format('x'),
        ...update,
      })
      .then(() => {});
    // @ts-ignore
    return window?.offlineMode ? Promise.resolve() : res;
  }
}

function firestoreRequest(
  roomId: string,
  update: Partial<firebase.firestore.DocumentData>,
  merge: firebase.firestore.SetOptions['merge'],
  callback?: () => void,
  isInitialLoad?: boolean,
) {
  const firstoreTransactions = window.ldClient?.variation('enable-firestore-transactions');
  const hostedVersion = window.ldClient?.isSessionHosted;

  if (firstoreTransactions && !isInitialLoad && !hostedVersion) {
    return firebase.firestore().runTransaction((transaction) => {
      const docRef = firebase.firestore().collection(GAME_DEF_COLLECTION).doc(roomId);
      return transaction.get(docRef).then((doc) => {
        const data = doc.data();
        if (!doc.exists || !data) {
          datadogLogs.logger.error(
            `Document does not exist in Firestore Service (firestoreRequest): Room ID: ${roomId} `,
          );
          throw new Error('Document does not exist!');
        }
        if (merge) {
          transaction.set(
            docRef,
            {
              key: process.env.REACT_APP_FIREBASE_LS_KEY,
              lastUpdated: moment().format('x'),
              ...update,
            },
            { merge },
          );
        } else {
          transaction.set(docRef, {
            key: process.env.REACT_APP_FIREBASE_LS_KEY,
            lastUpdated: moment().format('x'),
            ...data,
            ...update,
          });
        }
      });
    });
  } else {
    return firebase
      .firestore()
      .collection(GAME_DEF_COLLECTION)
      .doc(roomId)
      .set(
        {
          key: process.env.REACT_APP_FIREBASE_LS_KEY,
          lastUpdated: moment().format('x'),
          ...update,
        },
        { merge },
      )
      .then(() => {
        if (process.env.REACT_APP_DEBUG === 'true') {
          console.log({ message: `Session ${roomId} updated (firestore)`, merge, update });
        }
      })
      .catch((e) => {
        datadogLogs.logger.error(`Unknown Error in Firestore Service (firestoreRequest): ${JSON.stringify(e)}`);
      });
  }
}

export function setValueRealtime(
  route = STATE_ROUTE,
  roomId = '',
  update: Partial<firebase.firestore.DocumentData>,
  callback?: () => void,
) {
  return firebase
    .database()
    .ref(`${PUZZLE_DB}/${roomId}/${route}/`)
    .set({
      key: process.env.REACT_APP_FIREBASE_LS_KEY,
      lastUpdated: moment().format('x'),
      ...update,
    })
    .then(() => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log({ message: `Session ${roomId} updated (realtime)`, update });
      }
      callback && callback();
    })
    .catch((e) => {
      datadogLogs.logger.error(`Unknown Error in Firestore Service (setValueRealtime): ${JSON.stringify(e)}`);
    });
}

export function updateValueRealtime(
  route = STATE_ROUTE,
  roomId = '',
  update: Partial<firebase.firestore.DocumentData>,
  callback?: () => void,
) {
  return firebase
    .database()
    .ref(`${PUZZLE_DB}/${roomId}/${route}/`)
    .update({
      key: process.env.REACT_APP_FIREBASE_LS_KEY,
      lastUpdated: moment().format('x'),
      ...update,
    })
    .then(() => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log({ message: `Session ${roomId} updated (realtime)`, update });
      }
      callback && callback();
    })
    .catch((e) => {
      datadogLogs.logger.error(`Unknown Error in Firestore Service (updateValueRealtime): ${JSON.stringify(e)}`);
    });
}

export function getRealtime(
  route = STATE_ROUTE,
  roomId = '',
  callback: (a: firebase.database.DataSnapshot, b?: string | null) => any,
) {
  return firebase
    .database()
    .ref(`${PUZZLE_DB}/${roomId}/${route}/`)
    .orderByChild('lastUpdated')
    .limitToLast(1)
    .toJSON();
}
