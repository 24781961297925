import React, { forwardRef } from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps as MuiDialogProps,
  Zoom,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Button } from 'components/Button';

import { useStyles } from './styled';

const Transition = forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Zoom ref={ref} {...props} />
  ),
);

type DialogProps = {
  title?: React.ReactNode | string;
  okText?: string;
  onClose?: () => void;
  withActions?: boolean;
} & MuiDialogProps;

export const Dialog: React.FC<DialogProps> = ({
  title,
  children,
  onClose,
  okText = 'OK',
  classes: extendedClasses,
  withActions = true,
  ...dialogProps
}) => {
  const classes = useStyles({ ...extendedClasses });
  const handleClose = () => onClose && onClose();

  return (
    <MuiDialog
      onClose={handleClose}
      maxWidth="xs"
      fullWidth={true}
      classes={{ paper: `${classes.errorDialog} ${extendedClasses?.paper}` }}
      {...dialogProps}
      TransitionComponent={Transition}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.75)' } }}
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: `${classes.contentText} ${extendedClasses?.root}` }}>{children}</DialogContent>
      {withActions && (
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {okText}
          </Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
};
