import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  default: {
    margin: '0 auto',
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: 'var(--aquamarine)',
  },
  disabled: {
    background: 'var(--danger)',
  },
});

const IconWrapper = ({ isEnabled, icon }: { isEnabled: boolean; icon: string }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.default, { [classes.disabled]: !isEnabled })}>
      <img src={icon} />
    </div>
  );
};

export default IconWrapper;
