import React, { useContext, useEffect } from 'react';
import { GlobalContext } from './GameplayProvider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { datadogLogs } from '@datadog/browser-logs';

import { useMainParticipant } from 'hooks';

import { GAME_STATES } from './services/types';

import GameIntro from './components/GameBoard/GameIntro';
import GameOutro from './components/GameBoard/GameOutro';
import GameLoop from './components/GameBoard/GameLoop';
import TeamNameSelection from './components/GameBoard/TeamNameSelection';
import GameResult from './components/GameResult/GameResult';
import GamePaused from './components/GamePaused';

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 1,
  },
}));

export default function GameplayContent() {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const mainParticipant = useMainParticipant();

  useEffect(() => {
    if (mainParticipant) {
      context.setCurrentIdentity(mainParticipant.identity);
    }
  }, [mainParticipant?.identity]);

  if (!mainParticipant) return null;

  const isLeader = !!mainParticipant && context.isLeader;

  return (
    <Grid container alignItems="flex-start" className={classes.container}>
      <MemoizedGameStep context={context} isLeader={isLeader} />
    </Grid>
  );
}

const MemoizedGameStep = React.memo(GameStep);

function GameStep(props: any) {
  const { context, isLeader } = props;
  const { gameState, step } = context.gameDef.activeStep;
  const teamName = context.gameDef.gameData.teamName;
  const gameName =
    context.contentfulData.displayName || context.contentfulData?.gameSettings?.displayName || 'Entrance Exam';

  useEffect(() => {
    if (isLeader) {
      datadogLogs.logger.info(`${teamName} is on step ${step} and the game state is ${gameState}`);
    }
  }, [isLeader, gameState, step]); // eslint-disable-line

  switch (gameState) {
    case GAME_STATES.PLAYING: {
      return context.gameDef.gameData.startTime ? <GameLoop /> : <TeamNameSelection />;
    }
    case GAME_STATES.WIN: {
      return (
        <GameOutro
          gameState={GAME_STATES.WIN}
          step={step}
          isLeader={isLeader}
          debug={context.debug}
          modalData={context.contentfulData.outroModalStepsWin}
          setCurrentGameStep={context.setCurrentGameStep}
          gameCompleted={true}
        />
      );
    }
    case GAME_STATES.LOSS: {
      return (
        <GameOutro
          gameState={GAME_STATES.LOSS}
          step={step}
          isLeader={isLeader}
          debug={context.debug}
          modalData={context.contentfulData.outroModalSteps}
          setCurrentGameStep={context.setCurrentGameStep}
          gameCompleted={false}
        />
      );
    }
    case GAME_STATES.INTRO: {
      return (
        <GameIntro
          step={step}
          isLeader={isLeader}
          debug={context.debug}
          modalData={context.contentfulData.introModalSteps}
          setCurrentGameStep={context.setCurrentGameStep}
        />
      );
    }
    case GAME_STATES.PAUSED: {
      return <GamePaused />;
    }
    default: {
      return (
        <GameResult
          teamName={context.gameDef.gameData.teamName}
          gameName={gameName}
          gameResults={context.gameService.getGameResults()}
        />
      );
    }
  }
}
