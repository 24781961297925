import React from 'react';

export function getSnackbarClone(text: string) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '84px',
        right: '0',
        padding: '14px 16px',
        background: 'rgb(249, 250, 251)',
        borderRadius: '4px',
        fontSize: '0.875rem',
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        boxShadow:
          '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
        minWidth: '288px',
      }}
    >
      {text}
    </div>
  );
}
