import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { setJWT, setRoomId } from '../../services/token';
import { apiClient } from '../../services/api';
import { getURLParamsAsObject } from 'utils';
import { createAuth0Client } from '@auth0/auth0-spa-js';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressColor: {
    color: theme.palette.common.white,
    marginTop: '20px',
  },
}));

export default function AuthRedirectPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setIsAuthenticated } = useAppState();

  const handleRedirect = useCallback(async () => {
    const auth0 = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH_DOMAIN || '',
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
      authorizationParams: {
        response_type: 'token id_token',
        redirect_uri: `${window.location.origin}/redirect${window.location.search}`,
      },
    });

    await auth0.handleRedirectCallback();
    const authToken = await auth0.getTokenSilently();
    const idToken = await auth0.getIdTokenClaims();
    const params = getURLParamsAsObject();
    const { sessionId } = params;

    setJWT(`${idToken?.__raw}`);
    apiClient.setAuthorizationHeader(`${idToken?.__raw}`);
    setIsAuthenticated(true);
    setRoomId(sessionId);
    window.location.replace(`${window.location.origin}/${sessionId}${window.location.search}`);
  }, []);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return (
    <Grid container alignItems="flex-start" className={classes.container}>
      {t('auth:redirect')}
      <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
    </Grid>
  );
}
