import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    fontSize: '1.25rem',
    width: 'inherit',
    color: theme.palette.common.white,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 6fr 2fr',
    justifyItems: 'center',
    gap: '8px 18px',
  },
  tableBody: {
    marginTop: '8px',
  },
  tableRow: {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    overflow: 'hidden',
  },
  tableRowActive: {
    color: '#5dcdfc',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
  progressColor: {
    color: theme.palette.common.white,
  },
}));
