import i18n from 'i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getURLParamsAsObject } from './index';
import german from '../locales/de-DE.json';
import english from '../locales/en.json';
import spanish from '../locales/es-MX.json';
import japanese from '../locales/ja-JP.json';
import korean from '../locales/ko-KR.json';
import italian from '../locales/it-IT.json';
import dutch from '../locales/nl-NL.json';
import polish from '../locales/pl-PL.json';
import french from '../locales/fr-FR.json';
import frenchCa from '../locales/fr-CA.json';
import indonesian from '../locales/id-ID.json';
import portuguese from '../locales/pt-BR.json';
import russian from '../locales/ru-RU.json';
import thai from '../locales/th-TH.json';
import turkish from '../locales/tr-TR.json';
import vietnamese from '../locales/vi-VN.json';
import chineseSimplified from '../locales/zh-CN.json';
import chineseTraditional from '../locales/zh-HK.json';

import de from 'i18next-icu/locale-data/de';
import en from 'i18next-icu/locale-data/en';
import es from 'i18next-icu/locale-data/es';
import ja from 'i18next-icu/locale-data/ja';
import fr from 'i18next-icu/locale-data/fr';
import id from 'i18next-icu/locale-data/id';
import it from 'i18next-icu/locale-data/it';
import ko from 'i18next-icu/locale-data/ko';
import nl from 'i18next-icu/locale-data/nl';
import pl from 'i18next-icu/locale-data/pl';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';
import th from 'i18next-icu/locale-data/th';
import tr from 'i18next-icu/locale-data/tr';
import vi from 'i18next-icu/locale-data/vi';
import zh from 'i18next-icu/locale-data/zh';

export const I18N_QUERY_PARAM_KEY = 'language';
export const I18N_LOCAL_STORAGE_KEY = 'userLanguage';

const { language } = getURLParamsAsObject();

i18n
  // Detect user language - Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    new ICU({
      localeData: [de, en, es, ja, fr, id, it, nl, pl, pt, zh, tr, ko, ru, th, vi],
    }),
  )
  // Init i18next - For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      de: { ...german },
      en: { ...english },
      es: { ...spanish },
      'es-MX': { ...spanish },
      'fr-CA': { ...frenchCa },
      fr: { ...french },
      'id-ID': { ...indonesian },
      'it-IT': { ...italian },
      ja: { ...japanese },
      ko: { ...korean },
      'nl-NL': { ...dutch },
      'pl-PL': { ...polish },
      'pt-BR': { ...portuguese },
      'ru-RU': { ...russian },
      'th-TH': { ...thai },
      'tr-TR': { ...turkish },
      vi: { ...vietnamese },
      'zh-Hans': { ...chineseSimplified },
      'zh-Hant': { ...chineseTraditional },
    },
    interpolation: {
      escapeValue: false,
      prefix: '{{',
      suffix: '}}',
    },
    react: {
      wait: true,
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: I18N_QUERY_PARAM_KEY,
      lookupLocalStorage: I18N_LOCAL_STORAGE_KEY,
      caches: ['localStorage'],
    },
  });

export default i18n;
