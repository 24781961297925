/* eslint-disable no-unused-expressions */
import './wdyr';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import { ResponsiveWrapper } from '@livingsecurity/cyberblocks';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import firebase from 'firebase/app';
import { FirestoreDocument, FirestoreProvider } from '@react-firebase/firestore';
import { ContentfulProvider } from 'react-contentful';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { Userpilot } from 'userpilot';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import i18n from './utils/i18n';
import version from './hash.json';
import { isEmpty } from 'lodash';

import { CircularProgress, CssBaseline } from '@material-ui/core';
import { makeStyles, MuiThemeProvider, styled } from '@material-ui/core/styles';
import theme from './theme';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';

import GameplayProvider from './containers/GameplayPage/GameplayProvider';
import GameplayPage from './containers/GameplayPage/GameplayPage';
import QuizView from './containers/GameplayPage/components/QuizView/QuizView';
import VideoView from './containers/GameplayPage/components/VideoView/VideoView';
import LandingPage from './containers/LandingPage';
import AuthRedirectPage from './containers/AuthRedirectPage';
import TopTeamStandingsPage from './containers/TopTeamStandingsPage';
import WaitingRoomPage from './containers/WaitingRoomPage';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { VideoProvider } from './components/VideoProvider';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { GAME_DEF_COLLECTION } from './hooks/useFirebaseConnection/useFirebaseConnection';
import { BackgroundLayout } from 'components/layouts';

import './assets/styles/fonts.scss';
import './assets/styles/base.scss';
import './types';

import useInitializationService from './services/initialization';
import Grid from '@material-ui/core/Grid';

const VersionHash = styled('div')(() => ({
  opacity: '.05',
  position: 'fixed',
  bottom: '0',
  right: '0',
  fontSize: '10px',
  padding: '1px 4px',
  zIndex: 9,
  '&:hover': {
    opacity: '.3',
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressColor: {
    color: theme.palette.common.white,
    marginTop: '20px',
  },
}));

if (process.env.REACT_APP_DATADOG_CLIENT_ID) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
    site: 'datadoghq.com',
    service: 'teams',
    env: process.env.REACT_APP_DATADOG_ENV || 'dev',
    sampleRate: 100,
    forwardErrorsToLogs: true,
    silentMultipleInit: true,
    beforeSend: (log) => {
      // Ignore noisy warnings
      if (
        log?.message?.includes('Request failed with status code 400') ||
        log?.message?.includes('Failed prop') ||
        log?.message?.includes('Script error') ||
        log?.message?.includes('firestore.googleapis.com/google.firestore.v1.Firestore/Write') ||
        log?.message?.includes('firestore.googleapis.com/google.firestore.v1.Firestore/Listen') ||
        log?.message?.includes('identity.livingsecurity.com/usernamepassword/challenge') ||
        log?.message?.includes('focus-fighting detected') ||
        log?.message?.includes('Warning:')
      ) {
        return false;
      }
    },
  });

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
    site: 'datadoghq.com',
    service: 'teams',
    env: process.env.REACT_APP_DATADOG_ENV || 'dev',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
  });
}

const CyberEscapeOnlineApp = (props: any) => {
  const classes = useStyles();
  const location = useLocation<{ from: Location }>();
  const roomId = location.pathname.split('/')[1];
  const { error, setError, settings, isAuthenticated } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  const {
    updateGameDefinition,
    isAllServicesInitialized,
    firebaseSettings,
    firebaseConfig,
    gameService,
    contentfulClient,
    contentfulData,
  } = useInitializationService();

  useEffect(() => {
    if (
      !!process.env.REACT_APP_USERPILOT_APP_TOKEN &&
      (!window.location.href.includes('localhost') || process.env.REACT_APP_USERPILOT === 'true')
    ) {
      Userpilot.initialize(process.env.REACT_APP_USERPILOT_APP_TOKEN);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // Loading Hubspot chat widget AFTER user authenticates
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/5319473.js';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      // Enabling Datadog Session Replay - https://docs.datadoghq.com/real_user_monitoring/guide/session-replay-getting-started
      datadogRum.startSessionReplayRecording();
    } else {
      window.document.getElementById('hubspot-messages-iframe-container')?.remove();
      window.document.getElementById('hs-script-loader')?.remove();
      datadogRum.stopSessionReplayRecording();
    }
  }, [isAuthenticated]);

  const containerLoader = (
    <Grid container alignItems="flex-start" className={classes.container}>
      <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
    </Grid>
  );

  if (isEmpty(firebaseConfig)) return containerLoader;

  return (
    <ResponsiveWrapper disabled>
      <FirestoreProvider {...firebaseConfig} firebase={firebase}>
        <FirestoreDocument path={`${GAME_DEF_COLLECTION}/${roomId}`}>
          {(data) => {
            const gameDefinition = data.value;
            const isLoading = data.isLoading || data.isLoading === undefined;
            updateGameDefinition(gameDefinition, isLoading);

            return isLoading || !isAllServicesInitialized ? (
              containerLoader
            ) : (
              <UnsupportedBrowserWarning>
                <VideoProvider options={connectionOptions} onError={setError} gameService={gameService!}>
                  <ErrorDialog dismissError={() => setError(null)} error={error} />
                  <GameplayProvider
                    roomId={roomId}
                    firebaseSettings={firebaseSettings}
                    gameService={gameService!}
                    contentfulData={contentfulData!}
                  >
                    <ContentfulProvider client={contentfulClient!}>
                      <props.component />
                    </ContentfulProvider>
                  </GameplayProvider>
                </VideoProvider>
              </UnsupportedBrowserWarning>
            );
          }}
        </FirestoreDocument>
      </FirestoreProvider>
    </ResponsiveWrapper>
  );
};

const WrappedApp = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY || '',
})(CyberEscapeOnlineApp);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <BackgroundLayout>
              <Switch>
                <Route exact path="/redirect" component={AuthRedirectPage} />
                <Route exact path="/">
                  <LandingPage />
                </Route>
                <PrivateRoute exact path="/:roomId/join">
                  <WrappedApp component={App} />
                </PrivateRoute>
                <PrivateRoute exact path="/:roomId/play">
                  <WrappedApp component={App} />
                </PrivateRoute>
                <PrivateRoute exact path="/:roomId">
                  <WaitingRoomPage />
                </PrivateRoute>

                {/* These routes are really just for local testing */}
                <PrivateRoute path="/:roomId/gameplay">
                  <WrappedApp component={GameplayPage} />
                </PrivateRoute>
                <PrivateRoute path="/:roomId/quiz">
                  <WrappedApp component={QuizView} />
                </PrivateRoute>
                <PrivateRoute path="/:roomId/video">
                  <WrappedApp component={VideoView} />
                </PrivateRoute>

                <PrivateRoute path="/:roomId/results">
                  <WrappedApp component={TopTeamStandingsPage} />
                </PrivateRoute>

                <Route component={LandingPage} />
                <Redirect to="/" />
              </Switch>
            </BackgroundLayout>
          </SnackbarProvider>
        </AppStateProvider>
      </Router>
      <VersionHash>v{process.env.REACT_APP_VERSION || version.hash}</VersionHash>
    </I18nextProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
