import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 38px)',
    background: 'var(--black)',
    border: '2px solid var(--grey)',
    borderRadius: 10,
  },
  companyLogo: {
    marginBottom: 48,
    maxWidth: '80%',
    maxHeight: 350,
  },
  text: {
    color: 'var(--white)',
    fontSize: 32,
    lineHeight: 1.1,
  },
}));

export default function GamePaused() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <img
        className={classes.companyLogo}
        src="https://cdn.cyberescape.livingsecurity.com/image/logo.png"
        alt="company logo"
      />
      <div className={classes.text}>{t('gameplay:game-paused-by-moderator')}</div>
    </div>
  );
}
