import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { Button } from 'components/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Userpilot } from 'userpilot';

import GameResultTable from './GameResultTable';
import { IGameResults } from 'containers/GameplayPage/services/types';
import ModalContainer from 'components/Modal/ModalContainer';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { useVideoContext, useFullScreenToggle } from 'hooks';
import { getURLParamsAsObject } from 'utils';

type Props = {
  allTimersHidden?: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    gameContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: 'calc(100% - 60px)',
      height: 'calc(100% - 80px)',
      margin: 'auto',
      color: '#F2F2F2',
    },
    content: {
      width: '100%',
      height: '100%',
      minWidth: 'auto',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#f2f2f2',
    },
    resultTitle: {
      fontSize: '1.8rem',
      fontWeight: 800,
      marginTop: '1.5rem',
      textAlign: 'center',
    },
    resultSubtitle: {
      fontSize: '1rem',
      lineHeight: '34px',
      textAlign: 'center',
      display: ({ allTimersHidden }) => (allTimersHidden ? 'none' : 'block'),
      marginBottom: '1rem',
    },
    gameResults: {
      margin: '15px 0',
      width: '95%',
      display: ({ allTimersHidden }) => (allTimersHidden ? 'none' : 'block'),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '15px 0 10px',
      },
    },
    buttonWrapper: {
      margin: '2rem 0.5rem',
    },
  }),
);

export default function ({
  teamName,
  gameName,
  gameResults,
}: {
  teamName: string;
  gameName: string;
  gameResults: IGameResults;
}) {
  const context = useContext(GlobalContext);
  const { room } = useVideoContext();
  const { t } = useTranslation();
  const { userPilotSurveyId } = useFlags();
  const history = useHistory();
  const minResolution = useMediaQuery('(max-width:960px)');
  const { localTracks } = useVideoContext();
  const [, toggleFullScreen] = useFullScreenToggle();
  const [loading, setLoading] = useState(false);
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;
  const classes = useStyles({ allTimersHidden });

  useEffect(() => {
    context.switchAudio(true);

    if (userPilotSurveyId?.length) {
      // Manually trigger UserPilot survey
      Userpilot.trigger(userPilotSurveyId);
    }
  }, []); // eslint-disable-line

  function finishGame() {
    setLoading(true);
    stopTracks()
      .then(() => {
        toggleFullScreen(false);
        setLoading(false);
        room!.disconnect();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        const { campaign_token: campaignToken } = getURLParamsAsObject();
        if (allTimersHidden) {
          history.replace('/');
        } else {
          history.replace(`/${context.roomId}${campaignToken ? `?campaign_token=${campaignToken}` : ''}`);
        }
      });
  }

  function stopTracks() {
    return new Promise((resolve) => {
      localTracks.forEach((track) => {
        track.disable();
        track.stop();
      });
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }

  return (
    <div className={classes.gameContainer}>
      <ModalContainer
        bodyStyles={{ padding: minResolution ? '10px' : '1em' }}
        containerStyles={{ maxWidth: '1000px', width: '100%', minWidth: minResolution ? 'auto' : '550px' }}
      >
        <div className={classes.content}>
          <div>
            <div className={classes.resultTitle} data-testid="game-result-title">
              {allTimersHidden ? t('game-results:untimed-header') : teamName}
            </div>
            <div className={classes.resultSubtitle} data-testid="game-result-subtitle">
              {t('game-results:team-statistics')}
            </div>
          </div>
          <div className={classes.gameResults} data-testid="game-result-table-wrapper">
            <GameResultTable gameResults={gameResults} />
          </div>
          <div>
            <div className={classes.buttonWrapper}>
              <Button onClick={finishGame} data-testid="finish-game-button" loading={loading}>
                {t('game-results:end-game')}
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}
