import React, { useEffect } from 'react';
import { styled } from '@material-ui/core/styles';

import { useRoomState, useTwilioReconnectionNotifications } from 'hooks';

import { getURLParamsAsObject } from './utils';
import { I18N_LOCAL_STORAGE_KEY } from './utils/i18n';

import SessionSignupPage from './containers/SessionSignupPage/SessionSignupPage';
import GameplayPage from './containers/GameplayPage/GameplayPage';

import ConnectionStatusWidget from './components/ConnectionStatusWidget/ConnectionStatusWidget';

const Container = styled('div')({
  overflow: 'hidden',
  height: '100vh',
});

const { language } = getURLParamsAsObject();

function App() {
  const roomState = useRoomState();
  useTwilioReconnectionNotifications();

  useEffect(() => {
    const currentLanguage = language || localStorage.getItem(I18N_LOCAL_STORAGE_KEY);
    if (currentLanguage) {
      localStorage.setItem(I18N_LOCAL_STORAGE_KEY, currentLanguage);
    }
  }, [language]);

  return (
    <Container>
      {roomState === 'disconnected' ? <SessionSignupPage /> : <GameplayPage />}
      <ConnectionStatusWidget />
    </Container>
  );
}

export default App;
