import React, { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Timer from './Timer';

import { GAME_STATES, GameData, GameSettings } from '../../services/types';
import { GlobalContext } from '../../GameplayProvider';

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rightItem: {
      marginLeft: '50px',
    },
    disabled: {
      opacity: 0.2,
    },
  }),
);

export default function NavbarTimers({
  gameData,
  gameStart,
  gameSettings,
}: {
  gameData: GameData;
  gameStart: boolean;
  gameSettings: GameSettings;
}) {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const startTime = useMemo(() => (gameData.startTime ? moment(gameData.startTime, 'x') : {}), [gameData.startTime]);
  const endTime = useMemo(() => (gameData.endTime ? moment(gameData.endTime, 'x') : undefined), [gameData.endTime]);
  const gameLengthSeconds = context.gameService.calculateGameLengthSeconds(gameSettings.gameLengthMinutes);
  const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const [timePenalties, setTimePenalties] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const [timerChangedLastTime, setTimerChangedLastTime] = useState(false);

  const allTimersHidden = gameSettings.hideCountdownTimer && gameSettings.hideAccruedTime;
  const isGamePaused = context.gameDef.activeStep.gameState === GAME_STATES.PAUSED;
  const isTimerDisabled = !gameStart || !size(startTime) || isGamePaused;

  useEffect(() => {
    if (isGamePaused) return;

    if (
      gameStart &&
      size(startTime) &&
      moment(startTime).isAfter(moment().subtract(gameLengthSeconds, 's')) &&
      !endTime
    ) {
      const timer = setTimeout(() => {
        if (parseInt(timeLeft.hours) < 0 && parseInt(timeLeft.minutes) < 0 && parseInt(timeLeft.seconds) < 0) {
          clearTimeout(timer);
          setTimeLeft({ hours: '00', minutes: '00', seconds: '00' });
        } else {
          setTimeLeft(context.gameService.calculateTimeLeft(startTime, gameLengthSeconds));
          setTimePenalties(context.gameService.calculateTimePenalties());
        }
      }, 1000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    } else if (endTime && !timerChangedLastTime) {
      setTimePenalties(context.gameService.calculateTimePenalties());
      setTimerChangedLastTime(true);
    }
  }, [
    context.gameService,
    endTime,
    gameStart,
    startTime,
    timeLeft,
    timerChangedLastTime,
    gameLengthSeconds,
    isGamePaused,
  ]);

  useEffect(() => {
    setTimeLeft(
      isGamePaused ? gameData.gamePausedTimeLeft : context.gameService.calculateTimeLeft(startTime, gameLengthSeconds),
    );
    // every 10 sec check offline connection status
  }, []); // eslint-disable-line

  if (context.gameDef.activeStep.gameState === GAME_STATES.DONE || allTimersHidden) {
    return null;
  }

  return (
    <div className={classes.headerContainer}>
      <div className={clsx({ [classes.disabled]: isTimerDisabled })}>
        {!gameSettings.hideCountdownTimer && (
          <Timer time={timeLeft} label={t('gameplay:header:time-remaining')} testId="game-timer" />
        )}
      </div>
      <div className={clsx(classes.rightItem, { [classes.disabled]: isTimerDisabled })}>
        {!gameSettings.hideAccruedTime && (
          <Timer time={timePenalties} label={t('gameplay:header:time-penalties')} testId="penalties-timer" />
        )}
      </div>
    </div>
  );
}
