import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { IGlobalContext } from '../../containers/GameplayPage/services/types';

const useStyles = makeStyles({
  afterTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  buttonWrapper: {
    marginTop: 40,
  },
});

enum AfterTextEnum {
  DEFAULT,
  MOVE_FORWARD,
}

function useMoveForwardVideo(context: IGlobalContext) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [afterTextState, setAfterTextState] = useState(AfterTextEnum.DEFAULT);
  const [moveForwardArgs, setMoveForwardArgs] = useState<any[]>(['', 0, 0]);
  const [loading, setLoading] = useState(false);

  const moveForward = async () => {
    setLoading(true);
    const [gameState, loop, step] = moveForwardArgs;
    console.error(`Leader force advanced stuck video. Room ID: ${window.location.pathname.split('/')[1]}`);
    context.setCurrentGameStep(gameState, loop, step, undefined, 'video-move-forward');
  };

  const afterText = useMemo(() => {
    return (
      <div className={classes.afterTextWrapper}>
        {afterTextState === AfterTextEnum.DEFAULT ? (
          t('video-player:waiting')
        ) : (
          <>
            {t('video-player:stuck')}
            {context.isLeader && (
              <div className={classes.buttonWrapper}>
                <Button onClick={moveForward} loading={loading}>
                  {t('video-player:move-ahead')}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }, [afterTextState, context.isLeader, moveForwardArgs, loading]);

  const calculateAfterText = useCallback(
    (gameState: string, loop: number, step: number) => {
      setAfterTextState(AfterTextEnum.DEFAULT);
      setMoveForwardArgs([gameState, loop, step]);
      const SECONDS_BEFORE_BUTTON = 15;
      const now = moment().unix();
      const firstEndedTime = Object.values(context.gameDef.players).reduce((acc, player) => {
        if (player.videoIsOver && player.videoIsOver < acc) return player.videoIsOver;
        return acc;
      }, now);
      const timePassed = now - firstEndedTime;

      if (timePassed > SECONDS_BEFORE_BUTTON) {
        setAfterTextState(AfterTextEnum.MOVE_FORWARD);
      } else {
        setTimeout(() => {
          setAfterTextState(AfterTextEnum.MOVE_FORWARD);
        }, (SECONDS_BEFORE_BUTTON - timePassed) * 1000);
      }
    },
    [context.gameDef.players],
  );

  return {
    afterText,
    calculateAfterText,
  };
}

export default useMoveForwardVideo;
