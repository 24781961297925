import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Time {
  hours: string;
  minutes: string;
  seconds: string;
}

type Props = {
  time: Time;
  label: string;
  testId: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100px',
      color: 'rgba(242, 242, 242, 0.87)',
    },
    timer: {
      fontFamily: 'AtomicAge',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-1.5px',
    },
    label: {
      fontSize: '1.125rem',
      lineHeight: '16px',
      textAlign: 'center',
    },
  }),
);

export default function ({ time, label, testId }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.timer} data-testid={`${testId}-timer`}>
        {time.hours}:{time.minutes}:{time.seconds}
      </div>
      <div className={classes.label} data-testid={`${testId}-label`}>
        {label}
      </div>
    </div>
  );
}
