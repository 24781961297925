import React, { ChangeEvent, useCallback, useState } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  answerContainer: {
    margin: '20px 0 30px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '10px 0 15px',
    },
  },
  textArea: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '20px',
    color: 'lightgray',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
  },
  notLeader: {},
}));

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export default function ({
  handleOpenEndedChange,
  answerText,
  onSubmit,
  isLeader,
  loading,
}: {
  handleOpenEndedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  answerText?: string;
  onSubmit: (answerText: string) => Promise<void>;
  isLeader: boolean;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit(answerText || '');

    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
  }, [onSubmit, answerText]);

  return (
    <ThemeProvider theme={theme}>
      <div
        className={clsx(classes.answerContainer, {
          [classes.notLeader]: !isLeader,
        })}
        data-testid="quiz-text-area"
      >
        {!isSubmitting && !loading && (
          <OutlinedInput
            id="open-ended-answer"
            className={classes.textArea}
            multiline
            rows={5}
            value={answerText}
            onChange={handleOpenEndedChange}
            disabled={!isLeader}
            autoFocus
            required
            data-testid="quiz-text-area-outlined-input"
          />
        )}
        {isLeader && (
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              disabled={!isLeader || !answerText?.trim().length}
              loading={loading || isSubmitting}
              onClick={submitForm}
              testId="quiz-text-area-submit-button"
            >
              {t('gameplay:quiz:submit')}
            </Button>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}
