import React, { useState, useCallback } from 'react';
import { Button } from 'components/Button';
import ModalContainer from '../ModalContainer';
import { useTranslation } from 'react-i18next';

export default function GameStartModal({
  isLeader,
  callback,
  teamName,
  timersHidden,
}: {
  isLeader: boolean;
  callback: Function;
  teamName: string;
  timersHidden: boolean;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    callback(false);
  }, [callback]);

  return (
    <ModalContainer
      title={t('modals:game-start:title-one', { teamName })}
      subtitle={!timersHidden ? t('modals:game-start:title-two') : null}
      subtitleFont={18}
    >
      <div style={{ width: '410px' }}>
        <div>
          <p>
            {timersHidden
              ? t('modals:game-start:description-untimed')
              : t('modals:game-start:description', { penalty: 1 })}
          </p>
        </div>
        <div style={{ float: 'right', marginTop: '20px' }}>
          <Button
            style={{ backgroundColor: '#2d9cdb', padding: '10px 40px', fontSize: '0.875rem' }}
            onClick={handleClick}
            loading={isLoading}
            disabled={!isLeader}
          >
            {t('modals:game-start:lets-go')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
}
