/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @ts-ignore
import { Loader } from '@livingsecurity/cyberblocks';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { checkRoomAvailability, fetchRoomDetails } from '../GameplayPage/utils';
import {
  encryptAES,
  decryptAES,
  getAudioPreference,
  getFieldFromAuthJWT,
  getURLParamsAsObject,
  getVideoPreference,
  removeURLParamByName,
  isModeratorRole,
} from 'utils';
import { useAppState } from 'state';

import { useVideoContext, useLocalVideoToggle, useCheckAuth0TokenStatus, useConnectionStatus } from 'hooks';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from 'hooks/useConnectionStatus/types';

import { GlobalContext } from '../GameplayPage/GameplayProvider';

import SignUpModal from './SignUpModal';
import JoinModal from './JoinModal';
import RescheduleModal from './RescheduleModal';

import * as type from 'services/api/types';
import { AUTH_NAMESPACE } from 'services/token';

import { USER_NAME_SEPARATOR } from '_constants';
import SessionNameNavbar from 'components/Navbar/components/SessionNameNavbar/SessionNameNavbar';

const URLParams = getURLParamsAsObject();

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '15px 0',
      height: 0,
    },
  }),
);

type FormValues = {
  userName: string;
  teamName: string;
};

export default function SessionSignupPage() {
  const globalContext = useContext(GlobalContext);
  const { isSessionHosted } = globalContext;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(isSessionHosted);
  const checkAuth0TokenStatus = useCheckAuth0TokenStatus();

  const debug = location.search.indexOf('debug') > -1;
  const roomId = location.pathname.split('/')[1];
  const gameName =
    globalContext.contentfulData?.displayName ||
    globalContext.contentfulData?.gameSettings?.displayName ||
    'Failed to load content';
  const joinedPlayers = globalContext.gameDef.joinedPlayers;

  const [roomDetails, setRoomDetails] = useState<type.RoomDetails | null>(null);
  const [rescheduleModal, showRescheduleModal] = useState<boolean>(false);
  const [joinModal, showJoinModal] = useState<boolean>(false);
  const { joinRoom, setUserEmail, setIsCurrentUserModerator } = useAppState();
  const { connect } = useVideoContext();
  const [roomName, setRoomName] = useState<string>('');
  const [formData, setFormData] = useState<FormValues>({ userName: '', teamName: '' });
  const [isCheckOnPreviousUser, setCheckOnPreviousUser] = useState(true);
  const [isCheckStarted, setIsCheckStarted] = useState(false);
  const [withSignUpStep, setWithSignUpStep] = useState(true);

  const [loading, setLoading] = useState(false);

  const { addConnectionStatusNotification } = useConnectionStatus();

  const toggleJoinModal = () => {
    showJoinModal((prev) => !prev);
  };

  const setFormValues = (formData: FormValues) => {
    setFormData(formData);
    toggleJoinModal();
  };

  useEffect(() => {
    if (roomId) {
      setRoomName(roomId);
    } else {
      showRescheduleModal(true);
    }
  }, [roomId]);

  const checkRoomDetails = useCallback(async (): Promise<Boolean> => {
    try {
      const newRoomDetails = await fetchRoomDetails(roomId);

      setRoomDetails(newRoomDetails);

      const roomIsAvailable = checkRoomAvailability(newRoomDetails!);

      if (roomIsAvailable) return roomIsAvailable;

      throw new Error();
    } catch (e) {
      setCheckOnPreviousUser(false);
      setLoading(false);
      showRescheduleModal(true);

      return false;
    }
  }, [roomId]);

  useEffect(() => {
    checkRoomDetails();
  }, [checkRoomDetails]);

  const handleJoin = async (userName: string, teamName: string, isJoinAfterRefresh: boolean = false) => {
    try {
      const roomIsAvailable = await checkRoomDetails();

      if (!roomIsAvailable) return;

      const token = await checkAuth0TokenStatus();
      const email = getFieldFromAuthJWT(token, 'email');
      const encryptedEmail = encryptAES(email, globalContext.roomSessionId!);
      const groups = getFieldFromAuthJWT(token, AUTH_NAMESPACE)?.groups || [];
      const newUserName = `${userName}${USER_NAME_SEPARATOR}${encryptedEmail}`;
      const response = await joinRoom(
        isJoinAfterRefresh ? userName : newUserName,
        roomId,
        isJoinAfterRefresh || withSignUpStep,
      );
      const twilioToken = response.token;

      if (!twilioToken) return;

      await connect(twilioToken);

      setUserEmail(email);
      setIsCurrentUserModerator(isModeratorRole(groups));

      globalContext.setUsersNames(isJoinAfterRefresh ? userName : newUserName, teamName, email, groups);

      const { campaign_token, tenant_id } = URLParams;

      history.replace(
        `/${roomId}/play${campaign_token ? `?campaign_token=${campaign_token}&tenant_id=${tenant_id}` : ''}`,
      );
    } catch (e) {
      setCheckOnPreviousUser(false);
      setLoading(false);

      const msg = e.response?.data?.message as string | undefined;

      if (
        msg &&
        !msg.toLowerCase().includes('has ended') &&
        !msg.toLowerCase().includes('username') &&
        !msg.toLowerCase().includes('duplicate identity')
      ) {
        console.error(`Error trying to join Twilio room: ${msg}`);
      }

      if (msg && msg.toLowerCase().includes('full')) {
        showRescheduleModal(true);
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    const { userName, teamName } = formData;

    await handleJoin(userName, teamName);
  }, [formData]);

  useEffect(() => {
    // Leave this check on game in progress (could be usefull later)
    // if (roomDetails?.sessionState !== type.SessionStateEnum.INPROGRESS) return;

    if (isCheckStarted) {
      return;
    }

    const URLParams = new URLSearchParams(window.location.search);
    const isAfterTwilioDisconnection = URLParams.get('notification') === 'info-video';

    if (isAfterTwilioDisconnection) {
      addConnectionStatusNotification(ConnectionProblemTypeEnum.TWILIO, ConnectionNotificationTypeEnum.INFO, 8000);
      removeURLParamByName('notification');
      setWithSignUpStep(false);
    }

    if (!joinedPlayers) {
      setCheckOnPreviousUser(false);
      return;
    }

    try {
      setIsCheckStarted(true);
      checkAuth0TokenStatus()
        .then((token) => {
          const userEmail = getFieldFromAuthJWT(token, 'email');
          const roomSessionId: number = globalContext.gameService.getRoomDetails().session.id;
          const previousJoinedUser = Object.entries(joinedPlayers).find(
            ([joinedPlayerEmail]) => decryptAES(joinedPlayerEmail, roomSessionId) === userEmail,
          );

          if (!previousJoinedUser) {
            setCheckOnPreviousUser(false);
            return;
          }

          const player: any = previousJoinedUser[1];
          const previousVideoPreference = getVideoPreference(roomSessionId);
          const previousAudioPreference = getAudioPreference(roomSessionId);

          if (player.playerName && previousVideoPreference && previousAudioPreference && !isSessionHosted) {
            if (String(isVideoEnabled) !== previousVideoPreference) toggleVideoEnabled();
            if (isAfterTwilioDisconnection) {
              setFormValues({
                userName: player.playerName.split(USER_NAME_SEPARATOR)[0],
                teamName: player.teamSuggestion,
              });
              setCheckOnPreviousUser(false);
              return;
            }
            setLoading(true);
            handleJoin(player.playerName, player.teamSuggestion || '', true);
          } else {
            setCheckOnPreviousUser(false);
          }
        })
        .catch((e) => {
          console.error(e.message);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [joinedPlayers, isCheckStarted, isSessionHosted]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : !roomDetails || isCheckOnPreviousUser ? (
          <div />
        ) : rescheduleModal && !debug ? (
          <RescheduleModal roomDetails={roomDetails} />
        ) : (
          <>
            <JoinModal
              handleSubmit={handleSubmit}
              userName={formData.userName}
              onClose={toggleJoinModal}
              isJoinModal={joinModal}
              withBackButton={withSignUpStep}
            />
            <SignUpModal roomName={roomName} handleSubmit={setFormValues} isJoinModal={joinModal} />
          </>
        )}
      </div>
      <SessionNameNavbar displayName={gameName} />
    </div>
  );
}
