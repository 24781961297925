import React, { ChangeEvent, useContext, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
// @ts-ignore
import { Button } from '@livingsecurity/cyberblocks';
import { useMediaQuery, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PenaltyType, QuizAnswer, QuizQuestion } from '../../services/types';
import ModalContainer from 'components/Modal/ModalContainer';
import QuizOption from './QuizOption';
import clsx from 'clsx';
import { GlobalContext } from '../../GameplayProvider';
import { updateFirebaseField } from 'services/firestore';
import QuizTextArea from './QuizTextArea';
import { QuestionType } from 'models/QuestionType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: '0 1 0',
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflowX: 'hidden',
      color: '#f2f2f2',
    },
    title: {
      fontSize: '26px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    subtitle: {
      fontSize: '0.875rem',
      lineHeight: '26px',
      textAlign: 'center',
      color: '#139DD8',
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
        lineHeight: '20px',
      },
    },
    question: {
      fontSize: '1rem',
      padding: '16px 0 24px',
      color: '#eaeaea',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5vw',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '6px 0 4px',
      },
    },
    answerContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflowX: 'hidden',
    },
    answerResult: {
      width: '100%',
    },
    answer: {
      color: '#ff0000',
      fontSize: '1.25rem',
      padding: '3px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    correctAnswer: {
      color: '#2d9cdb',
    },
    feedback: {
      padding: '30px 0',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 0',
      },
    },
    nudge: {
      padding: '10px 20px',
      backgroundColor: 'rgba(2, 74, 105, .5)',
      borderRadius: '5px',
      fontSize: '1rem',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        padding: '5px 1 0px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
      },
    },
    progressColor: {
      color: theme.palette.common.white,
      width: '30px!important',
      height: '30px!important',
    },
    submitButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      width: '100%',
      height: '50px',
    },
  }),
);

export default function ({ quizQuestion, callback }: { quizQuestion: QuizQuestion; callback: Function }) {
  const context = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState(context.gameDef.activeStep);
  const selected = context.gameDef.activeStep.stepData?.selected || 0;
  const disabled = context.gameDef.activeStep.stepData?.disabled || [0];
  const { hideCountdownTimer } = context.contentfulData.gameSettings;
  const { t } = useTranslation();
  const classes = useStyles();
  const isLeader = context.isLeader;
  const [isAnswerWriting, setIsAnswerWriting] = useState(false);
  const [storedSelectedAnswer, setStoredSelectedAnswer] = useState(0);

  const minResolution = useMediaQuery('(max-width:960px)');
  const [answerText, setValue] = React.useState<string>('');

  useEffect(() => {
    if (activeStep.step !== context.gameDef.activeStep.step) {
      setActiveStep(context.gameDef.activeStep);
      setStoredSelectedAnswer(0);
    }
  }, [context.gameDef.activeStep]);

  useEffect(() => {
    setActiveStep(context.gameDef.activeStep);
  }, [context.gameDef.activeStep]);

  // If the leader changes, the previous leader's local state must get updated to database value
  useEffect(() => {
    if (!isLeader && activeStep?.stepData?.answerPlainText !== answerText) {
      setValue(activeStep?.stepData?.answerPlainText || '');
    }
  }, [isLeader, activeStep, answerText]);

  useEffect(() => {
    if (!isLeader && storedSelectedAnswer) {
      setStoredSelectedAnswer(0);
    }
  }, [isLeader]);

  const updateAnswerPlainText = useCallback(
    debounce(async (value: string) => {
      if (isLeader) {
        updateFirebaseField(
          context.roomId,
          {
            'activeStep.stepData.answerPlainText': value,
          },
          false,
        );
      }
    }, 300),
    [isLeader, context.roomId],
  );

  const handleOpenEndedChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isLeader) {
        setValue(event.target.value);
        updateAnswerPlainText(event.target.value);
      }
    },
    [isLeader, context.roomId],
  );

  function selectAnswer(index: number) {
    setIsAnswerWriting(true);
    context.setCurrentGameStep(
      activeStep.gameState,
      activeStep.loop,
      activeStep.step,
      {
        selected: index,
        disabled: [...disabled, index],
      },
      'answer',
    );
  }

  const selectedAnswer = (index: number) => {
    if (isLeader && !isAnswerWriting && !selected) {
      selectAnswer(index);
    }
  };

  const submitAnswer = () => selectedAnswer(storedSelectedAnswer);

  const selectOption = (index: number) => () => {
    setStoredSelectedAnswer(index);
  };

  useEffect(() => {
    // this workaround is needed because an answer can be written some faster than selected was changed
    // and in this case user can choose another answer without recording previous
    if (isAnswerWriting && selected) {
      setIsAnswerWriting(false);
    }
  }, [isAnswerWriting, selected]);

  const saveOpenEndedAnswer = useCallback(
    async (answerText: string, question: QuizQuestion) => {
      setIsAnswerWriting(true);
      await context.gameService.recordOpenAnswer(question, answerText);
      setValue('');
      if (isLeader) {
        await updateFirebaseField(context.roomId, {
          'activeStep.stepData.answerPlainText': '',
        });
      }
      callback(() => {
        setIsAnswerWriting(false);
        setValue('');
      });
    },
    [setValue, callback, context.gameService.recordOpenAnswer],
  );

  function renderAllAnswers() {
    return (
      <>
        {quizQuestion?.answers?.map((answer, index) => {
          const isDisabled = disabled.indexOf(index + 1) > -1;
          return (
            <QuizOption
              key={answer?.fields?.answerPlainText}
              styles={{ flexBasis: '100%' }}
              selected={storedSelectedAnswer === index + 1}
              disabled={isDisabled}
              isLeader={isLeader}
              callback={selectOption(index + 1)}
              testId={`quiz-option-${index + 1}`}
            >
              <div>{answer?.fields?.answerPlainText}</div>
            </QuizOption>
          );
        })}
        {isLeader && (
          <div className={classes.submitButtonContainer} data-testid="submit-answer-button">
            {isAnswerWriting ? (
              <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
            ) : (
              <Button disabled={!storedSelectedAnswer} onClick={submitAnswer}>
                {t('gameplay:quiz:submit')}
              </Button>
            )}
          </div>
        )}
      </>
    );
  }

  const recordAnswer = (answer: QuizAnswer, question: QuizQuestion) => {
    context.gameService.recordAnswer(question, answer).then(() => {
      if (!answer.fields?.correctAnswer) {
        context.gameService.recordPenalty(PenaltyType.QUESTION).then(() => {
          selectAnswer(0);
          setStoredSelectedAnswer(0);
        });
      } else {
        callback();
      }
    });
  };

  function renderChoice() {
    const choice = quizQuestion?.answers && quizQuestion?.answers[selected - 1];

    return (
      choice && (
        <AnswerResult
          choice={choice}
          isLeader={isLeader}
          callback={recordAnswer}
          quizQuestion={quizQuestion}
          isAnswerWriting={isAnswerWriting}
        />
      )
    );
  }

  return (
    <ModalContainer
      containerStyles={{ width: minResolution ? 'auto' : 'calc(100% - 50px)' }}
      bodyStyles={{ padding: minResolution ? '5px 10px' : '1em 1.5em' }}
    >
      <div className={classes.container}>
        <div>
          <div className={classes.title} data-testid="quiz-question-title">
            {quizQuestion.title || t('gameplay:quiz:title')}
          </div>
          {quizQuestion.questionType !== QuestionType.OPEN_ENDED && !hideCountdownTimer && (
            <div className={classes.subtitle} data-testid="quiz-question-subtitle">
              {t('gameplay:quiz:subtitle')}
            </div>
          )}
        </div>
        <div className={classes.question} data-testid="quiz-question-text">
          {quizQuestion.questionPlainText}
        </div>
        {quizQuestion.questionType === QuestionType.OPEN_ENDED ? (
          <div className={classes.answerContainer} style={{ justifyContent: 'center' }}>
            <QuizTextArea
              handleOpenEndedChange={handleOpenEndedChange}
              answerText={answerText || activeStep?.stepData?.answerPlainText}
              onSubmit={saveOpenEndedAnswer.bind(null, answerText, quizQuestion)}
              isLeader={isLeader}
              loading={isAnswerWriting}
            />
          </div>
        ) : (
          <div className={classes.answerContainer}>{!selected ? renderAllAnswers() : renderChoice()}</div>
        )}
      </div>
    </ModalContainer>
  );
}

const AnswerResult = ({
  choice,
  isLeader,
  callback,
  quizQuestion,
  isAnswerWriting,
}: {
  choice: any;
  isLeader: boolean;
  callback: Function;
  quizQuestion: QuizQuestion;
  isAnswerWriting: boolean;
}) => {
  const { t } = useTranslation();
  const [isCheckingAnswer, setIsCheckingAnswer] = useState(false);
  const classes = useStyles();

  const isCorrect = choice?.fields.correctAnswer;

  function checkAnswer(answer: QuizAnswer, question: QuizQuestion) {
    setIsCheckingAnswer(true);
    callback(answer, question);
  }

  return (
    <div className={classes.answerResult}>
      <QuizOption
        styles={{ flexBasis: '100%' }}
        selected={true}
        disabled={false}
        isLeader={false}
        testId="quiz-option-answer"
      >
        <div>{choice.fields.answerPlainText}</div>
      </QuizOption>
      <div className={classes.feedback} data-testid="quiz-view-feedback">
        <div
          className={clsx(classes.answer, { [classes.correctAnswer]: choice.fields.correctAnswer })}
          data-testid="quiz-view-answer"
        >
          {isCorrect ? t('gameplay:quiz:correct') : t('gameplay:quiz:incorrect')}
        </div>
        <div className={classes.nudge} data-testid="quiz-view-nudge">
          {choice.fields.nudge}
        </div>
      </div>
      {isLeader && (
        <div className={classes.buttonContainer} data-testid="quiz-view-button">
          {isCheckingAnswer || isAnswerWriting ? (
            <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
          ) : (
            <Button onClick={checkAnswer.bind(null, choice, quizQuestion)}>
              {isCorrect ? t('gameplay:quiz:continue') : t('gameplay:quiz:try-again')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
