import React, { useCallback } from 'react';

import { styled } from '@material-ui/core/styles';

const Wrapper = styled('button')(() => ({
  height: '100%',
  minWidth: '95px',
  border: 'none',
  transition: 'none',
  paddingTop: '4px',
  '&:hover': {
    backgroundColor: '#0f202c',
  },
  '&:focus': {
    boxShadow: 'none',
  },
}));

const Text = styled('div')(() => ({
  marginTop: '8px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'lighter',
  color: '#fff',
}));

const Icon = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));

export default function NavbarButton({
  icon,
  iconComponent,
  onClick,
  alt,
  disabled,
  children,
  id,
  className,
  testId,
}: {
  icon?: any;
  iconComponent?: any;
  onClick: () => void;
  alt?: string;
  disabled?: boolean;
  children: string;
  id?: string;
  className?: string;
  testId?: string;
}) {
  const clickHandler = useCallback(() => {
    if (disabled) return;
    onClick();
  }, [onClick, disabled]);

  return (
    <Wrapper onClick={clickHandler} id={id} className={className} data-testid={testId || 'navbar-button'}>
      {iconComponent || <Icon src={icon} alt={alt || 'Control button'} />}
      <Text>{children}</Text>
    </Wrapper>
  );
}
