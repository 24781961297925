import { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';

import { baseStyles, calculatePosition } from './helpers';

export default function useContextMenu() {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState<[number, number]>([0, 0]);
  const ctxMenuRef = useRef(null);

  const triggerRef = useRef(null!);

  const [style, setStyles] = useState({});

  const hide = () => setVisible(false);

  const bindWindowEvents = useCallback(() => {
    window.addEventListener('resize', hide);
    window.addEventListener('click', hide);
    window.addEventListener('scroll', hide);
  }, []);

  const unBindWindowEvents = useCallback(() => {
    window.removeEventListener('resize', hide);
    window.removeEventListener('click', hide);
    window.removeEventListener('scroll', hide);
  }, []);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    if (ctxMenuRef.current?.contains(event.target as Node)) return;
    if (!triggerRef?.current) return;

    const clickX = event.clientX;
    const clickY = event.clientY;
    setVisible(true);
    setCoords([clickX, clickY]);
  };

  useEffect(() => {
    if (visible) {
      bindWindowEvents();
    } else {
      unBindWindowEvents();
    }
    return () => {
      unBindWindowEvents();
    };
  }, [bindWindowEvents, unBindWindowEvents, visible]);

  useLayoutEffect(() => {
    if (!ctxMenuRef.current) return;
    if (visible) {
      const { top, left } = calculatePosition(triggerRef.current, ctxMenuRef.current, coords);
      setStyles((prevStyles) => ({
        ...prevStyles,
        top: `${top}px`,
        left: `${left}px`,
        opacity: 1,
        pointerEvents: 'auto',
      }));
    } else {
      setStyles(baseStyles);
    }
  }, [coords, ctxMenuRef, visible]);

  return {
    bindTrigger: {
      ref: triggerRef,
      onContextMenu: handleContextMenu,
    },
    bindMenu: {
      ref: ctxMenuRef,
      style,
    },
  };
}
