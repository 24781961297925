import React, {
  useEffect,
  useState,
  FormEvent,
  ChangeEvent,
  FocusEvent,
  ReactNode,
  MouseEvent,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useAppState } from 'state';

import { apiClient } from 'services/api';

import { setAudioPreference, setVideoPreference } from 'utils';

import { USER_NAME_SEPARATOR } from '_constants';

import { useRoomState, useVideoContext } from 'hooks';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import ModalContainer from 'components/Modal/ModalContainer';

const useStyles = makeStyles((theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      background: '#002638',
      margin: '0 auto',
      width: 'auto',
      height: 'auto',
      color: '#F2F2F2',
      boxShadow: 'var(--green-box-shadow)',
      borderRadius: '10px',
      cursor: 'default',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: '50px',
        justifyContent: 'flex-start',
      },
      '&.invisible': {
        display: 'none',
      },
    },
    modalBody: {
      padding: '32px',
    },
    form: {
      width: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    inputContainer: {
      marginBottom: 32,
    },
    inputTitle: {
      color: '#f2f2f2',
      fontSize: '1.125rem',
      lineHeight: 2,
    },
    inputRequirement: {
      marginBottom: 8,
      color: '#A2ACB3',
      fontSize: '.75rem',
      lineHeight: 1.4,
    },
    inputLabel: {
      display: 'flex',
      alignItems: 'stretch',
      height: 64,
      background: '#173042',
      border: '1px solid #A2ACB3',
      borderRadius: 4,
      '&:before': {
        content: "''",
        display: 'block',
        width: 56,
        height: '100%',
        backgroundColor: '#139ed8',
        borderRight: '1px solid #A2ACB3',
      },
      '&:hover, &:focus-within': {
        borderColor: '#85E5FF',
      },
      '&.error': {
        borderColor: '#FF9380',

        '&:before': {
          backgroundColor: '#FF9380',
          borderColor: '#FF9380',
        },
      },
    },
    input: {
      flexGrow: 1,
      padding: '0 16px',
      border: 'none',
      background: 'transparent',
      color: '#fff',
      fontSize: '1.125rem',
      lineHeight: '25px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::placeholder': {
        color: '#828282',
      },
    },
    inputError: {
      marginTop: 8,
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#FF9380',
      fontSize: '.75rem',
      lineHeight: '16px',
    },
    button: {
      position: 'relative',
      padding: '10px 24px',
      background: '#2D9CDB',
      border: 'none',
      borderRadius: 30,
      color: '#FFFFFF',
      fontSize: '0.875rem',
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#4EB3D4',
      },
      '&[disabled]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
    buttonLoader: {
      position: 'absolute',
      top: 'calc(50% - 10px)',
      left: 'calc(50% - 10px)',
    },
    submitButton: {
      marginTop: 40,
      marginLeft: 'auto',
    },
  }),
);

const initialValues = { userName: '', teamName: '' };

const USER_NAME_MIN_CHARACTERS = 1;
const NAME_MAX_CHARACTERS = 20;
const TEAM_NAME_MIN_CHARACTERS = 1;

type SignUpModalProps = {
  roomName: string;
  handleSubmit: (values: typeof initialValues) => void;
  isJoinModal: boolean;
};

type EventType = {
  target: {
    name: string;
    value: string;
  };
};

// TODO: move to separate file when UI library will be ready
const InputComponent = ({
  title,
  required,
  requirement,
  error,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  labelTestId = 'input-label',
}: {
  title?: string;
  required?: boolean;
  requirement?: string;
  error?: string;
  placeholder?: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  labelTestId?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.inputContainer}>
      {title && <div className={classes.inputTitle} data-testid={labelTestId}>{`${title}${required ? '*' : ''}`}</div>}
      {requirement && <div className={classes.inputRequirement}>{requirement}</div>}
      <label className={clsx(classes.inputLabel, { error })}>
        <input
          className={classes.input}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </label>
      {error && <div className={classes.inputError}>{error}</div>}
    </div>
  );
};

// TODO: move to separate file when UI library will be ready
const ButtonComponent = ({
  children,
  loading,
  onClick,
  type = 'button',
  disabled,
  testId,
  classes: additionalClasses,
}: {
  children: ReactNode | string;
  loading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  testId: string;
  classes?: string;
}) => {
  const classes = useStyles();

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={testId || 'button'}
      className={clsx(classes.button, additionalClasses)}
    >
      {children}
      {loading && <CircularProgress className={classes.buttonLoader} color="primary" size={20} />}
    </button>
  );
};

const SignUpModal: React.FC<SignUpModalProps> = ({ roomName, handleSubmit, isJoinModal }) => {
  const { t } = useTranslation();
  const roomId = useLocation().pathname.split('/')[1];
  const classes = useStyles();
  const roomState = useRoomState();
  const context = useContext(GlobalContext);
  const { isSessionHosted } = context;
  const { isConnecting, isAcquiringLocalTracks } = useVideoContext();
  const { isFetching } = useAppState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAudioPreference(context.roomSessionId, 'true');
    setVideoPreference(context.roomSessionId, 'true');
  }, []);

  useEffect(() => {
    if (isJoinModal) setIsLoading(false);
  }, [isJoinModal]);

  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');

  const validateUserName = (value: string) => {
    const trimedUserName = value.trim();

    if (!value) {
      setUserNameError(t('sign-up:username-required-error'));
      return;
    }

    if (trimedUserName.length < USER_NAME_MIN_CHARACTERS + 1) {
      setUserNameError(t('sign-up:username-length-error', { limit: USER_NAME_MIN_CHARACTERS }));
      return;
    }

    if (trimedUserName.includes(USER_NAME_SEPARATOR)) {
      setUserNameError(t('sign-up:username-includes-error', { phrase: USER_NAME_SEPARATOR }));
      return;
    }

    setUserNameError('');
  };

  const changeUserName = ({ target: { value } }: EventType) => {
    const newValue = value.length > NAME_MAX_CHARACTERS ? userName : value;

    setUserName(newValue);
    validateUserName(newValue);
  };

  const handleUserNameBlur = ({ target: { value } }: EventType) => {
    const trimedUserName = value.trim();

    if (trimedUserName !== value) setUserName(trimedUserName);

    validateUserName(trimedUserName);
  };

  const [teamName, setTeamName] = useState('');
  const [teamNameError, setTeamNameError] = useState('');

  const validateTeamName = (value: string) => {
    const trimedTeamName = value.trim();

    if (trimedTeamName && trimedTeamName.length < TEAM_NAME_MIN_CHARACTERS + 1) {
      setTeamNameError(t('sign-up:team-length-error', { limit: TEAM_NAME_MIN_CHARACTERS }));
      return;
    }

    setTeamNameError('');
  };

  const changeTeamName = ({ target: { value } }: EventType) => {
    const newValue = value.length > NAME_MAX_CHARACTERS ? teamName : value;

    setTeamName(newValue);
    validateTeamName(newValue);
  };

  const handleTeamNameBlur = ({ target: { value } }: EventType) => {
    const trimedTeamName = value.trim();

    if (trimedTeamName !== value) setTeamName(trimedTeamName);

    validateTeamName(trimedTeamName);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (teamName) {
      try {
        const { available } = await apiClient.validateTeamName(roomId, teamName);

        if (!available) return setTeamNameError(t('sign-up:team-name-taken-error', { name: teamName }));
      } catch (e) {
        console.error(e);
        return setTeamNameError(t('sign-up:team-name-invalid-error'));
      } finally {
        setIsLoading(false);
      }
    }

    handleSubmit({ userName, teamName });
  };

  const isSubmitDisabled = Boolean(
    isAcquiringLocalTracks ||
      isConnecting ||
      !roomName ||
      isFetching ||
      userNameError ||
      teamNameError ||
      (!isSessionHosted && !userName),
  );

  return (
    <ModalContainer
      title={t('sign-up:your-info')}
      titleTestId="sign-up-modal-title"
      classes={{
        modalContainer: clsx(classes.modalContainer, { invisible: isJoinModal }),
        body: classes.modalBody,
      }}
    >
      {roomState === 'disconnected' && (
        <form className={classes.form} onSubmit={submitHandler}>
          {!isSessionHosted && (
            <InputComponent
              title={t('sign-up:your-player-name')}
              required
              error={userNameError}
              requirement={t('sign-up:team-name-requirement', { limit: NAME_MAX_CHARACTERS })}
              placeholder={t('sign-up:enter-display-name')}
              name="userName"
              value={userName}
              onChange={changeUserName}
              onBlur={handleUserNameBlur}
              labelTestId="your-display-name-label" // was before, could be useless
            />
          )}
          <InputComponent
            title={t('sign-up:suggest-team-name')}
            error={teamNameError}
            requirement={t('sign-up:team-name-requirement', { limit: NAME_MAX_CHARACTERS })}
            placeholder={t('sign-up:suggested-team-name')}
            name="teamName"
            value={teamName}
            onChange={changeTeamName}
            onBlur={handleTeamNameBlur}
            labelTestId="suggest-team-name-label"
          />
          <ButtonComponent
            type="submit"
            testId="continue-button"
            disabled={isSubmitDisabled}
            loading={isConnecting || isFetching || isLoading}
            classes={classes.submitButton}
          >
            {t('modals:continue')}
          </ButtonComponent>
        </form>
      )}
    </ModalContainer>
  );
};

export default SignUpModal;
