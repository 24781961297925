type TestCase = {
  startTime: number;
  endTime: number;
};

const DOWNLOAD_TIMEOUT = 50000;

export async function checkNetworkSpeed() {
  const mediaLink = 'https://cdn.cyberescape.livingsecurity.com/image/GameBG.jpg';
  const sizeInBytes = 187000;

  const calculateSpeed = (testCases: number[], sizeInBytes: number) => {
    const loadedBits = sizeInBytes * 8;
    const averageDuration = testCases.reduce((acc, time) => acc + time, 0) / testCases.length / 1000;
    const speedInMbps = loadedBits / averageDuration / 1000 / 1000;

    return speedInMbps;
  };

  const startDownloadTest = async () => {
    const runTest = (): Promise<TestCase> => {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const currentMediaLink = `${mediaLink}?date=${new Date()}`;
        let timeout = false;
        const downloadImgSrc = new Image();

        downloadImgSrc.onload = () => {
          if (!timeout) {
            resolve({ startTime, endTime: new Date().getTime() });
          }
        };
        downloadImgSrc.src = currentMediaLink;

        setTimeout(() => {
          timeout = true;
          reject(new Error('Download timeout'));
        }, DOWNLOAD_TIMEOUT);
      });
    };

    const testCases = [];
    let errorCaught = false;
    try {
      const { startTime, endTime } = await runTest();
      testCases.push(endTime - startTime);
    } catch (e) {
      errorCaught = true;
    }
    const speedInMbps = calculateSpeed(testCases, sizeInBytes);

    return testCases.length ? speedInMbps > 0.5 : false;
  };
  try {
    const res = startDownloadTest();
    return res;
  } catch (e) {
    return false;
  }
}
