import React from 'react';
import { Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { testStatusesEnum } from '../../JoinModal';
import StatusBlock from '../StatusBlock';

const DatabaseStatusBlock = ({ status }: { status: testStatusesEnum }) => {
  const { t } = useTranslation();
  const title = {
    [testStatusesEnum.SUCCESS]: t('join-status:firebase-success-title'),
    [testStatusesEnum.PROGRESS]: t('join-status:firebase-progress-title'),
    [testStatusesEnum.FAIL]: t('join-status:firebase-failed-title'),
  };
  const failDescription = (
    <Trans
      i18nKey="join-status:firebase-failed-description"
      components={[
        <Link
          href="https://www.livingsecurity.com/support/teams-sites-to-whitelist"
          target="_blank"
          rel="noreferrer noopener nofollow"
          key={1}
          style={{
            color: '#89BBFF',
          }}
        />,
      ]}
    />
  );
  const description = {
    [testStatusesEnum.SUCCESS]: t('join-status:firebase-success-description'),
    [testStatusesEnum.PROGRESS]: t('join-status:firebase-progress-description'),
    [testStatusesEnum.FAIL]: failDescription,
  };

  return <StatusBlock title={title[status]} description={description[status]} status={status} testId="database" />;
};

export default DatabaseStatusBlock;
