import React from 'react';
import { styled } from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';

const BandwidthWarningContainer = styled('div')({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Warning = styled('h3')({
  textAlign: 'center',
  margin: '0.6em 0',
  color: '#F2F2F2',
  fontSize: '.75rem',
});

export default function BandwidthWarning() {
  const { t } = useTranslation();

  return (
    <BandwidthWarningContainer>
      <div>
        <ErrorOutlineIcon fontSize="medium" />
      </div>
      <Warning>{t('warnings:bandwidth')}</Warning>
    </BandwidthWarningContainer>
  );
}
