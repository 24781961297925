import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getJWT } from '../token';
import { getFieldFromAuthJWT, getURLParamsAsObject } from '../../utils';
import { authenticateFirebase } from '../firestore';
import { useAppState } from '../../state';
import { apiClient } from '../api';

import { Region } from 'types';

import { useFirebaseConnection, useCheckAuth0TokenStatus, useContentfulClient } from 'hooks';
import GameService from '../../containers/GameplayPage/services/GameService';
import { GameDefinition } from '../../containers/GameplayPage/services/types';
import { createGameDefinition, fetchRoomDetails, fetchContentfulData } from '../../containers/GameplayPage/utils';
import { ErrorCodesEnum } from '../../components/ErrorDialog/enhanceMessage';

const { language } = getURLParamsAsObject();

export enum ServicesEnum {
  RoomDetails,
  LaunchDarkly,
  Database,
  GameService,
  Contentful,
}

export default function useInitializationService() {
  const { t } = useTranslation();
  const { setError } = useAppState();

  const [statuses, setStatuses] = useState({
    [ServicesEnum.RoomDetails]: false, // +
    [ServicesEnum.LaunchDarkly]: false, // +
    [ServicesEnum.Database]: false, // +
    [ServicesEnum.GameService]: false, // +
    [ServicesEnum.Contentful]: false, // +
  });

  const setServiceInitialized = useCallback((name: ServicesEnum) => {
    setStatuses((prev) => ({
      ...prev,
      [name]: true,
    }));
  }, []);

  // ----------------------Database(firebase)-----------------------
  const [ldUser, setLdUser] = useState<any>();
  const [contentfulData, setContentfulData] = useState<any>({});
  const [gameDefinition, setGameDefinitionData] = useState<GameDefinition>();
  const [isGameDefLoading, setIsGameDefLoading] = useState(true);

  const updateGameDefinition = useCallback(
    (newGameDefinition: GameDefinition, isLoading: boolean) => {
      if (
        newGameDefinition === undefined ||
        (newGameDefinition?.lastUpdated && gameDefinition?.lastUpdated === newGameDefinition?.lastUpdated)
      ) {
        return;
      }
      if (isLoading !== isGameDefLoading) {
        setIsGameDefLoading(isLoading);
      }
      if (!isLoading) {
        setGameDefinitionData(newGameDefinition);
      }
    },
    [gameDefinition, isGameDefLoading],
  );
  // ----------------------Database(firebase)-----------------------

  // ----------------------RoomDetails-----------------------
  const location = useLocation<{ from: Location }>();
  const roomId = location.pathname.split('/')[1];
  const [roomDetails, setRoomDetails] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const roomDetails = await fetchRoomDetails(roomId);
        setRoomDetails(roomDetails);
        setServiceInitialized(ServicesEnum.RoomDetails);
      } catch {
        setError({ message: t('warnings:something-wrong'), code: ErrorCodesEnum.FAILED_TO_FETCH_ROOM_DETAILS });
      }
    })();
  }, [roomId]);
  // ----------------------RoomDetails-----------------------

  // ----------------------GameService-----------------------
  const [gameService, initGameService] = useState<GameService | null>(null);
  const [gameServiceInitStarted, setGameServiceInitStarted] = useState(false);

  useEffect(() => {
    if (
      gameDefinition &&
      roomDetails &&
      !gameService &&
      !statuses[ServicesEnum.GameService] &&
      !gameServiceInitStarted
    ) {
      setGameServiceInitStarted(true);

      initGameService(new GameService(gameDefinition!, roomDetails, roomId));
      setServiceInitialized(ServicesEnum.GameService);
    } else if (gameDefinition && gameService) {
      gameService.setGameDefinition(gameDefinition);
    }
  }, [gameServiceInitStarted, roomDetails, gameDefinition, gameService, roomId, statuses]);
  // ----------------------GameService-----------------------

  // ----------------------LaunchDarkly-----------------------
  const [launchDarklyError, setLaunchDarklyError] = useState<boolean>(false);
  const ldClient = useLDClient();

  useEffect(() => {
    (async () => {
      const token = getJWT();

      if (!token || !ldClient || !roomDetails || statuses[ServicesEnum.LaunchDarkly]) return;

      const userEmail = getFieldFromAuthJWT(token, 'email');

      const user = ldClient.getUser();

      await ldClient.identify({
        key: user?.key,
        anonymous: false,
        email: userEmail,
        name: `${roomDetails.id}:${userEmail}`,
        custom: {
          tenantId: `${roomDetails.companyId}`,
          sessionStart: roomDetails.sessionStart,
          sessionId: `${roomDetails.id}`,
          sessionState: `${roomDetails.sessionState}`,
          contentfulId: roomDetails.session.contentful_src_id,
          campaignId: roomDetails.session.campaign,
          language: roomDetails.session.language,
          isSessionHosted: roomDetails.session.is_hosted,
        },
      });

      setLdUser(ldClient.getUser());
      setServiceInitialized(ServicesEnum.LaunchDarkly);
      window.ldClient = ldClient;
      window.ldClient.isSessionHosted = roomDetails.session.is_hosted;
    })();
  }, [ldClient, roomDetails]); // TODO: check dependency array, if have different in GameplayProvider

  // Log if launchDarkly blocked
  useEffect(() => {
    function onLaunchDarklyError() {
      setLaunchDarklyError(true);

      if (roomDetails) {
        console.error(`LaunchDarkly error in company ID ${roomDetails.companyId} in room ID ${roomDetails.id}`);
      }
    }

    if (ldClient) {
      ldClient.on('error', onLaunchDarklyError);
    }
    return () => ldClient?.off('error', onLaunchDarklyError);
  }, [ldClient, roomDetails]);
  // ----------------------LaunchDarkly-----------------------

  // ----------------------Accessibe-----------------------
  const hideAccessibeWidget = window.ldClient?.variation('hide-accessibe-widget');
  useEffect(() => {
    if (hideAccessibeWidget) {
      /* eslint-disable no-unused-expressions */
      window.acsbJS?.destroy();
      window.document.getElementById('acsb-trigger')?.remove();
      window.document.getElementById('acsb-widget')?.remove();
    }
  }, [hideAccessibeWidget]);
  // ----------------------Accessibe-----------------------

  // ----------------------Hubspot-----------------------
  const hideHubspotWidget = window.ldClient?.variation('hide-hubspot-widget');
  useEffect(() => {
    if (hideHubspotWidget) {
      /* eslint-disable no-unused-expressions */
      window.document.getElementById('hubspot-messages-iframe-container')?.remove();
      window.document.getElementById('hubspot-messages-loader')?.remove();
      window.document.getElementById('hs-script-loader')?.remove();
    }
  }, [hideHubspotWidget]);
  // ----------------------Hubspot-----------------------

  // ----------------------Database(firebase)-----------------------
  const [firebaseConfig, setFirebaseConfig] = useState({});
  const checkAuth0TokenStatus = useCheckAuth0TokenStatus();
  const setDatabaseInitialized = async (region: Region) => {
    const fetchFirebaseToken = async (region: Region) => {
      try {
        const response = await apiClient.getFirebaseToken(region);
        if (response.token) {
          authenticateFirebase(response.token)
            .then(() => {
              setServiceInitialized(ServicesEnum.Database);
            })
            .catch((error: Error) => {
              setError({ message: error.message, code: ErrorCodesEnum.FAILED_FIREBASE_AUTH });
              console.error(error);
            });
        } else {
          throw new Error(response);
        }
      } catch (e) {
        console.error(e);
        throw new Error(e);
      }
    };

    try {
      checkAuth0TokenStatus()
        .then(() => {
          fetchFirebaseToken(region).catch((error: Error) => {
            setError({ message: error.message, code: ErrorCodesEnum.FAILED_FIREBASE_TOKEN });
            console.error(error);
          });
        })
        .catch((e) => {
          console.error(e.message);
        });
    } catch (error) {
      setError({ message: error.message, code: ErrorCodesEnum.FAILED_FIREBASE_TOKEN });
      console.error(error);
    }
  };

  const firebaseSettings = useFirebaseConnection({
    newUser: ldUser,
    launchDarklyError,
    callback: setDatabaseInitialized,
    roomDetails,
    setFirebaseConfig,
  });
  // ----------------------Database(firebase)-----------------------

  // ----------------------Contentful-----------------------
  const contentfulClient = useContentfulClient();

  useEffect(() => {
    (async () => {
      if (
        contentfulClient &&
        !isGameDefLoading &&
        !statuses[ServicesEnum.Contentful] &&
        statuses[ServicesEnum.Database]
      ) {
        if (!gameDefinition) {
          await createGameDefinition(roomId, language);
        }
        setServiceInitialized(ServicesEnum.Contentful);
      }
    })();
  }, [contentfulClient, roomId, gameDefinition, isGameDefLoading, statuses]);

  useEffect(() => {
    if (!roomDetails?.session.contentful_src_id) return;
    fetchContentfulData(
      contentfulClient,
      roomDetails.session.contentful_src_id,
      roomDetails.session.language,
      (data) => {
        setContentfulData(data);
      },
    );
  }, [contentfulClient, roomDetails]);
  // ----------------------Contentful-----------------------

  const isAllServicesInitialized = useMemo(() => {
    return Object.values(statuses).every((service) => service);
  }, [statuses]);

  return {
    updateGameDefinition,
    isAllServicesInitialized,
    // !!! REMEMBER - you need to use setServiceInitialized after your service initialization
    // You need to decide where to use it - inside this service (if possible initialize it here) or outside
    setServiceInitialized,
    firebaseSettings,
    firebaseConfig,
    gameService,
    contentfulClient,
    contentfulData,
  };
}
