import { useCallback, useContext, useEffect } from 'react';
import { checkNetworkSpeed } from 'utils/connectionSpeed';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';
import { useConnectionStatus, useVideoContext } from 'hooks';
import { ConnectionNotificationTypeEnum, ConnectionProblemTypeEnum } from 'hooks/useConnectionStatus/types';

export default function useNetworkSpeedStatus() {
  const { room } = useVideoContext();
  const context = useContext(GlobalContext);
  const localParticipant = room!.localParticipant;
  const {
    addConnectionStatusNotification,
    removeConnectionStatusNotification,
    connectionProblems,
  } = useConnectionStatus();

  const setNetworkProblem = useCallback(
    (notificationType: ConnectionNotificationTypeEnum) => {
      addConnectionStatusNotification(ConnectionProblemTypeEnum.NETWORK, notificationType);
    },
    [addConnectionStatusNotification],
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      const isNetworkSpeedGood = await checkNetworkSpeed();
      const isPoorConnection = Number(localParticipant.networkQualityLevel) < 3 || !isNetworkSpeedGood;

      if (!navigator.onLine) {
        setNetworkProblem(ConnectionNotificationTypeEnum.FAIL);
      } else if (isPoorConnection) {
        setNetworkProblem(ConnectionNotificationTypeEnum.WARNING);
      } else {
        removeConnectionStatusNotification(ConnectionProblemTypeEnum.NETWORK);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [setNetworkProblem, removeConnectionStatusNotification]);

  useEffect(() => {
    const networkProblem = connectionProblems.find(
      (problem) => problem.problemType === ConnectionProblemTypeEnum.NETWORK,
    );
    if (
      (networkProblem && networkProblem.notificationType === ConnectionNotificationTypeEnum.WARNING) ||
      (!networkProblem && !(!navigator.onLine || Number(localParticipant.networkQualityLevel) < 3))
    ) {
      context.setConnectionStatus(networkProblem?.notificationType || ConnectionNotificationTypeEnum.WARNING);
    }
  }, [connectionProblems]);
}
