import { useEffect, useState } from 'react';
// @ts-ignore
import { useWindowResize } from '@livingsecurity/cyberblocks';

export const useFontSize = (size: number = 12) => {
  const { pixelRatio, os } = useWindowResize();
  const [fontSize, setFontSize] = useState(size);

  useEffect(() => {
    if (os === 'Windows') {
      setFontSize(size / (pixelRatio * 1.1));
    } else if (pixelRatio > 3) {
      setFontSize(size / (pixelRatio - 1.5));
    }
  }, [pixelRatio, os, size]);

  return fontSize;
};
