import Cookies from 'js-cookie';

enum TokenStorageKeysEnum {
  jwt = 'Authorization',
  roomId = 'roomId',
}

export const AUTH_NAMESPACE = 'http://teams_mf';

export function setJWT(token: string) {
  setLocalStorage(TokenStorageKeysEnum.jwt, token);
}

export function setRoomId(id: string) {
  sessionStorage.setItem(TokenStorageKeysEnum.roomId, id);
}

export function getJWT() {
  return getLocalStorage(TokenStorageKeysEnum.jwt);
}

export function getRoomId() {
  return sessionStorage.getItem(TokenStorageKeysEnum.roomId);
}

export function removeJWT() {
  return removeLocalStorage(TokenStorageKeysEnum.jwt);
}

export function removeAuthData() {
  Object.values(TokenStorageKeysEnum).forEach((item) => {
    sessionStorage.removeItem(item);
  });
}

export function parseJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

// Since our domains have a subdomain, we need to remove it
const getBaseHostname = () => {
  const urlParts = location.hostname.split('.');
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
  Cookies.set(key, value, {
    domain: location.hostname === 'localhost' ? location.hostname : `.${getBaseHostname()}`,
    sameSite: 'Lax',
    expires: 7,
  });
}

export function getLocalStorage(key: string, isEmbedded?: boolean) {
  if (isEmbedded) {
    return Cookies.get(key);
  }
  return Cookies.get(key) || localStorage.getItem(key);
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
  Cookies.remove(key);
}
