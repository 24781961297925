import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { size } from 'lodash';
// @ts-ignore
import { ErrorWrapper, VideoPlayer } from '@livingsecurity/cyberblocks';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../GameplayProvider';
import { useAppState } from 'state';
import { makeStyles } from '@material-ui/core/styles';

// Shared video container styles
export const videoContainer = {
  display: 'flex',
  width: '100%',
  height: '100%',
  minWidth: '300px',
  margin: 'auto',
};

const useStyles = makeStyles({
  wrapper: {
    background: '#121212',
    border: '2px solid #A2ACB3',
    borderRadius: 10,
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 32px)',
    margin: 'auto',
    position: 'relative',
    padding: '20px 35px',
  },
  video: {
    height: '100% !important',
    paddingTop: '0 !important',
    boxShadow: 'var(--green-box-shadow)',
  },
});

export default function TeamsVideoPlayer({
  id,
  publicId,
  width = 1280,
  disableSeeking = false,
  captions,
  language,
  playerOptions,
  percentCallback,
  onResetCallback,
  afterText,
  showSkip,
  skipText,
}: {
  id?: string;
  publicId: string;
  disableSeeking?: boolean;
  width?: number;
  captions?: Object;
  language?: Object;
  playerOptions?: Object;
  percentCallback?: Function;
  onResetCallback?: Function;
  afterText?: string | React.ReactNode;
  showSkip?: boolean;
  skipText?: string;
}) {
  const classes = useStyles();
  const context = useContext(GlobalContext);
  const { activeSinkId } = useAppState();
  const videoEL = useRef<any>();
  const { gameDef } = context;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isDone, setDone] = useState(false);
  const [currentCaptions, setCurrentCaptions] = useState(captions); // This helps prevent the video from restarting on Firebase update

  useEffect(() => {
    // If back-to-back video steps, we need to trigger a reload so the new video appears
    toggleLoading();
  }, [publicId]);

  const toggleLoading = () => {
    setLoading(true);
    setDone(false);

    if (captions !== currentCaptions) {
      setCurrentCaptions(captions);
    }
    setTimeout(() => {
      setLoading(false);
      setDone(false);
    }, 500);
  };

  const onReset = useCallback(() => {
    if (onResetCallback) {
      onResetCallback();
    } else {
      toggleLoading();
    }
  }, [onResetCallback]);

  const onPercentChange = useCallback(
    (percent: number) => {
      if (percent === 100) {
        setDone(true);
      }

      percentCallback && percentCallback(percent);
    },
    [percentCallback],
  );

  useEffect(() => {
    if (videoEL?.current && !loading) {
      videoEL.current?.getInstance()?.videojs?.el_?.firstElementChild?.setSinkId?.(activeSinkId);
    }
  }, [videoEL?.current, loading, activeSinkId]);

  return (
    <div className={classes.wrapper}>
      {!isDone && !loading && (
        <ErrorWrapper errorComponent={() => <div style={{ color: '#F2F2F2' }}>{t('warnings:video-error')}</div>}>
          <VideoPlayer
            ref={videoEL}
            t={t}
            id={id || 'cld-video-player'}
            publicId={publicId}
            width={width}
            disableSeeking={disableSeeking}
            volume={0.75}
            playerOptions={{
              controls: !disableSeeking,
              fluid: true,
              hideContextMenu: false,
              showJumpControls: false,
              autoplay: true,
              muted: false,
              loop: false,
              playsinline: false,
              disablePictureInPicture: true,
              playedEventPercents: [0, 5, 10, 25, 50, 75, 90, 100],
              colors: { base: '#08152E', accent: 'rgba(94,206,252, .8)', text: '#fff' },
              logoOnclickUrl: 'https://livingsecurity.com',
              logoImageUrl: 'https://puzzles.cdn.livingsecurity.com/CEO/LS-Logo.png',
              ...playerOptions,
            }}
            captions={currentCaptions}
            language={language}
            onReset={onReset}
            onPercentChange={onPercentChange}
            showSkipButton={showSkip}
            skipText={skipText}
            wrapperStyles={{
              height: '100%',
            }}
            className={classes.video}
          />
        </ErrorWrapper>
      )}
      {isDone && size(gameDef?.players) > 1 && afterText}
    </div>
  );
}
