import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFontSize } from '../../utils/responsive';

type Props = {
  headerFont?: number;
  subtitleFont?: number;
  bodyFont?: number;
};

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      background: '#002638',
      margin: '0 auto',
      maxWidth: '757px',
      width: 'auto',
      height: 'auto',
      color: '#F2F2F2',
      boxShadow: 'var(--green-box-shadow)',
      borderRadius: '10px',
      cursor: 'default',
    },
    header: {
      width: '100%',
      background: '#2d9cdb',
      padding: '.5em 1em',
      fontSize: ({ headerFont }) => `${headerFont}px`,
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      boxSizing: 'border-box',
    },
    title: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    subtitle: {
      fontSize: ({ subtitleFont }) => `${subtitleFont}px`,
      fontWeight: 'lighter',
    },
    body: {
      width: '100%',
      position: 'relative',
      padding: '1.5em',
      fontSize: ({ bodyFont }) => `${bodyFont}px !important`,
      fontWeight: 'lighter',
    },
  }),
);

export default function (props: any) {
  const headerFont = useFontSize(22);
  const subtitleFont = useFontSize(props.subtitleFont || 13);
  const bodyFont = useFontSize(16);
  const classes = useStyles({ headerFont, subtitleFont, bodyFont });

  return (
    <div className={props.classes?.modalContainer || classes.modalContainer} style={props.containerStyles}>
      {!!(props.title || props.subtitle) && (
        <div className={classes.header}>
          <div className={classes.title} data-testid={props.titleTestId || 'modal-container-title'}>
            {props.title}
          </div>
          <div className={classes.subtitle}>{props.subtitle}</div>
        </div>
      )}
      <div className={`${classes.body} ${props.classes?.body || ''}`} style={props.bodyStyles}>
        {props.children}
      </div>
    </div>
  );
}
