import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    navbarHeight: number;
    videoBarMaxHeight: number;
    videoBarMaxHeightMedium: number;
    videoBarMaxHeightSmall: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    navbarHeight?: number;
    videoBarMaxHeight?: number;
    videoBarMaxHeightMedium?: number;
    videoBarMaxHeightSmall?: number;
  }
}

export default createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#F22F46',
    },
    background: {
      default: '#002638',
    },
  },
  sidebarWidth: 220,
  sidebarMobileHeight: 90,
  navbarHeight: 70,
  videoBarMaxHeight: 135,
  videoBarMaxHeightMedium: 115,
  videoBarMaxHeightSmall: 100,
});
