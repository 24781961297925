import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  top: {
    flexGrow: 0,
  },

  body: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
});
