import { useState, useCallback, useEffect } from 'react';
import { Auth0LockPasswordless } from 'auth0-lock';
import i18next from 'i18next';

export enum PasswordlessEventsEnum {
  AUTHENTICATED = 'authenticated',
  HASH_PARSED = 'hash_parsed',
}

type Email = string | null;

interface IPasswordlessLockConfig {
  pathname?: string;
  params?: Record<string, any>;
  email: Email;
}

const options = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  secret: process.env.REACT_APP_AUTH_SECRET,
};

const AUTH0_SETTINGS = {
  ...options,
  getSettings: (state?: string, email?: Email) => ({
    allowedConnections: ['email'],
    passwordlessMethod: 'code',
    closable: false,
    rememberLastLogin: false,
    languageDictionary: {
      title: i18next.t('auth:title'),
      passwordlessEmailInstructions: i18next.t('auth:body'),
      signUpTerms:
        "By submitting you agree to our <a href='https://www.livingsecurity.com/terms-of-service' style='font-weight: inherit' target='_new'>terms of service</a> and <a href='https://www.livingsecurity.com/data-protection' style='font-weight: inherit' target='_new'>privacy policy</a>.",
    },
    theme: {
      logo:
        'https://images.squarespace-cdn.com/content/v1/593044ab15cf7dbccaa2ed78/1553710850507-DJV1TBMP0VAWI1WNSSVT/ke17ZwdGBToddI8pDm48kAHOG77v-MnM_Ys_XLpVIiYUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcwuTvpRw4PKK3XopuSewHN3s_5tU1RytWcGWe55hP5ypZ3BaZfS4aZR_wCKxK3Ohh/living+security.png',
      primaryColor: '#4eb3d4',
    },
    ...(email && {
      prefill: {
        email,
      },
    }),
    auth: {
      responseType: 'token id_token',
      redirectUrl: `${window.location.origin}/redirect`,
      ...(state && {
        params: {
          state,
        },
      }),
    },
  }),
};

export default function usePasswordlessLock(defaultConfig?: boolean) {
  const [PasswordlessLock, setPasswordlessLock] = useState<Auth0LockPasswordlessStatic | null>(null!);

  const configurePasswordlessLock = useCallback((config?: IPasswordlessLockConfig) => {
    const state = config ? JSON.stringify(config) : undefined;
    setPasswordlessLock(
      new Auth0LockPasswordless(
        AUTH0_SETTINGS.clientId!,
        AUTH0_SETTINGS.domain!,
        AUTH0_SETTINGS.getSettings(state, config?.email),
      ),
    );
  }, []);

  const auth0Logout = useCallback(() => {
    if (!PasswordlessLock) return;
    PasswordlessLock.logout({
      returnTo: '/',
    });
  }, [PasswordlessLock]);

  useEffect(() => {
    if (defaultConfig) {
      configurePasswordlessLock();
    }
  }, [defaultConfig, configurePasswordlessLock]);

  return {
    PasswordlessLock,
    configurePasswordlessLock,
    auth0Logout,
  };
}
