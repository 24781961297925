import { useState, useEffect } from 'react';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

enum PermissionStatusesEnum {
  'GRANTED' = 'granted',
  'DENIED' = 'denied',
}

interface PermissionStatus {
  state: boolean | null;
  wasDenied: boolean;
}

interface PermissionsStatuses {
  audio: PermissionStatus;
  video: PermissionStatus;
}

export default function useDevices(localTracks?: Array<LocalAudioTrack | LocalVideoTrack>) {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [permissionsStatuses, setPermissionsStatuses] = useState<PermissionsStatuses>({
    audio: {
      state: null,
      wasDenied: false,
    },
    video: {
      state: null,
      wasDenied: false,
    },
  });

  useEffect(() => {
    if (navigator.mediaDevices) {
      const getDevices = () => navigator.mediaDevices.enumerateDevices().then((devices) => setDevices(devices));
      navigator.mediaDevices.addEventListener('devicechange', getDevices);
      getDevices();

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      };
    }
  }, [localTracks]);

  useEffect(() => {
    function initialChangeHandler(name: 'audio' | 'video', state: PermissionState) {
      setPermissionsStatuses((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          state: state === PermissionStatusesEnum.GRANTED,
        },
      }));
    }
    function onChange(name: 'audio' | 'video', state: PermissionState) {
      setPermissionsStatuses((prev) => ({
        ...prev,
        [name]: {
          state: state === PermissionStatusesEnum.GRANTED,
          wasDenied: state === PermissionStatusesEnum.DENIED,
        },
      }));
    }

    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      initialChangeHandler('audio', permissionStatus.state);
      permissionStatus.onchange = function () {
        onChange('audio', this.state);
      };
    });
    navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
      initialChangeHandler('video', permissionStatus.state);
      permissionStatus.onchange = function () {
        onChange('video', this.state);
      };
    });
  }, []);

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
    hasAllowedAudioInputDevices: devices.some((device) => device.kind === 'audioinput' && device.label !== ''),
    hasAllowedVideoInputDevices: devices.some((device) => device.kind === 'videoinput' && device.label !== ''),
    permissionsStatuses,
  };
}
