export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 320,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'Teams-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'Teams-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'Teams-selectedVideoInput';

export const USER_NAME_SEPARATOR = '---|$|---';

export const PARTICIPANT_STATUSES = {
  HAS_NOT_ARRIVED: 'has not arrived',
  WATCHING_INTRO_VIDEO: 'watching intro video',
  READY: 'ready',
};

export const GROUPS = {
  LS_ADMIN: 'TEAMS_LS_ADMIN',
  CUSTOMER_ADMIN: 'TEAMS_CUSTOMER_ADMIN',
  PARTICIPANT: 'TEAMS_PARTICIPANT',
  COLLABORATOR: 'TEAMS_COLLABORATOR',
};

export const VIDEO_BLUR_KEY = 'Teams-videoBlur';

export enum VideoBlurValuesEnum {
  true = 'true',
  false = 'false',
}

export const REGIONS = {
  US: 'US',
  Europe: 'Europe',
  Asia: 'Asia',
};
