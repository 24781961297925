import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import FullscreenIcon from 'assets/images/navbar/fullscreenPopup.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: '50px',
      backgroundColor: 'rgba(0,0,0,0.8)',
      border: '1px solid #4fd4b2',
      width: '240px',
      height: '65px',
      color: 'white',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '0.875rem',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    text: {
      margin: 0,
      width: '190px',
    },
  }),
);

const Icon = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));

const NavbarPopup = ({ isOpen, onClose, testId }: { isOpen: boolean; onClose: () => void; testId?: string }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.container,
      }}
      data-testid={testId || 'navbar-popup'}
    >
      <Icon src={FullscreenIcon} alt="fullscreen mode popup" />
      <p className={classes.text}>{t('bottom-bar:try-fullscreen')}</p>
    </Dialog>
  );
};

export default NavbarPopup;
