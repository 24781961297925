import React, { useState, useContext, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import AudioButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/AudioButton/AudioButton';
import VideoButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/VideoButton/VideoButton';
import SettingsButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/SettingsButton/SettingsButton';
import FullscreenButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/FullscreenButton/FullscreenButton';
import ResourceButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/ResourcesButton/ResourceButton';
import NavbarPopup from './NavbarPopUp/NavbarPopUp';
import ProgressBar from './ProgressBar/ProgressBar';
import PauseGameButton from './PauseGameButton';

import NavbarTimers from 'containers/GameplayPage/components/NavbarTimers/NavbarTimers';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';

import { useFullScreenToggle } from 'hooks';

import Navbar from 'components/Navbar/Navbar';

const useStyles = makeStyles(() =>
  createStyles({
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    timersWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    fakeWidgetsWrapper: {
      width: 130,
    },
    text: {
      color: '#F2F2F2',
      fontSize: '1.125rem',
      lineHeight: '18px',
      fontWeight: 'bold',
    },
  }),
);

export default function GameplayNavbar({ gameStart }: { gameStart: boolean }) {
  const minResolution = useMediaQuery('(max-width:1116px), (max-height:684px)');
  const classes = useStyles();
  const [isPopupOpen, togglePopup] = useState(true);
  const [isFullScreen, toggleFullScreen, fullScreenAvailable] = useFullScreenToggle();
  const context = useContext(GlobalContext);
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;
  const { isSessionHosted, contentfulData } = context;

  const closePopup = () => {
    togglePopup(false);
  };

  const fullScreenButtons = (
    <>
      {!isFullScreen && (
        <NavbarPopup isOpen={isPopupOpen && minResolution} onClose={closePopup} testId="full-screen-button" />
      )}
      {fullScreenAvailable && <FullscreenButton isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen} />}
    </>
  );

  const loops = useMemo(() => {
    return Object.values(context?.contentfulData?.loops || {}).map((loop: any) => ({ steps: loop.length }));
  }, [context?.contentfulData?.loops]);

  return (
    <Navbar>
      <div className={classes.buttonsWrapper}>
        {isSessionHosted ? (
          contentfulData.puzzleResource?.code && <ResourceButton />
        ) : (
          <>
            <VideoButton withBlurMenu />
            <AudioButton />
            <SettingsButton />
          </>
        )}
        {fullScreenButtons}
      </div>
      {gameStart && (
        <>
          <ProgressBar
            gameState={context.gameDef.activeStep.gameState}
            activeStep={context.gameDef.activeStep.step}
            activeLoop={context.gameDef.activeStep.loop}
            loops={loops}
          />
          <div className={classes.timersWrapper}>
            {!allTimersHidden && <PauseGameButton />}
            <NavbarTimers
              gameData={context.gameDef.gameData}
              gameSettings={context.contentfulData.gameSettings}
              gameStart={gameStart}
            />
          </div>
        </>
      )}
      <div className={classes.fakeWidgetsWrapper}>
        {/* this div needed to take place for a chat & Accessibe widgets */}
      </div>
    </Navbar>
  );
}
