export const calculatePosition = (containerRef: any, menuRef: any, coords: [number, number]) => {
  const [clickX, clickY] = coords;

  const rectOffsetLeft = containerRef.getBoundingClientRect().left;
  const rectOffsetTop = containerRef.getBoundingClientRect().top;
  const rootW = menuRef.offsetWidth;
  const rootH = menuRef.offsetHeight;

  const right = clickX - rectOffsetLeft < rootW;
  const left = !right;
  const top = clickY - rectOffsetTop < rootH;
  const bottom = !top;

  let posTop = 0;
  let posLeft = 0;

  if (right) {
    posLeft = clickX + 5;
  }

  if (left) {
    posLeft = clickX - rootW - 5;
  }

  if (top) {
    posTop = clickY + 5;
  }

  if (bottom) {
    posTop = clickY - rootH - 5;
  }

  return {
    top: posTop,
    left: posLeft,
  };
};

export const baseStyles = {
  position: 'fixed',
  opacity: 0,
  pointerEvents: 'none',
  zIndex: 999,
  color: 'white',
  padding: '5px 10px',
  background: 'rgba(0, 115, 160, 1)',
  // boxShadow: 'var(--green-box-shadow)',
};
