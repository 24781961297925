import React, { PropsWithChildren, useContext } from 'react';
import { styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Dialog } from 'components/Dialog';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';

const ParticipantText = styled('div')({
  padding: '0 10px 10px 0',
});
const ResourceLink = styled('div')({
  padding: '20px 0',
});

const RemindParticipants = styled('div')({
  position: 'relative',
  padding: '15px 30px',
  display: 'flex',
  width: 'max-content',
  background: '#05141e',
  transition: 'box-shadow .3s',
  margin: '10px auto',
  textAlign: 'center',
  '&:hover': {
    boxShadow: '0 0 10px 0px rgb(79 212 178 / 40%)',
    cursor: 'pointer',
  },
});

const RemindParticipantsText = styled('div')({
  color: '#fff',
});

const RemindParticipantsCopy = styled('div')({
  textTransform: 'uppercase',
  color: '#6ebddb',
  marginLeft: '15px',
  textSize: '12px',
});

interface ErrorDialogProps {
  onClose: () => void;
  open: boolean;
}

function ResourcesDialog({ onClose, open }: PropsWithChildren<ErrorDialogProps>) {
  const context = useContext(GlobalContext);
  const { resourceUrl } = useFlags();
  const { t } = useTranslation();
  const code = context.contentfulData.puzzleResource?.code;

  return (
    <Dialog open={code && open} title={t('resources:title')} onClose={onClose}>
      <>
        <ResourceLink>
          <ParticipantText>{t('resources:description-1')}</ParticipantText>
          <ParticipantText>
            Go to{' '}
            <a href="https://resources.livingsecurity.com" target="_blank" rel="noreferrer noopener nofollow">
              {resourceUrl || 'resources.livingsecurity.com'}
            </a>{' '}
            {t('resources:description-2')}
          </ParticipantText>
        </ResourceLink>
        <div>{t('resources:code')}:</div>
        <div>
          <CopyToClipboard text={code} copyText={t('wait-list:link-copied')}>
            {(onClick) => (
              <RemindParticipants onClick={onClick}>
                <RemindParticipantsText>{code}</RemindParticipantsText>
                <RemindParticipantsCopy>
                  <i className="fad fa-copy" />
                </RemindParticipantsCopy>
              </RemindParticipants>
            )}
          </CopyToClipboard>
        </div>
      </>
    </Dialog>
  );
}

export default ResourcesDialog;
