import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAudioLevel } from 'hooks';
import { AudioTrack } from 'twilio-video';
import clsx from 'clsx';

import { ReactComponent as MutedMicIcon } from 'assets/images/navbar/muted-mic.svg';

const useStyles = makeStyles({
  container: {
    width: (big) => `${big ? 30 : 22}px`,
    height: (big) => `${big ? 30 : 25}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  line: {
    backgroundColor: '#fff',
    width: (big) => `${big ? 3 : 2}px`,
    transition: 'height 0.2s ease-in-out, transform 0.5s ease-in-out',
    position: 'relative',
    transform: 'scaleY(1)',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
      backgroundColor: 'var(--seafoam-blue)',
      opacity: 0,
      transition: '0.4s ease-in-out',
      '&:nth-child(odd)': {
        background: 'yellow',
      },
    },
  },
  colored: {
    '&::after': {
      opacity: 1,
    },
  },
  small: {
    height: (big) => `${big ? 4 : 3}px`,
  },
  medium: {
    height: (big) => `${big ? 8 : 6}px`,
  },
  large: {
    height: (big) => `${big ? 13 : 11}px`,
  },
  upper: {
    marginTop: (big) => `-${big ? 3 : 2}px`,
  },
  '@keyframes bigEvenPlay': {
    '0%': {
      transform: 'scaleY(1)',
    },
    '20%': {
      transform: 'scaleY(1.6)',
    },
    '40%': {
      transform: 'scaleY(1.3)',
    },
    '60%': {
      transform: 'scaleY(1.6)',
    },
    '100%': {
      transform: 'scaleY(1)',
    },
  },
  '@keyframes bigOddPlay': {
    '0%': {
      transform: 'scaleY(1)',
    },
    '20%': {
      transform: 'scaleY(1.3)',
    },
    '40%': {
      transform: 'scaleY(1.6)',
    },
    '60%': {
      transform: 'scaleY(1.3)',
    },
    '100%': {
      transform: 'scaleY(1)',
    },
  },
  x2: {
    transform: 'scaleY(1)',
    '&:nth-child(even)': {
      animation: '$bigEvenPlay 1.5s ease infinite',
    },
    '&:nth-child(odd)': {
      animation: '$bigOddPlay 1.5s ease infinite',
    },
  },
  muted: {
    height: (big) => `${big ? 4 : 3}px`,
    marginTop: '0 !important',
  },
  '@keyframes smallEvenPlay': {
    '0%': {
      transform: 'scaleY(1)',
    },
    '20%': {
      transform: 'scaleY(1.1)',
    },
    '40%': {
      transform: 'scaleY(1.3)',
    },
    '60%': {
      transform: 'scaleY(1.1)',
    },
    '100%': {
      transform: 'scaleY(1)',
    },
  },
  '@keyframes smallOddPlay': {
    '0%': {
      transform: 'scaleY(1)',
    },
    '20%': {
      transform: 'scaleY(1.3)',
    },
    '40%': {
      transform: 'scaleY(1.1)',
    },
    '60%': {
      transform: 'scaleY(1.3)',
    },
    '100%': {
      transform: 'scaleY(1)',
    },
  },
  animated: {
    transform: 'scaleY(1)',
    '&:nth-child(even)': {
      animation: '$smallEvenPlay 1.5s ease infinite',
    },
    '&:nth-child(odd)': {
      animation: '$smallOddPlay 1.5s ease infinite',
    },
  },
  mutedMic: {
    height: (big) => (big ? 30 : 25),
    width: (big) => (big ? 30 : 16),
  },
});

function AudioLevelIndicator({ big = false, audioTrack }: { big?: boolean; audioTrack: AudioTrack }) {
  const classes = useStyles(big);
  const [isTrackEnabled, audioLevel] = useAudioLevel(audioTrack);

  const getClasses = useCallback(
    (size: 'small' | 'medium' | 'large', upper?: boolean) => {
      return clsx(
        classes.line,
        classes[size],
        upper && classes.upper,
        audioLevel < 0.5 && classes.muted,
        audioLevel >= 0.5 && classes.colored,
        audioLevel >= 0.5 && classes.animated,
        audioLevel >= 0.8 && classes.x2,
      );
    },
    [audioLevel, classes],
  );

  return isTrackEnabled ? (
    <div className={classes.container} data-testid="audio-level-indicator">
      <div className={getClasses('small')} />
      <div className={getClasses('medium')} />
      <div className={getClasses('large', true)} />
      <div className={getClasses('large')} />
      <div className={getClasses('medium')} />
      <div className={getClasses('small')} />
    </div>
  ) : (
    <MutedMicIcon className={classes.mutedMic} data-testid="muted-mic-icon" />
  );
}

export default React.memo(AudioLevelIndicator);
