import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { testStatusesEnum } from '../JoinModal';

type StatusBlockProps = {
  title: string;
  description: string | React.ReactNode;
  status?: keyof typeof testStatusesEnum;
  withStatus?: boolean;
  testId?: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    warningBlock: {},
    progressColor: {
      color: '#4EB3D4',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '17px',
      fontSize: '1rem',
      lineHeight: 1,
      color: '#ffffff',
      fontWeight: 700,
    },
    icon: {
      marginRight: '13px',
      width: '20px',
    },
    description: {
      fontSize: '.9rem',
      color: '#EAEAEA',
    },
  }),
);
const StatusBlock = ({ title, description, status, withStatus = true, testId = 'item' }: StatusBlockProps) => {
  const classes = useStyles();

  return (
    <div className={classes.warningBlock} data-testid={`${testId}-status-block`}>
      <div className={classes.title} data-testid={`${testId}-status-block-title`}>
        {withStatus && (
          <div className={classes.icon} data-testid={`${testId}-status-block-icon`}>
            {status === testStatusesEnum.FAIL && (
              <i className="fas fa-exclamation-triangle" style={{ color: '#ec5151', marginRight: '10px' }} />
            )}
            {status === testStatusesEnum.SUCCESS && (
              <i className="fas fa-check-circle" style={{ color: '#4ED4B2', marginRight: '10px' }} />
            )}
            {status === testStatusesEnum.PROGRESS && <CircularProgress size={18} className={classes.progressColor} />}
          </div>
        )}
        {title}
      </div>
      <div className={classes.description} data-testid={`${testId}-status-block-description`}>
        {description}
      </div>
    </div>
  );
};

export default StatusBlock;
