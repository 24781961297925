import moment, { Duration, Moment } from 'moment-timezone';
import { datadogLogs } from '@datadog/browser-logs';
import { union } from 'lodash';
import { DateTime } from 'luxon';

import { apiClient, GameCompletionStatusEnum, GameResults, RoomDetails } from 'services/api';
import { mergeToFirebase, setValueRealtime, updateFirebaseField } from 'services/firestore';

import {
  AnswerStatistics,
  GAME_STATES,
  GameDefinition,
  IGameResults,
  PenaltyType,
  QuestionStatistics,
  QuizAnswer,
  QuizQuestion,
} from './types';

import { STATE_ROUTE } from 'hooks/useFirebaseConnection/useFirebaseConnection';

import { decryptAES } from 'utils';

import { USER_NAME_SEPARATOR } from '_constants';

export default class GameService {
  private gameDefinition: GameDefinition;
  private roomDetails: RoomDetails;
  private teamRank: number;
  private roomId: string;
  // private pingTimer: number | NodeJS.Timeout = 0;

  constructor(gameDefinition: GameDefinition, roomDetails: RoomDetails, roomId: string) {
    this.gameDefinition = gameDefinition;
    this.roomDetails = roomDetails;
    this.roomId = roomId;
    this.teamRank = 1;
  }

  public getRoomDetails() {
    return this.roomDetails;
  }

  public setGameDefinition(gameDefinition: GameDefinition) {
    this.gameDefinition = gameDefinition;
  }

  public getGameDefinition(): GameDefinition {
    return this.gameDefinition;
  }

  public setTeamName(teamName: string) {
    const gameData = this.gameDefinition.gameData;
    gameData.teamName = teamName;
    mergeToFirebase(this.roomDetails.id, { gameData });
  }

  public togglePuzzleSize(puzzleExpanded: boolean) {
    const activeStep = this.gameDefinition.activeStep;
    activeStep.stepData = { puzzleExpanded };
    return mergeToFirebase(this.roomDetails.id, { activeStep });
  }

  private getTimeIntervals(gameDuration = 3600, steps = 8) {
    const unixTime = this.gameDefinition?.gameData?.startTime;
    if (!unixTime) return;
    const interval = gameDuration / steps;
    const startDate = DateTime.fromMillis(+unixTime);
    const intervalDates = [startDate];
    for (let i = 1; i < steps; i++) {
      const prevInterval = intervalDates[intervalDates.length - 1];
      intervalDates.push(prevInterval.plus({ seconds: interval })); // moment doesn't work because it's mutable
    }
    return intervalDates;
  }

  private findInterval(intervals: DateTime[]) {
    const now = DateTime.local();
    let currentInterval: DateTime = intervals[0];
    const lastInterval = intervals[intervals.length - 1];
    const closestIdx = intervals.findIndex((i) => now > i);
    currentInterval = intervals[closestIdx - 1];
    if (now >= lastInterval) currentInterval = lastInterval;
    if (now <= intervals[0] || closestIdx === 0) currentInterval = intervals[0];
    return currentInterval;
  }

  private getDelay() {
    const MAX_DELAY = 1000 * 60 * 3; // 3 min
    const intervals = this.getTimeIntervals();
    if (!intervals) return;
    const delaysMap = intervals.reduce<Record<string, number>>((acc, i, idx, arr) => {
      acc[i.toString()] = (MAX_DELAY * (arr.length - idx)) / arr.length;
      return acc;
    }, {});
    const currentInterval = this.findInterval(intervals);
    return delaysMap[currentInterval.toString()];
  }

  /*
  public calculateTeamRank(puzzleStep: number | null) {
    // TODO remove after BE will fix gameState
    const isSessionExpired = DateTime.local() > DateTime.fromISO(this.roomDetails.session.utc_end_time);
    if (puzzleStep === null || isSessionExpired) return;

    let timeDelay = this.getDelay();
    const isGamePlaying = this.gameDefinition.gameData && this.gameDefinition.activeStep.gameState === 'PLAYING';

    if (this.pingTimer) clearInterval(this.pingTimer as number);
    this.pingTimer = setInterval(() => {
      timeDelay = this.getDelay();
      if (isGamePlaying) {
        const gameStart = moment(parseInt(this.gameDefinition.gameData.startTime), 'x');
        const secondsElapsed = moment().diff(gameStart, 's');
        if (this.roomDetails.id && !isSessionExpired) {
          apiClient
            .calculateRank(this.roomDetails.id, secondsElapsed)
            .then((response) => this.setTeamRank(response.rank));
        }
      }
    }, timeDelay);
  }

  public setTeamRank(rank: number) {
    const gameData = this.gameDefinition.gameData;
    gameData.position = rank;
    return mergeToFirebase(this.roomDetails.id, { gameData });
  }
   */

  public recordPenalty(penaltyType: PenaltyType) {
    const gameData = this.gameDefinition.gameData;
    return mergeToFirebase(this.roomDetails.id, {
      gameData: {
        ...gameData,
        penalties: {
          ...gameData.penalties,
          [penaltyType]: gameData.penalties[penaltyType] + 1,
        },
      },
    });
  }

  public recordAnswer(question: QuizQuestion, answer: QuizAnswer) {
    const gameData = this.gameDefinition.gameData;
    gameData.questionsStatistics = gameData.questionsStatistics || [];
    const previouslySavedQuestion = gameData.questionsStatistics.find((item) => item.id === question.id);
    let answers: AnswerStatistics[];
    if (previouslySavedQuestion) {
      answers = previouslySavedQuestion.answers.map((item) => ({
        ...item,
        chosen: item.id === answer.sys.id || item.chosen,
      }));
    } else {
      answers = question.answers.map((item) => ({
        id: item.sys.id,
        correctAnswer: item.fields.correctAnswer,
        answerPlainText: item.fields.answerPlainText,
        chosen: answer.sys.id === item.sys.id,
      }));
    }
    const { title, ...questionData } = question;

    if (previouslySavedQuestion) {
      gameData.questionsStatistics = gameData.questionsStatistics.map((item) => {
        return item.id === question.id
          ? {
              ...item,
              answers,
            }
          : item;
      });
    } else {
      gameData.questionsStatistics.push({
        ...questionData,
        answers,
        answeredCorrectFirstTime: answer.fields.correctAnswer,
      });
    }

    return mergeToFirebase(this.roomDetails.id, { gameData });
  }

  public recordOpenAnswer(question: QuizQuestion, answerPlainText: string) {
    const gameData = this.gameDefinition.gameData;
    gameData.questionsStatistics = gameData.questionsStatistics || [];
    const { title, ...questionData } = question;

    gameData.questionsStatistics.push({
      ...questionData,
      answers: [{ answerPlainText }],
    });

    return mergeToFirebase(this.roomDetails.id, { gameData });
  }

  public markEndTime() {
    const gameData = this.gameDefinition.gameData;
    gameData.endTime = moment().format('x');
    datadogLogs.logger.info(`Room ${this.roomDetails.id} has ended at ${moment().toISOString()}`);
    return mergeToFirebase(this.roomDetails.id, { gameData });
  }

  public clearPuzzleState(puzzleRoomId: string) {
    const activeStep = this.gameDefinition.activeStep;
    activeStep.stepData.puzzleExpanded = false;
    mergeToFirebase(this.roomDetails.id, { activeStep });
    setValueRealtime(STATE_ROUTE, puzzleRoomId, { puzzleState: { forceReset: true } });
  }

  public calculateTimeLeft(gameStart: Object, gameLengthSeconds: number) {
    const duration = moment.duration(gameLengthSeconds - moment().diff(gameStart, 's'), 's');
    if (duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0) {
      this.triggerLoss();
    }

    return this.getFormattedTimeFromDuration(duration);
  }

  private DEFAULT_GAME_LENGTH_MINUTES = 60;
  private MAXIMUM_GAME_LENGTH_MINUTES = 210;

  public calculateGameLengthSeconds(gameLengthMinutes?: number) {
    const newGameLengthMinutes = gameLengthMinutes
      ? gameLengthMinutes <= this.MAXIMUM_GAME_LENGTH_MINUTES
        ? gameLengthMinutes
        : this.MAXIMUM_GAME_LENGTH_MINUTES
      : this.DEFAULT_GAME_LENGTH_MINUTES;

    return newGameLengthMinutes * 60;
  }

  public calculateTimePenalties() {
    const gameData = this.gameDefinition.gameData;
    const incorrectCount = gameData.penalties.QUESTION;
    const skipCount = gameData.penalties.PUZZLE;
    const { incorrectPenalty, skipPenalty } = GameService.getPenaltyTimeInSeconds(incorrectCount, skipCount);

    const penaltyTime = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .add(incorrectPenalty, 's')
      .add(skipPenalty, 's');
    return this.getFormattedTimeFromDuration(penaltyTime);
  }

  getFormattedTimeFromDuration(duration: Duration | Moment) {
    return {
      hours: GameService.formatNumberAsString(duration.hours()),
      minutes: GameService.formatNumberAsString(duration.minutes()),
      seconds: GameService.formatNumberAsString(duration.seconds()),
    };
  }

  static getPenaltyTimeInSeconds(incorrectQuestionCount: number, skippedPuzzleCount: number) {
    const incorrectPenalty = incorrectQuestionCount * 60;
    const skipPenalty = skippedPuzzleCount * 300;
    return {
      incorrectPenalty,
      skipPenalty,
    };
  }

  static formatNumberAsString(value: number) {
    return value < 0 ? '00' : value < 10 ? `0${value}` : `${value}`;
  }

  public triggerLoss() {
    this.markEndTime().then(() => {
      setTimeout(() => {
        mergeToFirebase(this.roomDetails.id, {
          activeStep: {
            gameState: GAME_STATES.LOSS,
            loop: 0,
            step: 0,
            stepData: {},
          },
        });
      }, 500);
    });
  }

  public getGameResults(): IGameResults {
    const gameData = this.gameDefinition.gameData;
    const gameDuration = moment.duration(moment(gameData.endTime, 'x').diff(moment(gameData.startTime, 'x')));
    const totalDuration = moment.duration(moment(gameData.endTime, 'x').diff(moment(gameData.startTime, 'x')));
    const totalDuration1 = moment.duration(moment(gameData.endTime, 'x').diff(moment(gameData.startTime, 'x')));
    const gameTime = this.getFormattedTimeFromDuration(gameDuration);
    const incorrectCount = gameData.penalties.QUESTION;
    const skipCount = gameData.penalties.PUZZLE;
    const { incorrectPenalty, skipPenalty } = GameService.getPenaltyTimeInSeconds(incorrectCount, skipCount);
    const penaltyTimeInSeconds = incorrectPenalty + skipPenalty;
    const finalDuration = totalDuration.add(penaltyTimeInSeconds, 's');
    const totalTime = this.getFormattedTimeFromDuration(finalDuration);
    const incorrectTime = moment.duration(incorrectPenalty, 's');
    const skipTime = moment.duration(skipPenalty, 's');

    return {
      gameTime: `${gameTime.hours}:${gameTime.minutes}:${gameTime.seconds}`,
      incorrectCount: incorrectCount,
      incorrectPenalty: `00:${GameService.formatNumberAsString(incorrectTime.minutes())}:00`,
      skipCount: skipCount,
      skipPenalty: `00:${GameService.formatNumberAsString(skipTime.minutes())}:00`,
      totalTime: `${totalTime.hours}:${totalTime.minutes}:${totalTime.seconds}`,
      finalTimeInSeconds: Math.round(totalDuration1.as('s')),
      penaltyTimeInSeconds,
    };
  }

  decryptEmailsArray(emails: string[]): string[] {
    if (!emails) return [];
    return emails.reduce((acc: string[], email: string) => {
      const value = email ? decryptAES(email, this.roomDetails.session.id) : null;
      return value ? acc.concat(value) : acc;
    }, []);
  }

  public sendGameResults({
    completed,
    endTime,
    intermediate,
    completedParticipants = [],
  }: {
    completed: boolean;
    endTime: string;
    intermediate?: boolean;
    completedParticipants?: Array<string>;
  }) {
    const gameDefinition = this.gameDefinition;
    const gameResults = this.getGameResults();
    const storylineId = this.roomDetails.session.contentful_src_id;
    const campaignId = this.roomDetails.session.campaign;
    const finalEndTime = gameDefinition.gameData.endTime || endTime;
    const totalDuration = moment.duration(
      moment(finalEndTime, 'x').diff(moment(gameDefinition.gameData.startTime, 'x')),
    );
    const endDateTime = gameDefinition.gameData.endTime
      ? moment(gameDefinition.gameData.endTime, 'x').toISOString()
      : moment().toISOString();

    const numberOfQuestionsAnsweredCorrectFirstTime = gameDefinition.gameData.questionsStatistics.reduce(
      (acc, question: QuestionStatistics) => (question.answeredCorrectFirstTime ? acc + 1 : acc),
      0,
    );

    const joinedPlayers =
      gameDefinition.joinedPlayers &&
      Object.keys(gameDefinition.joinedPlayers).map((joinedPlayer) => joinedPlayer.split(USER_NAME_SEPARATOR)[0]);
    const joinedParticipants = intermediate ? joinedPlayers : union(joinedPlayers, completedParticipants); // prevent error when completed player absent in joined

    const gameResult: GameResults = {
      teamName: gameDefinition?.gameData?.teamName || 'Missing Team Name',
      startDateTime: moment(gameDefinition.gameData.startTime, 'x').toISOString(),
      endDateTime,
      storylineId,
      campaignId,
      joinedParticipants,
      completedParticipants: intermediate ? [] : completedParticipants,
      finalTimeInSeconds: Math.floor(totalDuration.as('s')),
      penaltyTimeInSeconds: gameResults.penaltyTimeInSeconds,
      numberOfPenalties: gameResults.skipCount,
      numberOfQuestions: gameResults.incorrectCount,
      numberOfQuestionsAnsweredCorrectFirstTime,
      gameCompletionStatus: completed ? GameCompletionStatusEnum.COMPLETED : GameCompletionStatusEnum.INCOMPLETE,
      questionsStatistics: gameDefinition.gameData.questionsStatistics,
    };

    if (gameDefinition.gameResults) return Promise.resolve();

    if (!intermediate) {
      const gameState = completed ? GAME_STATES.WIN : GAME_STATES.LOSS;
      const activeStep = {
        gameState,
        step: 0,
        loop: 0,
        stepData: {
          resultsSent: true,
        },
      };

      mergeToFirebase(this.roomDetails.id, { activeStep, gameResult });
    }
    const sendingMethod = intermediate ? apiClient.sendIntermediateResults : apiClient.sendGameResults;
    const sendingData = {
      ...gameResult,
      joinedParticipants: this.decryptEmailsArray(gameResult.joinedParticipants),
      completedParticipants: this.decryptEmailsArray(gameResult.completedParticipants),
    };

    return (
      sendingMethod
        .call(apiClient, this.roomDetails.id, sendingData)
        /* TODO: This is sending dupication request
         ** remove this logic after refactor
         */
        .then((response) => {
          if (['408', '503', '504'].includes(response.status)) {
            return sendingMethod.call(apiClient, this.roomDetails.id, sendingData);
          }

          return response;
        })
        .catch((e) => {
          console.error(e);
        })
    );
  }

  public setLeader(email: string) {
    return mergeToFirebase(this.roomDetails.id, {
      leader: email,
    });
  }

  private changeGameState(gameState: typeof GAME_STATES[keyof typeof GAME_STATES], gameLengthMinutes?: number) {
    const isGamePaused = gameState === GAME_STATES.PAUSED;
    const { startTime } = this.gameDefinition.gameData;

    return updateFirebaseField(this.roomDetails.id, {
      activeStep: {
        ...this.gameDefinition.activeStep,
        gameState,
      },
      gameData: {
        ...this.gameDefinition.gameData,
        gamePausedTime: isGamePaused ? Date.now() : null,
        gamePausedTimeLeft: isGamePaused
          ? this.calculateTimeLeft(startTime, this.calculateGameLengthSeconds(gameLengthMinutes))
          : null,
        startTime: isGamePaused ? startTime : +startTime + (Date.now() - this.gameDefinition.gameData.gamePausedTime),
      },
    });
  }

  public pauseGame(gameLengthMinutes: number) {
    return this.changeGameState(GAME_STATES.PAUSED, gameLengthMinutes);
  }

  public resumeGame() {
    return this.changeGameState(GAME_STATES.PLAYING);
  }
}
