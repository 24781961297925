import React, { useMemo } from 'react';
import { Participant, Track, LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';
import Publication from '../Publication/Publication';
import { usePublications, useVideoContext, useLocalVideoToggle } from 'hooks';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

interface ParticipantTracksProps {
  participant: Participant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  mainPanel?: boolean;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
export default function ParticipantTracks({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
  mainPanel,
}: ParticipantTracksProps) {
  const { room } = useVideoContext();
  const [isVideoEnabled] = useLocalVideoToggle();
  const publications = usePublications(participant);
  const isLocal = participant === room!.localParticipant;

  const publicationsToShow = useMemo(() => {
    let filteredPublications: TrackPublication[] = [];

    if (enableScreenShare && publications.some((p) => p.trackName.includes('screen'))) {
      filteredPublications = publications.filter((p) => !p.trackName.includes('camera'));
    } else if (!mainPanel) {
      filteredPublications = publications.filter((p) => !p.trackName.includes('screen'));
    }
    const videoPublications = filteredPublications.filter((item) => item.kind === 'video');

    const maxVideoPulications = !isLocal || isVideoEnabled ? 1 : 0;

    if (videoPublications.length > maxVideoPulications) {
      return filteredPublications.reduce<TrackPublication[]>((acc, publication) => {
        if (
          publication.kind !== 'video' ||
          ('publishPriority' in publication && publication?.publishPriority === 'low') ||
          ('priority' in publication && publication?.priority === 'low')
        ) {
          return acc.concat(publication);
        }
        return acc;
      }, []);
    }

    return filteredPublications;
  }, [publications, isVideoEnabled]);

  return (
    <>
      {publicationsToShow &&
        publicationsToShow.map((publication) => (
          <Publication
            key={publication.kind}
            publication={publication}
            isLocal={isLocal}
            disableAudio={disableAudio}
            videoPriority={videoPriority}
          />
        ))}
    </>
  );
}
