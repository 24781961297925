import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavbarButton from 'components/Navbar/components/GameplayNavbar/NavbarButton/NavbarButton';
import IconWrapper from '../IconWrapper';

// Move icons back if they will be changed
// import MicOn from 'assets/images/navbar/microphone-on.svg';
// import MicOff from 'assets/images/navbar/microphone-off.svg';
import Mic from 'assets/images/navbar/microphone.svg';

import { useLocalAudioToggle, useDevices, useVideoContext } from 'hooks';

export default function AudioButton({ fromSettings }: { fromSettings?: boolean }) {
  const { t } = useTranslation();
  const { localTracks } = useVideoContext();
  const { hasAudioInputDevices, hasAllowedAudioInputDevices, permissionsStatuses } = useDevices(localTracks);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(
    localTracks,
    hasAllowedAudioInputDevices,
    fromSettings,
  );
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  const toggleAudio = useCallback(async () => {
    if (loading) return;

    if (hasAllowedAudioInputDevices && !permissionsStatuses.audio.wasDenied) {
      setLoading(true);

      await toggleAudioEnabled();

      setLoading(false);
    }
  }, [hasAllowedAudioInputDevices, toggleAudioEnabled, permissionsStatuses.audio.wasDenied, loading]);

  const hasPermission = useMemo(() => {
    return !permissionsStatuses.audio.wasDenied && permissionsStatuses.audio.state;
  }, [permissionsStatuses.audio.wasDenied, permissionsStatuses.audio.state]);

  return (
    <NavbarButton
      // icon={isAudioEnabled && hasPermission ? MicOn : MicOff}
      iconComponent={<IconWrapper isEnabled={Boolean(isAudioEnabled && hasPermission)} icon={Mic} />}
      onClick={toggleAudio}
      alt="Toggle audio button"
      disabled={!hasAudioInputDevices}
      data-cy-audio-toggle
      testId="toggle-audio-button"
    >
      {hasPermission && hasAudioTrack && hasAllowedAudioInputDevices
        ? `${t('bottom-bar:mic')} ${isAudioEnabled ? t('bottom-bar:on') : t('bottom-bar:off')}`
        : t('bottom-bar:no-audio')}
    </NavbarButton>
  );
}
