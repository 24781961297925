import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ModalContainer from '../ModalContainer';
import { Button } from 'components/Button';
import { GlobalContext } from '../../../containers/GameplayPage/GameplayProvider';

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: 20,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressColor: {
    display: 'block',
    margin: '0 auto',
    color: theme.palette.common.white,
  },
}));

type Props = {
  cancelHandler: (event: any) => void;
  skipHandler: (event: any) => void;
  loading: boolean;
};

const ConfirmSkipPuzzle: React.FC<Props> = (props: Props) => {
  const context = useContext(GlobalContext);
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ModalContainer
      title={t('gameplay:puzzle:confirm-skip:title')}
      containerStyles={{
        position: 'absolute',
        zIndex: 1000,
      }}
      titleTestId="confirm-skip-puzzle-modal-title"
    >
      <div className={classes.text} data-testid="confirm-skip-puzzle-modal-subtitle">
        {!allTimersHidden && t('gameplay:puzzle:confirm-skip:subtitle')}
      </div>
      {props.loading ? (
        <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
      ) : (
        <div className={classes.buttonsWrapper}>
          <Button onClick={props.cancelHandler}>{t('gameplay:puzzle:confirm-skip:cancel')}</Button>
          <Button onClick={props.skipHandler}>{t('gameplay:puzzle:skip')}</Button>
        </div>
      )}
    </ModalContainer>
  );
};

export default ConfirmSkipPuzzle;
