import React from 'react';
import {
  Button as MUIButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';

import { useStyles } from './styled';

const theme = createMuiTheme({
  palette: { primary: { main: '#139ed8' } },
});

type ButtonProps = { loading?: boolean; testId?: string } & MuiButtonProps;

export const Button: React.FC<ButtonProps> = ({
  children,
  loading = false,
  disabled,
  classes: overridedClasses,
  testId,
  ...buttonProps
}) => {
  const classes = useStyles({ classes: overridedClasses });
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.buttonWrapper}>
        <MUIButton
          classes={{ containedPrimary: classes.button, disabled: classes.disabled }}
          color="primary"
          variant="contained"
          disabled={loading || disabled}
          data-testid={testId || 'button'}
          {...buttonProps}
        >
          {children}
        </MUIButton>
        {loading && <CircularProgress className={classes.loadIndicator} color="primary" size={20} />}
      </div>
    </ThemeProvider>
  );
};
