import React from 'react';
import Switch from 'react-switch';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  text: {
    marginLeft: 9,
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    color: 'var(--white)',
  },
});

type Props = {
  text: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
};

const Switcher = ({ text, checked, onChange, disabled }: Props) => {
  const classes = useStyles();

  return (
    <label className={clsx(classes.label, { [classes.disabled]: disabled })}>
      <Switch
        checked={checked}
        onChange={onChange}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#4ed4b2"
        offColor="#626E76"
        width={22}
        height={14}
        borderRadius={7}
        handleDiameter={10}
      />
      <div className={classes.text}>{text}</div>
    </label>
  );
};

export default Switcher;
