import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { map, debounce, orderBy, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ensureMediaPermissions } from 'utils';
// @ts-ignore
import { Button } from '@livingsecurity/cyberblocks';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { GlobalContext } from '../../GameplayProvider';
import { Player } from '../../services/types';
import GameStartModal from 'components/Modal/components/GameStartModal';
import ModalContainer from 'components/Modal/ModalContainer';
import TeamNameSelectorComponent from '../../../SessionSignupPage/TeamNameSelector';
import { apiClient } from 'services/api';
import { mergeToFirebase } from 'services/firestore';

const useStyles = makeStyles((theme) => ({
  teamNameSelectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  error: {
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'center',
    width: '100%',
    color: 'red',
    paddingBottom: '30px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& button': {
      fontWeight: 400,
      padding: '10px 24px',
    },
  },
  progressColor: {
    display: 'block',
    margin: '0 auto',
    color: theme.palette.common.white,
  },
}));

const getTeamNameChoices = (players: Record<string, Player>) =>
  orderBy(map(players, (player) => player.teamName || ''));

export default function TeamNameSelection() {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const classes = useStyles();
  const [teamNameChoices, setTeamNameChoices] = useState<Array<string>>(() =>
    getTeamNameChoices(context.gameDef.players),
  );
  const [customName, setCustomName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isTeamNameChanging, setIsTeamNameChanging] = useState(false);
  const gameData = context.gameDef.gameData;
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;
  const { isSessionHosted } = context;

  const updateFirebase = useCallback(
    (gameData) => {
      if (context.isLeader) {
        return mergeToFirebase(context.roomId, { gameData });
      }
    },
    [context.roomId, context.isLeader],
  );

  const updateGameData = useCallback(
    (field: string, value: any) => {
      updateFirebase({
        [field]: value,
      });
    },
    [context.gameDef.gameData, updateFirebase],
  );

  async function toggleSelectedTeam(nameIndex: number, isSelected: boolean) {
    if (isSelected || isTeamNameChanging) return;

    setIsTeamNameChanging(true);

    try {
      await updateFirebase({
        selectedTeamName: nameIndex,
      });
    } finally {
      setIsTeamNameChanging(false);
    }
  }
  const debouncedUpdateFirebase = useMemo(() => debounce(updateFirebase, 1000), [updateFirebase]);

  function handleCustomName({ target: { value } }: any) {
    if (context.isLeader) {
      const localValue = value;

      setCustomName(localValue);
      debouncedUpdateFirebase({
        selectedTeamNameValue: localValue,
      });
    }
  }

  const handleStartTimers = useCallback(() => {
    if (context.isLeader) context.startTimers();
  }, [context.isLeader, context.startTimers]);

  function validateTeamName() {
    const gameData = context.gameDef.gameData;
    const teamName = teamNameChoices[gameData.selectedTeamName - 1] || customName;

    if (teamName && teamName.length && /\S/.test(teamName) && context.isLeader) {
      if (teamName.length < 2) return updateGameData('error', t('sign-up:team-length-error'));

      setLoading(true);

      apiClient
        .validateTeamName(context.roomId, teamName)
        .then(({ available }) => {
          if (available) {
            apiClient
              .setTeamName(context.roomId, teamName)
              .then(() => {
                // store in firebase
                context.gameService.setTeamName(teamName);
              })
              .catch(console.error);
          } else {
            setLoading(false);
            updateGameData('error', `The team name ${teamName} is taken`);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          if (error.response && error.response.status >= 300) {
            updateGameData('error', error.response.data.body || error.response.data);
          }
        });
    }
  }

  useEffect(() => {
    const newChoices = getTeamNameChoices(context.gameDef.players);
    if (!isEqual(newChoices, teamNameChoices)) {
      setTeamNameChoices(newChoices);
    }
  }, [context.gameDef.players]); // eslint-disable-line

  useEffect(() => {
    if (!isSessionHosted) {
      ensureMediaPermissions();
    }
  }, []);

  useEffect(() => {
    if (context.isLeader) setCustomName(context.gameDef.gameData.selectedTeamNameValue);
  }, [context.isLeader]);

  return (
    <div className={classes.teamNameSelectionContainer}>
      {gameData.teamName ? (
        <GameStartModal
          isLeader={context.isLeader}
          callback={handleStartTimers}
          teamName={gameData.teamName}
          timersHidden={allTimersHidden}
        />
      ) : (
        <ModalContainer containerStyles={{ width: 'calc(100% - 50px)' }}>
          <TeamNameSelectorComponent
            nameChoices={teamNameChoices}
            selected={gameData.selectedTeamName}
            isLeader={context.isLeader}
            nameCallback={handleCustomName}
            customName={context.isLeader ? customName : gameData.selectedTeamNameValue}
            callback={toggleSelectedTeam}
          />
          {gameData.error && <div className={classes.error}>{JSON.stringify(gameData.error)}</div>}
          {teamNameChoices && (
            <div className={classes.buttonRow}>
              {loading ? (
                <CircularProgress classes={{ colorPrimary: classes.progressColor }} />
              ) : (
                <Button onClick={validateTeamName} disabled={!context.isLeader}>
                  {t('gameplay:team-name:submit')}
                </Button>
              )}
            </div>
          )}
        </ModalContainer>
      )}
    </div>
  );
}
