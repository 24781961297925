import { useCallback, useEffect, useState, useContext } from 'react';
import { LocalVideoTrack } from 'twilio-video';

import { useVideoContext } from 'hooks';

import { checkBlockedPermissions, getVideoPreference } from 'utils';

import { GlobalContext } from 'containers/GameplayPage/GameplayProvider';

export default function useLocalVideoToggle(isSessionHosted) {
  const context = useContext(GlobalContext);
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;
  const [isPublishing, setIsPublishing] = useState(false);
  const [hasPermissionError, setPermissionError] = useState(false);

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        // eslint-disable-next-line no-unused-expressions
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else if (!hasPermissionError) {
        setIsPublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(() => {
            setPermissionError(true);
            checkBlockedPermissions(onError);
          })
          .finally(() => setIsPublishing(false));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  useEffect(() => {
    const previousVideoPreference = getVideoPreference(context.roomSessionId);

    if (!isSessionHosted && String(!!videoTrack) !== previousVideoPreference && videoTrack) {
      toggleVideoEnabled();
    }
  }, [videoTrack, isSessionHosted]); // eslint-disable-line

  return [!!videoTrack, toggleVideoEnabled] as const;
}
