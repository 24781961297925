import React, { Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TeamRank } from 'services/api';
import { getDateStringFromSeconds } from 'utils/dateUtils';

import { useStyles } from './styled';

type TableProps = {
  activeTeam?: string;
  headColumns: string[];
  rows: TeamRank[] | undefined;
  tableTestId?: string | 'rank-table';
};

export const RankTable: React.FC<TableProps> = ({ headColumns, rows, activeTeam, tableTestId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!rows) return <CircularProgress classes={{ colorPrimary: classes.progressColor }} />;

  return (
    <div className={classes.tableContainer}>
      <div className={classes.grid}>
        {headColumns.map((h, idx) => (
          <div key={idx} style={{ fontWeight: 'bold' }} data-testid={`${tableTestId}-head-column-${idx + 1}`}>
            {h}
          </div>
        ))}
      </div>
      {rows.length > 0 ? (
        <div className={clsx(classes.grid, classes.tableBody)}>
          {rows.map((r) => (
            <Fragment key={r.rank}>
              <div
                className={clsx(r.teamName === activeTeam ? classes.tableRowActive : classes.tableRow)}
                data-testid={`${tableTestId}-team-rank-${r.rank}`}
              >
                {r.rank}
              </div>
              <div
                className={clsx(r.teamName === activeTeam ? classes.tableRowActive : classes.tableRow)}
                data-testid={`${tableTestId}-team-name-${r.rank}`}
              >
                {r.teamName}
              </div>
              <div
                className={clsx(r.teamName === activeTeam ? classes.tableRowActive : classes.tableRow)}
                data-testid={`${tableTestId}-team-time-${r.rank}`}
              >
                {getDateStringFromSeconds(r.totalTimeInSeconds)}
              </div>
            </Fragment>
          ))}
        </div>
      ) : (
        <div className={classes.empty} data-testid={`${tableTestId}-no-results`}>
          {t('leaderboard:no-results')}
        </div>
      )}
    </div>
  );
};
