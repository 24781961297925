import React from 'react';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import ModalContainer from '../../components/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { RoomDetails, SessionStateEnum } from '../../services/api';

export default function RescheduleModal({ roomDetails }: { roomDetails: RoomDetails }) {
  const { t } = useTranslation();
  const tz = moment?.tz?.zone(moment?.tz?.guess())?.abbr(parseInt(moment().format('x')));
  const sessionStart = roomDetails?.sessionStart;
  const sessionState = roomDetails?.sessionState;
  const isOver = moment(sessionStart).add(1, 'h').isBefore(moment()) || sessionState === SessionStateEnum.ENDED;
  const doorsOpenAt = moment(sessionStart).subtract(15, 'm');

  function getTitle() {
    if (isOver) {
      return t('sign-up:reschedule-modal:session-completed');
    } else if (sessionState === SessionStateEnum.CANCELED) {
      return t('sign-up:reschedule-modal:session-cancelled');
    }
    return t('sign-up:reschedule-modal:session-full');
  }

  function getSubtitle() {
    if (isOver) {
      const endTime = moment(sessionStart).add(1, 'h');
      return t('sign-up:reschedule-modal:game-completed', { endTime: `${getStartTime(endTime)} ${tz}` });
    } else if (sessionState === SessionStateEnum.CANCELED) {
      return null;
    }
    return t('sign-up:reschedule-modal:doors-opened-at', { startTime: `${getStartTime(doorsOpenAt)} ${tz}` });
  }

  function getBodyText() {
    if (isOver) {
      return t('sign-up:reschedule-modal:session-completed-body');
    } else if (sessionState === SessionStateEnum.CANCELED) {
      return t('sign-up:reschedule-modal:session-cancelled-body');
    }
    return t('sign-up:reschedule-modal:max-numbers');
  }

  function getStartTime(time: any) {
    return time.isSame(moment(), 'day') ? time.format('h:mm A zz') : time.format('MMM Do, YYYY [at] h:mm A zz');
  }

  return (
    <>
      <ModalContainer title={getTitle()} subtitle={getSubtitle()}>
        <div style={{ width: '340px' }}>
          <div>{getBodyText()}</div>
          <div style={{ float: 'right', marginTop: '20px' }}>
            <a href={roomDetails?.session.link_for_reschedule}>
              <Button
                style={{ backgroundColor: '#2d9cdb', borderRadius: '50px', padding: '7px 15px', fontSize: '13px' }}
              >
                {t('sign-up:reschedule-modal:reschedule')}
              </Button>
            </a>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
