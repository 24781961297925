import { DateTime } from 'luxon';

/**
 * Convert number of seconds to date string. Example: 120 (s) -> 00:02:00
 */
export const getDateStringFromSeconds = (seconds: number, format?: string) =>
  DateTime.local()
    .startOf('day')
    .plus({ seconds })
    .toFormat(format ?? 'HH:mm:ss');
