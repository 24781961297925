import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import markerChecked from 'assets/images/marker-checked.svg';
import markerUnchecked from 'assets/images/marker-unchecked.svg';
import { GAME_STATES } from 'containers/GameplayPage/services/types';

type StylesProps = {
  maxContainerWidth: number;
  stepWidth: number;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: ({ maxContainerWidth }: StylesProps) => ({
      display: 'flex',
      height: '10px',
      marginTop: 15,
      border: '3px solid #121212',
      borderRightWidth: '2px',
      outline: '2px solid #49B499',
      maxWidth: maxContainerWidth,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    }),
    loop: {
      display: 'flex',
      position: 'relative',
      background: '#3E5A52',
    },
    step: ({ stepWidth }: StylesProps) => ({
      minWidth: '5px',
      maxWidth: '10px',
      width: stepWidth,
      '&.active': {
        background: '#6AFFD9',
      },
    }),
    marker: {
      position: 'absolute',
      zIndex: 1,
      bottom: '-10px',
      right: '-9px',
    },
    sectionBar: {
      display: 'none',
      color: '#85E5FF',
      fontSize: '1rem',
      lineHeight: '19px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
  }),
);

const MAX_BAR_WIDTH = 350;

const ProgressBar = ({
  activeStep,
  activeLoop,
  loops,
  gameState,
}: {
  activeStep: number;
  activeLoop: number;
  loops: any;
  gameState: string;
}) => {
  const { t } = useTranslation();

  const stepWidth = useMemo(() => {
    const numberOfSteps = loops.reduce((acc: number, loop: any) => acc + loop.steps, 0);
    const calculatedWidth = MAX_BAR_WIDTH / numberOfSteps;
    return Math.floor(calculatedWidth);
  }, [loops]);

  const classes = useStyles({ stepWidth, maxContainerWidth: MAX_BAR_WIDTH });

  const isAfterGameState = useMemo(() => gameState === GAME_STATES.WIN || gameState === GAME_STATES.DONE, [gameState]);

  const loopsToRender = useMemo(() => {
    return loops.map((loop: any, loopIdx: any) => (
      <div className={classes.loop} key={loopIdx} data-testid={`progress-bar-loop-${loopIdx}`}>
        {(() => {
          const steps = [];
          const isLoopPassed = loopIdx < activeLoop;

          for (let i = 0; i < loop.steps; i = i + 1) {
            const active =
              isAfterGameState ||
              (gameState !== GAME_STATES.INTRO && (isLoopPassed || (loopIdx === activeLoop && i < activeStep)));
            steps.push(
              <span
                className={clsx(classes.step, { active })}
                key={`${loopIdx}_${i}`}
                data-testid={`progress-bar-step-${loopIdx}-${i}`}
              />,
            );
          }

          steps.push(
            <img
              className={classes.marker}
              alt="marker"
              src={isAfterGameState || isLoopPassed ? markerChecked : markerUnchecked}
              data-testid={`progress-bar-marker-${loopIdx}`}
            />,
          );

          return steps;
        })()}
      </div>
    ));
  }, [loops, activeLoop, activeStep, gameState]);

  if (!loops.length) return null;

  return (
    <div data-testid="progress-bar">
      <div className={classes.container} data-testid="progress-bar-container">
        {loopsToRender}
      </div>
      <div className={classes.sectionBar} data-testid="progress-bar-section-bar">
        {`${t('section')} ${isAfterGameState ? loops.length : activeLoop + 1}/${loops.length}`}
      </div>
    </div>
  );
};

export default memo(ProgressBar);
