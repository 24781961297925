import React, { ChangeEvent, useContext, useEffect } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import QuizOption from '../../containers/GameplayPage/components/QuizView/QuizOption';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../GameplayPage/GameplayProvider';

const optionHeight = '50px';
const quizOptionStyles = { height: optionHeight };
const useStyles = makeStyles({
  title: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '0.875rem',
    margin: '8px auto 24px',
    maxWidth: '530px',
    textAlign: 'center',
    color: 'var(--grey)',
  },
  warning: {
    fontSize: '0.875rem',
    margin: '8px auto 30px',
    maxWidth: '530px',
    textAlign: 'center',
    color: 'var(--grey)',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    paddingBottom: '22px',
    flexWrap: 'wrap',
    overflowX: 'hidden',
  },
  input: {
    background: 'none',
    height: '50px',
    width: 'calc(100% - 30px)',
    marginLeft: '-16px',
    color: '#F2F2F2',
    fontSize: '0.875rem',
    padding: '0 20px',
    outline: 'none',
    border: 'none',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const hasArrayTeams = (arr: string[]) => arr.some((teamName: string) => !!teamName.length);

export default function (props: {
  nameChoices: Array<string>;
  callback: Function;
  nameCallback: (event: ChangeEvent<HTMLInputElement>) => void;
  isLeader: boolean;
  selected?: number;
  customName?: string;
}) {
  const context = useContext(GlobalContext);
  const { t } = useTranslation();
  const { nameChoices = [], callback, isLeader, nameCallback, selected, customName } = props;
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;
  const classes = useStyles();
  const teamNameLength = 20;

  useEffect(() => {
    context.switchAudio(!context.isSessionHosted);
  }, []); // eslint-disable-line

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.title}>{t('gameplay:team-name:title')}</div>
      {!allTimersHidden && (
        <div className={classes.subtitle}>{t('gameplay:team-name:subtitle', { maxLength: teamNameLength })}</div>
      )}
      {allTimersHidden && (
        <div className={classes.warning}>{t('gameplay:team-name:warning', { maxLength: teamNameLength })}</div>
      )}
      <div className={classes.container}>
        {nameChoices.map((name: string, index: number) => {
          const isSelected = selected === index + 1;

          return name.length ? (
            <QuizOption
              {...props}
              key={index}
              selected={isSelected}
              callback={callback.bind(null, index + 1, isSelected)}
              styles={{ height: '50px' }}
            >
              <div>{name}</div>
            </QuizOption>
          ) : null;
        })}
        <QuizOption
          {...props}
          styles={quizOptionStyles}
          selected={selected === nameChoices.length + 1}
          callback={callback.bind(null, nameChoices.length + 1, selected === nameChoices.length + 1)}
        >
          <input
            className={classes.input}
            placeholder={t(`gameplay:team-name:${hasArrayTeams(nameChoices) ? 'enter-other-name' : 'enter-name'}`)}
            maxLength={teamNameLength}
            onChange={nameCallback}
            value={customName}
            disabled={!isLeader}
          />
        </QuizOption>
      </div>
    </ThemeProvider>
  );
}
