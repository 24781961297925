import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GlobalContext } from '../../GameplayProvider';

import 'react-typist/dist/Typist.css';
import Tour from 'reactour';

enum guideType {
  'LEADER',
  'PLAYER',
}

export default function PuzzleWalkthrough({ toggleView }: { toggleView: any }) {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const isLeader = context.isLeader;
  const [puzzleTourOpen, setPuzzleTourOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { puzzleResource } = context.contentfulData;
  const { hidePuzzleIntroWalkthrough } = useFlags();
  const hideLeaderPuzzleGuide = localStorage.getItem('ls_hideLeaderPuzzleGuide');
  const hidePlayerPuzzleGuide = localStorage.getItem('ls_hidePlayerPuzzleGuide');
  const allTimersHidden =
    context.contentfulData?.gameSettings?.hideCountdownTimer && context.contentfulData?.gameSettings?.hideAccruedTime;

  useEffect(() => {
    setLoaded(true);
  }, []); // eslint-disable-line

  function dismissTour(type: guideType) {
    if (type === guideType.LEADER) {
      localStorage.setItem('ls_hideLeaderPuzzleGuide', 'true');
    } else if (type === guideType.PLAYER) {
      localStorage.setItem('ls_hidePlayerPuzzleGuide', 'true');
    }
    setPuzzleTourOpen(false);
  }

  function nextStep() {
    if (currentStep + 1 > tourSteps.length - 1) return;
    if (!isLeader && currentStep === 1) {
      toggleView('GUIDE');
    }
    setCurrentStep(currentStep + 1);
  }

  const tourSteps = useMemo(() => {
    return isLeader && !allTimersHidden
      ? [
          {
            selector: '',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:leader:step-1-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:leader:step-1-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
          {
            selector: '#skip-btn',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:leader:step-2-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:leader:step-2-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
          {
            selector: '#restart-btn',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:leader:step-3-1')}</div>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:leader:step-3-2')}</div>
                <div>{t('gameplay:puzzle:walkthrough:leader:step-3-3')}</div>
              </div>
            ),
            roundedStep: true,
          },
        ]
      : !isLeader && puzzleResource?.name
      ? [
          {
            selector: '',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:player:step-1-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:player:step-1-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
          {
            selector: '#player-toggle-container',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:player:step-2-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:player:step-2-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
          {
            selector: '#exam-guide-btn',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:player:step-3-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:player:step-3-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
          {
            selector: '#expand-btns',
            content: () => (
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>{t('gameplay:puzzle:walkthrough:player:step-4-1')}</div>
                <div>{t('gameplay:puzzle:walkthrough:player:step-4-2')}</div>
              </div>
            ),
            roundedStep: true,
          },
        ]
      : [];
  }, [isLeader]);

  useEffect(() => {
    const currentHideLeaderPuzzleGuide = localStorage.getItem('ls_hideLeaderPuzzleGuide');
    const currentHidePlayerPuzzleGuide = localStorage.getItem('ls_hidePlayerPuzzleGuide');
    if ((currentHideLeaderPuzzleGuide && currentHidePlayerPuzzleGuide) || hidePuzzleIntroWalkthrough) return;

    setPuzzleTourOpen(false);
    setTimeout(() => {
      // we need to "close" and "open" tour again because with dynamic steps if current step is bigger than number of
      // new steps it causes error and there is no way to programmatically set the step
      setPuzzleTourOpen(true);
      setCurrentStep(0);
    }, 10);
  }, [isLeader]);

  if ((!isLeader && hidePlayerPuzzleGuide) || (isLeader && hideLeaderPuzzleGuide) || hidePuzzleIntroWalkthrough) {
    return null;
  }

  return (
    <>
      {loaded && puzzleTourOpen && currentStep <= tourSteps?.length - 1 && (
        <Tour
          steps={tourSteps}
          accentColor="#2d9cdb"
          isOpen={puzzleTourOpen}
          closeWithMask={false}
          disableInteraction={true}
          nextStep={nextStep}
          maskClassName=""
          getCurrentStep={setCurrentStep}
          goToStep={currentStep}
          onRequestClose={dismissTour.bind(null, isLeader ? guideType.LEADER : guideType.PLAYER)}
          showCloseButton={currentStep === tourSteps.length - 1}
        />
      )}
    </>
  );
}
