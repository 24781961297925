import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ navbarHeight }: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 2,
      width: '100%',
      height: navbarHeight,
      backgroundColor: 'var(--black)',
      padding: '0 16px',
      borderTop: '2px solid #A2ACB3',
    },
  }),
);

function Navbar({ children }: { children: ReactNode }) {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
}

export default Navbar;
