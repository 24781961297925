import React, { createContext, useContext, useReducer, useState, useCallback } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import firebase from 'firebase/app';
import { apiClient, GenericResponse } from '../services/api';
import { SELECTED_AUDIO_OUTPUT_KEY } from '../_constants';
import { datadogLogs } from '@datadog/browser-logs';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | { message: string; code: number } | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  joinRoom(username: string, roomId: string, isJoinAfterRefresh: boolean): Promise<GenericResponse>;
  user?: firebase.User | null | { displayName: undefined; photoURL: undefined };
  signIn?(): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  isAuthenticated?: boolean;
  setIsAuthenticated(value: boolean): React.SetStateAction<boolean>;
  userEmail?: string;
  setUserEmail(email: string | null): React.SetStateAction<string | null>;
  isCurrentUserModerator?: boolean;
  setIsCurrentUserModerator(variable: boolean | null): React.SetStateAction<boolean | null>;
  authChecked: boolean;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
  datadogLogs.logger.debug(`Loading previously selected audio output device: ${selectedAudioDeviceId || 'default'}`);
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState(selectedAudioDeviceId || 'default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isCurrentUserModerator, setIsCurrentUserModerator] = useState<boolean | null>(null);
  const [authChecked, setAuthChecked] = useState(false);

  const handleAuthCheck = useCallback(
    (value) => {
      if (!authChecked) setAuthChecked(true);
      setIsAuthenticated(value);
    },
    [authChecked],
  );

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    isAuthenticated,
    setIsAuthenticated: handleAuthCheck,
    userEmail,
    setUserEmail,
    isCurrentUserModerator,
    setIsCurrentUserModerator,
    authChecked,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    joinRoom: async (userName, roomId, isJoinAfterRefresh) => {
      return apiClient.joinRoom(roomId, userName, isJoinAfterRefresh);
    },
    getToken: async (identity, roomName) => {
      const headers = new window.Headers();
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
      const params = new window.URLSearchParams({ identity, roomName });

      return fetch(`${endpoint}?${params}`, { headers }).then((res) => res.text());
    },
  };

  const joinRoom: StateContextType['joinRoom'] = (username, roomId, isJoinAfterRefresh) => {
    setIsFetching(true);
    return contextValue
      .joinRoom(username, roomId, isJoinAfterRefresh)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, joinRoom, getToken }}>{props.children}</StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
